import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
} from "@mui/material";
import { tableHeadCell, tableBodyCell } from "../styles/common_styles";

export const ConsumerTableComponent = ({
  headers,
  data,
  actions,
  countryNames,
}) => {
  console.log(data, "dattatata");
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {data ? (
        <TableContainer sx={{ pt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                {headers?.map((header, index) => (
                  <TableCell key={index} sx={tableHeadCell}>
                    {header}{" "}
                  </TableCell>
                ))}
                {actions && <TableCell sx={tableHeadCell}>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {countryNames?.map((countryName, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: "center" }}>
                    {countryName}
                  </TableCell>
                  {data[countryName]?.revenue.map((revenue, monthIndex) => (
                    <TableCell key={monthIndex} sx={{ textAlign: "center" }}>
                      {revenue}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid sx={{ textAlign: "center", fontWeight: "bold", mt: 3 }}>
          {" "}
          No Table Data
        </Grid>
      )}
    </Paper>
  );
};
