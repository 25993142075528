import Login_image from '../assets/images/Login_image.png'

export const LoginImage  = {
  width: "100%",
  backgroundImage: `url(${Login_image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
};

export const LoginContainer = {
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

export const BlockCenter = {
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  height:'100vh',
};

export const LoginForm = {
  maxWidth: '500px',
  minWidth: 'auto'
};

export const Emaillable = {
  fontSize: '14px',
  marginBottom: '5px',
  marginTop: '25px'
};

export const Emaillablee = {
  fontSize: '14px',
  marginBottom: '5px',
  marginTop: '10px'
};

export const EmaillableTwo = {
  fontSize: '14px',
  marginBottom: '5px',
};

export const CustomField = {
  marginTop: "10px",
  borderRadius: "25px",
  fontSize:'16px !important',
  color:'black !important'
};

export const CustomFieldTwo = {
  borderRadius: "25px",
  fontSize:'14px'
};

export const Paragraph = {
  fontSize: '15px ',
  color: 'black',
  textAlign: 'end !important',
  textDecoration: 'underline',
  padding: '20px 20px 0 20px'
};

export const LoginFormContainer = {
  maxWidth: '350px',
  minWidth:'auto'
};

export const ForgotTextContainer = {
  display: 'flex',
  justifyContent: 'end'
};

export const LoginButtonContainer = {
  marginTop:'20px',
  display: "flex",
  justifyContent: "center",
};

export const eyeIconParentPosition = {
  position:'relative'
};

export const LogoStyles = {
  textAlign:'center'
};