import {
  Grid,
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import React from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import logo from "../../assets/images/logo.png";
import {
  container1_2,
  container1_1,
  container6,
} from "../../styles/common_styles";
import { ButtonComponent } from "../../components/form_components/button";
import { ManagementServices } from "../../services/management_services";
import { Loader } from "../../components/loader_component";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";

export const DeleteUser = () => {
  // const [open, setOpen] = React.useState(false);
  const [passwordModal, setPasswordModal] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [password, setPassword] = React.useState("");
  // const handleOpen = () => setOpen(true);
  const [otpModalOpen, setOtpModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [otp, setOtp] = React.useState(["", "", "", ""]);
  const [deleteScreen, setDeleteScreen] = React.useState(true);
  const [customerType, setCustomerType] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [loaderData, setLoaderData] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({
    firstName: "",
    email: "",
    mobile: "",
  });
  const inputRefs = React.useRef([]);
  const { register } = useForm();

  const handleClose = () => setOtpModalOpen(false);
  const handleDeleteModalClose = () => setDeleteModalOpen(false);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value) || value.length > 1) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    // Move focus to the next input field
    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const submitOtp = async (e) => {
    const otpString = otp.join("");
    const otpNumber = +otpString;
    const payload = {
      otp: otpNumber,
      userId: userId,
      type: customerType,
    };
    const verifyOtp = await ManagementServices.verifyOtpSend(payload);
    if (verifyOtp.status === 1) {
      setAlrtMsg(verifyOtp.message);
      setSuccessAlrtOpen(true);
      setUserDetails({
        firstName: verifyOtp.data.firstName,
        email: verifyOtp.data.email,
        mobile: verifyOtp.data.mobile,
      });
      handleClose();
      setDeleteScreen(false);
    } else {
      setAlrtMsg(verifyOtp.message);
      setErrorAlrtOpen(true);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const HandleOtp = async () => {
    if (email !== "" && customerType !== 0) {
      const payload = {
        emailOrPhone: email,
        type: customerType,
      };
      const emailOrPhoneVerify =
        await ManagementServices.verifyWithEmailOrPhone(payload);
      setLoaderData(true);
      if (emailOrPhoneVerify.status === 1) {
        setAlrtMsg(emailOrPhoneVerify.message);
        setSuccessAlrtOpen(true);
        setUserId(emailOrPhoneVerify.data.userId);
        setEmail("");
        setLoaderData(false);
        setOtpModalOpen(true);
      } else {
        setAlrtMsg(emailOrPhoneVerify.message);
        setErrorAlrtOpen(true);
        setLoaderData(false);
      }
    } else {
      setError(true);
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    if (password === "Krify@123") {
      setPasswordModal(false);
    } else {
      setError(true);
    }
  };

  const OtpModal = () => {
    return (
      <>
        {loaderData ? (
          <Loader />
        ) : (
          <Modal
            open={otpModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                height: 200,
                bgcolor: "background.paper",
                boxShadow: 24,
                pt: 2,
                px: 4,
                pb: 3,
                outline: 0,
                borderRadius: 5,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  alignContent: "center",
                }}
              >
                OTP Verification
              </Typography>
              <Grid sx={{ display: "flex", justifyContent: "center" }}>
                {otp.map((_, index) => (
                  <TextField
                    key={index}
                    type="text"
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: "center" },
                    }}
                    value={otp[index]}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: "8px", borderRadius: "1px" }}
                  />
                ))}
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <ButtonComponent btnText={"Submit"} onClick={submitOtp} />
              </Grid>
            </Box>
          </Modal>
        )}
      </>
    );
  };

  const DeleteUserModal = () => {
    return (
      <Modal
        open={deleteModalOpen}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            height: 100,
            bgcolor: "background.paper",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            outline: 0,
            borderRadius: 5,
          }}
        >
          <Grid
            sx={{ display: "flex", justifyContent: "center", self: "center" }}
          >
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                alignContent: "center",
              }}
            >
              Account Deleted Successfully
            </Typography>
          </Grid>
        </Box>
      </Modal>
    );
  };

  const handleDelete = async () => {
    const payload = {
      userId: userId,
      type: customerType,
    };
    const deleteUserResponse = await ManagementServices.DeleteUser(payload);
    if (deleteUserResponse.status === 1) {
      setDeleteModalOpen(true);
      setTimeout(() => {
        setDeleteScreen(true);
        setDeleteModalOpen(false);
      }, 5000);
      setAlrtMsg(deleteUserResponse.message);
      setErrorAlrtOpen(true);
      setCustomerType(0);
      setUserDetails({
        firstName: "",
        email: "",
        mobile: "",
      });
      setUserId("");
    } else {
      setAlrtMsg(deleteUserResponse.message);
      setErrorAlrtOpen(true);
    }
  };

  const ScreenDelete = () => {
    return (
      <Grid item md={12} sx={container1_2}>
        <Grid sx={container1_1}>
          <img src={logo} alt="logo" />
          <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <Grid>
              <Grid ml={3} sx={{ fontWeight: 1000, fontSize: 22 }}>
                User Account Details
              </Grid>
              <Grid ml={3} mt={3} sx={{ fontWeight: "bold" }}>
                UserName : {userDetails?.firstName}
              </Grid>
              <Grid ml={3} mt={3} sx={{ fontWeight: "bold" }}>
                Phone Number : {userDetails?.mobile}
              </Grid>
              <Grid ml={3} mt={3} sx={{ fontWeight: "bold" }}>
                EmailId : {userDetails?.email}
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <ButtonComponent btnText={"Delete"} onClick={handleDelete} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {!passwordModal &&
        (loaderData ? (
          <Loader />
        ) : (
          <Grid container>
            {deleteScreen ? (
              <Grid item md={12} sx={container1_2}>
                <Grid sx={container1_1}>
                  <img src={logo} alt="logo" />
                  <Grid pt={5}>
                    <Typography sx={{ fontWeight: "bold" }} ml={3}>
                      Please Enter Your Email/Phone number to delete the Account
                    </Typography>
                  </Grid>
                  <Grid mb={2} mt={3} ml={3}>
                    Type
                  </Grid>
                  <Grid ml={3}>
                    <FormControl>
                      <Select
                        {...register("type", { required: true })}
                        size="small"
                        inputProps={{ "aria-label": "Type" }}
                        value={customerType}
                        sx={{ width: "350px", height: "60px" }}
                        onChange={(e) => setCustomerType(e.target.value)}
                      >
                        <MenuItem value={0}>Select</MenuItem>
                        <MenuItem value={1}>Consumer</MenuItem>
                        <MenuItem value={2}>Chef</MenuItem>
                      </Select>
                    </FormControl>
                    {error && customerType === "" && (
                      <p style={{ color: "red" }}>Type is required</p>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ display: "flex", flexDirection: "column" }}
                    pt={5}
                    ml={3}
                  >
                    <Grid mb={2} mt={3}>
                      Email/Phone
                    </Grid>
                    <Grid>
                      <TextField
                        {...register("Email", { required: true })}
                        sx={{ width: "350px", height: "10px" }}
                        placeholder="Enter Here"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {error && email === "" && (
                        <p style={{ color: "red", paddingTop: "13px" }}>
                          This Field is required
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid sx={{ pl: 13, pt: 10, ml: 3 }}>
                      <ButtonComponent btnText={"Submit"} onClick={HandleOtp} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container>{ScreenDelete()}</Grid>
            )}
            <Grid item md={12} sx={container6}>
              Copyright © 2024 Home Food Delivery
            </Grid>
          </Grid>
        ))}
      <Modal
        open={passwordModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Authentication
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={(event) => handlePasswordSubmit(event)}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  width="100%"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setError(false);
                    setPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {error && (
                  <p style={{ color: "red" }}>Please enter valid password</p>
                )}
              </FormControl>
              <Grid
                sx={{ width: "100%", textAlign: "center", padding: "10px" }}
              >
                <Button variant="contained" type="submit" size="medium">
                  Submit
                </Button>
              </Grid>
            </form>
          </Typography>
        </Box>
      </Modal>
      {OtpModal()}
      {DeleteUserModal()}
      <Grid>
        <SuccessAlert
          alertMsg={alrtMsg}
          open={successAlrtOpen}
          close={closeSuccess}
        />
        <ErrorAlert
          alertMsg={alrtMsg}
          open={errorAlrtOpen}
          close={closeError}
        />
      </Grid>
    </>
  );
};
