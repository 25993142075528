import { Grid, Typography,Pagination, Stack  } from "@mui/material";
import React from "react";
import { PaginationContainer } from "../styles/common_styles";

export const HFPagination = ({ details, paginate }) => {
  const { currentPage, RecordsForPage, totalRecords, pageCount } = details;
  const startRecord = (currentPage - 1) * RecordsForPage + 1;
  const endRecord = Math.min(currentPage * RecordsForPage, totalRecords);
  
  const PaginationText =
    totalRecords <= 10 ? (
      <Typography>
        Showing {totalRecords===0?0:startRecord}-{endRecord} from {totalRecords}
      </Typography>
    ) : (
      <Typography>
        Showing {startRecord}-{endRecord} from {totalRecords}
      </Typography>
    );
  const handleChange = (event, value) => {
    paginate(value);
  };

  return (
    <>
      <Grid container sx={PaginationContainer}>
        <Grid>{PaginationText}</Grid>
        <Grid>
          <Stack spacing={2}>
            <Pagination
              count={pageCount ? pageCount : 1}
              page={currentPage ? currentPage : 1}
              size="large"
              onChange={handleChange}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};