import Header from "../../components/layout_components/header";
import SideNav from "../../components/layout_components/sidenav";
import {
  LayoutStyle,
  LayoutContainerStyle,
  LayoutBodyStyle,
} from "../../styles/common_styles";
import { Outlet } from "react-router-dom";
import { Box, Grid } from "@mui/material";

const Layout = () => {
  return (
    <>
      <Box sx={LayoutStyle}>
        <Grid container>
          <Grid item md={2}>
            <SideNav />
          </Grid>
          <Grid item md={10}>
            <Grid>
              <Header />
            </Grid>
            <Grid sx={LayoutContainerStyle}>
              <Grid sx={LayoutBodyStyle}>
                <Outlet/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Layout;
