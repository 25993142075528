import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  FormControl,
  TextField,
  Box,
  Typography,
  Modal,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
} from "@mui/material";
import { ButtonComponent } from "./form_components/button";
import { AuthServices } from "../services/auth_services";
import { CustomField, Emaillable, LoginForm } from "../styles/loginstyles";
import {
  modalStyle,
  modalHeading,
  blockOrDeleteModalStyle,
  Errors,
  selectCheckBoxStyle,
} from "../styles/common_styles";
import { CommonServices } from "../services/common_services";
import { useForm } from "react-hook-form";
import { FormExpressions } from "../utils/regularExpressions";
import { SuccessAlert, ErrorAlert } from "./alert";
import {
  GetAdminDetails,
  constructStateDetails,
  handleCountryChangeList,
  constructCityDetails,
  prepareAddressList,
} from "../utils/helpers";
import { BackDropLoader } from "./loader_component";
import { Loader } from "./loader_component";
import CloseIcon from "@mui/icons-material/Close";
import debounce from "lodash.debounce";

export const AddModalComponent = ({ openhandler, handleClose, trigger }) => {
  const [selectedRoles, setSelectedRoles] = useState(0);
  const [roles, setRoles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showCountry, setShowCountry] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [backDropLoaderOpen, setBackDropLoaderOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [preparedStates, setPreparedStates] = useState([]);
  const [preparedCities, setPreparedCities] = useState([]);
  const [adminDetails, setAdminDetails] = useState({});
  const [loaderData, setLoaderData] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  /**
   * Handles toggling the backdrop loader state.
   */
  const handleToggleBackDropLoader = () => {
    setBackDropLoaderOpen(!backDropLoaderOpen);
  };

  /**
   * This function closes the success alert.
   */
  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  /**
   * Closes the error alert.
   */
  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  /**
   * Handles the change of role and updates the UI accordingly.
   *
   * @param {{ target: { value: type }}} param - Destructured object with the target value
   * @return {void}
   */
  const handleChangeRole = ({ target: { value } }) => {
    setSelectedRoles(value);
    setShowCountry(value >= 3 && value <= 6);
    setShowState(value >= 4 && value <= 6);
    setShowCity(value === 5 || value === 6 || value === 4);
    setValue("role_id", value, { shouldValidate: true });
  };

  /**
   * A function that handles the change of country.
   *
   * @param {event} event - the event triggering the function
   * @return {void}
   */
  // const handleCountryChange = (event) => {
  //   setSelectedCountries(event.target.value);
  //   GetStates(2, event.target.value);
  // };
  const handleCountryChange = useCallback(
    debounce((event) => {
      const selectedValue = event.target.value;
      setSelectedCountries(selectedValue);
      GetStates(2, selectedValue); // Example of a side effect inside the callback
    }, 300), // Adjust the debounce delay as needed
    []
  );

  /**
   * Handles the change event of the autocomplete state.
   *
   * @param {Event} event - The change event.
   * @return {void} This function does not return anything.
   */
  const handleAutocompleteStateChange = (event) => {
    const { value } = event.target;
    setSelectedStates(value);
    GetCities(3, value, selectedCountries);
  };

  /**
   * Handles the change event for the autocomplete city input.
   *
   * @param {Event} event - the event object
   * @return {void}
   */
  const handleAutocompleteCityChange = (event) => {
    const { value } = event.target;
    setSelectedCities(value);
  };

  /**
   * Function to retrieve admin roles and handle the response accordingly.
   *
   * @return {Promise<void>} - A promise that resolves when the function completes
   */
  const AdminRoles = async () => {
    try {
      const Roles = await AuthServices.getRoles();
      if (Roles.status === 1) {
        setRoles(Roles.data);
      } else {
        alert(Roles.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  /**
   * Function to get countries asynchronously.
   */
  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  /**
   * GetStates function retrieves states based on type and country.
   *
   * @param {string} type - type of location
   * @param {string} country - country of location
   * @return {Promise} data from CommonServices
   */
  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  /**
   * GetCities function retrieves cities based on type, state, and country.
   *
   * @param {type} type - The type of cities to retrieve
   * @param {state} state - The state for which cities are to be retrieved
   * @param {country} country - The country for which cities are to be retrieved
   * @return {Promise} Promise that resolves to the retrieved cities
   */
  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  /**
   * Function to set the super admin address based on the selected countries.
   *
   * @return {Array} An array containing the super admin addresses for the selected countries.
   */
  const setSuperAdminAddress = async () => {
    const superAdminAddress = [];
    selectedCountries?.forEach((city) => {
      superAdminAddress.push({
        country_id: city,
        state_id: null,
        city_ids: [],
      });
    });
    return superAdminAddress;
  };

  /**
   * Async function to submit form data.
   *
   * @param {Object} data - the form data to be submitted
   * @return {Promise} a Promise that resolves with the result of form submission
   */
  const SubmitForm = async (data) => {
    if (selectedRoles === 0) {
      return;
    }
    // Open backdrop loader
    // setBackDropLoaderOpen(true);
    // Close the modal
    // handleClose();

    // Initialize empty address list for CEOs
    const ceoAddressList = [
      {
        country_id: null,
        state_id: null,
        city_ids: [],
      },
    ];

    // Determine the address list based on selected roles
    let superAdminAddress;
    if (selectedRoles === 3) {
      superAdminAddress = await setSuperAdminAddress();
    } else {
      superAdminAddress = await prepareAddressList(
        selectedCountries,
        preparedStates,
        preparedCities
      );
    }

    try {
      // Get admin details
      const AdminDetails = await GetAdminDetails();
      // Set location list based on selected roles and addresses
      const locationList =
        selectedRoles === 2 || selectedRoles === 1
          ? ceoAddressList
          : superAdminAddress;

      // Prepare payload for AddAdmin API
      const payload = {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phone,
        email: data.email,
        address: data.address,
        role_id: selectedRoles,
        user_id: AdminDetails.id,
        location_list: locationList,
      };
      setLoaderData(true);
      // Call AddAdmin API
      const response = await CommonServices.addAdmin(payload);

      // If API call is successful
      if (response.status === 1) {
        setLoaderData(false);
        handleClose();
        // Close backdrop loader
        setBackDropLoaderOpen(false);
        // Set success message
        setAlrtMsg(response.message);
        // Open success alert
        setSuccessAlrtOpen(true);
        // Reset form
        reset({
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          address: "",
          role_id: "",
        });
        trigger();
        // Reset selected countries, states and cities
        setSelectedCountries([]);
        setSelectedStates([]);
        setSelectedCities([]);
        setSelectedRoles(0);
        GetCountries();
        GetAdminDetails();
      } else {
        setLoaderData(false);
        // If API call fails
        setBackDropLoaderOpen(false);
        // Set error message
        setAlrtMsg(response.message);
        // Open error alert
        setErrorAlrtOpen(true);
        // Close the modal
        // handleClose();
        GetCountries();
        GetAdminDetails();
      }
    } catch (error) {
      console.error(`Error occurred while submitting form: ${error}`);
      setBackDropLoaderOpen(false);
    }
  };

  const settingAdmin = async () => {
    const AdminDetails = await GetAdminDetails();
    setAdminDetails(AdminDetails);
  };

  useEffect(() => {
    AdminRoles();
    GetCountries();
    settingAdmin();
  }, []);

  useEffect(() => {
    consStateObj();
  }, [selectedStates]);

  useEffect(() => {
    consCityObj();
  }, [selectedCities]);

  useEffect(() => {
    if (selectedCountries?.length > 0) {
      consCountryObj();
    }
  }, [selectedCountries]);

  const consStateObj = async () => {
    const data = await constructStateDetails(
      states,
      selectedStates,
      preparedCities,
      selectedCities
    );
    if (data) {
      setPreparedStates(data?.list);
      setPreparedCities(data?.filteredCitiesList);
      setSelectedCities(data?.filteredCityIds);
    }
  };

  const consCountryObj = async () => {
    const { filteredSelectedStates, filteredStatesList } =
      await handleCountryChangeList(
        selectedCountries,
        selectedStates,
        preparedStates
      );
    setPreparedStates(filteredStatesList);
    setSelectedStates(filteredSelectedStates);
  };

  const consCityObj = async () => {
    const data = await constructCityDetails(cities, selectedCities);
    setPreparedCities(data);
  };

  return (
    <>
      <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
      <Modal
        open={openhandler}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Grid container>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs>
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  Add user
                </div>
              </Grid>
              <Grid item>
                <IconButton
                  mb={2}
                  onClick={handleClose}
                  sx={{ color: "black", backgroundColor: "grey" }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          {loaderData ? (
            <Loader />
          ) : (
            <form onSubmit={handleSubmit(SubmitForm)}>
              <Grid container spacing={2} pl={4} pr={4}>
                <Grid item md={6}>
                  <FormControl fullWidth sx={LoginForm}>
                    <Grid sx={Emaillable}>First Name</Grid>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      size={"small"}
                      type={"text"}
                      inputProps={{
                        placeholder: "Enter first name",
                      }}
                      {...register("firstName", {
                        required: "Please enter first name",
                      })}
                    />
                    {errors.firstName && (
                      <Typography sx={Errors}>
                        {errors.firstName.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth sx={LoginForm}>
                    <Grid sx={Emaillable}>Last Name</Grid>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      size={"small"}
                      type={"text"}
                      inputProps={{
                        placeholder: "Enter last name",
                      }}
                      {...register("lastName", {
                        required: "Please enter last name",
                      })}
                    />
                    {errors.lastName && (
                      <Typography sx={Errors}>
                        {errors.lastName.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth sx={LoginForm}>
                    <Grid sx={Emaillable}>Phone Number</Grid>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      size={"small"}
                      inputProps={{
                        placeholder: "Enter phone number",
                      }}
                      {...register("phone", {
                        required: true,
                        pattern: FormExpressions.phone,
                      })}
                    />
                    {errors.phone && errors.phone.type === "required" && (
                      <Typography sx={Errors}>
                        Please enter phone number
                      </Typography>
                    )}
                    {errors.phone && errors.phone.type === "pattern" && (
                      <Typography sx={Errors}>
                        please enter valid phone
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth sx={LoginForm}>
                    <Grid sx={Emaillable}>Email</Grid>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      size={"small"}
                      inputProps={{
                        placeholder: "Enter email",
                      }}
                      {...register("email", {
                        required: true,
                        pattern: FormExpressions.email,
                      })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <Typography sx={Errors}>
                        Please enter your email
                      </Typography>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <Typography sx={Errors}>
                        please enter valid email
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth sx={LoginForm}>
                    <Grid sx={Emaillable}>Address</Grid>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      size={"small"}
                      type={"text"}
                      inputProps={{
                        placeholder: "Enter address",
                      }}
                      {...register("address", {
                        required: "Please enter address",
                      })}
                    />
                    {errors.address && (
                      <Typography sx={Errors}>
                        {errors.address.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <Grid sx={Emaillable}>Role</Grid>
                    <Select
                      displayEmpty
                      size={"small"}
                      sx={CustomField}
                      value={selectedRoles || ""}
                      {...register("role_id", {
                        required: {
                          value: true,
                          message: "Please select a role",
                        },
                        validate: (value) =>
                          value !== "0" || "Please select a role",
                      })}
                      onChange={handleChangeRole}
                      renderValue={(selected) =>
                        selected ? (
                          <Grid sx={selectCheckBoxStyle}>
                            {roles
                              .filter((role) => role.id === selected)
                              .map((role) => (
                                <span key={role.id}>{role.role_name}</span>
                              ))}
                          </Grid>
                        ) : (
                          "Select Role"
                        )
                      }
                    >
                      <MenuItem disabled value="">
                        {selectedRoles && selectedRoles.length === 0
                          ? "Select Role"
                          : ""}
                      </MenuItem>
                      {adminDetails.is_user_admin === true ||
                      adminDetails.role_id === 2
                        ? roles.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.role_name}
                            </MenuItem>
                          ))
                        : roles
                            .filter(
                              (option) => option.role_name !== "UserAdmin"
                            )
                            .map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.role_name}
                              </MenuItem>
                            ))}
                    </Select>
                    {selectedRoles === 0 && errors.role_id && (
                      <Typography sx={Errors}>
                        {errors.role_id.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {showCountry && (
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <Grid sx={Emaillable}>Country</Grid>
                      <Select
                        displayEmpty
                        size={"small"}
                        sx={CustomField}
                        multiple // Enable multiple selection
                        value={selectedCountries}
                        {...register("country", {
                          required: "Please select at least one country",
                        })}
                        onChange={handleCountryChange}
                        renderValue={(selected) =>
                          selected.length === 0 ? (
                            "Select Country"
                          ) : (
                            <Grid sx={selectCheckBoxStyle}>
                              {Array.isArray(selected) &&
                                selected.map((countryId, index) => {
                                  const country = countries.find(
                                    (country) => country.id === countryId
                                  );
                                  return (
                                    <span key={country.id}>
                                      {index > 0 && ", "}
                                      {country.name}
                                    </span>
                                  );
                                })}
                            </Grid>
                          )
                        }
                      >
                        <MenuItem disabled value="">
                          {selectedCountries.length === 0
                            ? "Select Country"
                            : ""}
                        </MenuItem>
                        {countries.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            <Checkbox
                              checked={selectedCountries.includes(option.id)}
                            />
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {selectedCountries.length === 0 && errors.country && (
                        <Typography sx={Errors}>
                          Please select at least one country.
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                )}
                {showState && (
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <Grid sx={Emaillable}>State</Grid>
                      <Select
                        displayEmpty
                        size={"small"}
                        sx={CustomField}
                        multiple // Enable multiple selection
                        value={selectedStates}
                        onChange={handleAutocompleteStateChange}
                        renderValue={(selected) =>
                          selected.length === 0 ? (
                            "Select State"
                          ) : (
                            <Grid sx={selectCheckBoxStyle}>
                              {Array.isArray(selected) &&
                                selected.map((stateId, index) => {
                                  const state = states?.find(
                                    (stateRecord) => stateRecord.id === stateId
                                  );
                                  return (
                                    <span key={state?.id}>
                                      {index > 0 && ", "}
                                      {state?.name}
                                    </span>
                                  );
                                })}
                            </Grid>
                          )
                        }
                      >
                        <MenuItem disabled value="">
                          {selectedStates.length === 0 ? "Select State" : ""}
                        </MenuItem>
                        {states.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            <Checkbox
                              checked={
                                Array.isArray(selectedStates) &&
                                selectedStates.includes(option.id)
                              }
                            />
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {selectedStates &&
                        selectedStates.length === 0 &&
                        errors.state && (
                          <Typography sx={Errors}>
                            Please select at least one state.
                          </Typography>
                        )}
                    </FormControl>
                  </Grid>
                )}

                {showCity && (
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <Grid sx={Emaillable}>City</Grid>
                      <Select
                        displayEmpty
                        size={"small"}
                        sx={CustomField}
                        multiple // Enable multiple selection
                        value={selectedCities || ""}
                        onChange={handleAutocompleteCityChange}
                        renderValue={(selected) =>
                          selected.length === 0 ? (
                            "Select City"
                          ) : (
                            <Grid sx={selectCheckBoxStyle}>
                              {Array.isArray(selected) &&
                                selected.map((cityId, index) => {
                                  const city = cities.find(
                                    (city) => city.id === cityId
                                  );
                                  return (
                                    <span key={city?.id}>
                                      {index > 0 && ", "}
                                      {city?.name}
                                    </span>
                                  );
                                })}
                            </Grid>
                          )
                        }
                      >
                        <MenuItem disabled value="">
                          {selectedCities.length === 0 ? "Select City" : ""}
                        </MenuItem>
                        {cities.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            <Checkbox
                              checked={
                                Array.isArray(selectedCities) &&
                                selectedCities.includes(option.id)
                              }
                            />
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {selectedCities &&
                        selectedCities.length === 0 &&
                        errors.cities && (
                          <Typography sx={Errors}>
                            Please select at least one city.
                          </Typography>
                        )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item md={12}>
                  <Grid container justifyContent={"center"} p={3}>
                    <ButtonComponent type={"submit"} btnText={"ADD"} />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Box>
      </Modal>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};

export const BlockOrDeleteModalComponent = ({
  deleteopen,
  onClose,
  title,
  resType,
}) => {
  const handleNo = async () => {
    resType(1);
  };
  const handleYes = async () => {
    resType(2);
  };
  return (
    <>
      <Modal
        open={deleteopen ? deleteopen : false}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={blockOrDeleteModalStyle}>
          <Typography textAlign={"center"}>{title}</Typography>
          <Grid container justifyContent={"space-around"} sx={{ mt: 3 }}>
            <Grid item>
              <ButtonComponent btnText={"No"} onClick={handleNo} />
            </Grid>
            <Grid item>
              <ButtonComponent btnText={"Yes"} onClick={handleYes} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
