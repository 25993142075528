import AxiosClient from "../axios/client";

export const HFDashboard = {
    userAdminDashboard: async (userId) => {
        try {
            const userAdminDashboard = await AxiosClient.post(
              "userAdminDashboardDetails",
              {
                userId,
              },
              { useAccessToken: true }
            );
              return userAdminDashboard;
        } catch (error) {
            console.error("Error fetching roles:", error);
        }
    }
}