import React from "react";
import {
  Typography,
  Box,
  Grid,
  // IconButton
} from "@mui/material";
// import Rating from '@mui/material/Rating';
import {
  Userheading,
  Cardstyle,
  Useradminheading,
  Paragraph,
} from "../../styles/common_styles";
// import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import "../../styles/common_styles.css";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { HFDashboard } from "../../services/dashboard_services";
import { GetAdminDetails } from "../../utils/helpers";
import { Loader } from "../../components/loader_component";
import { GetRoleDetails } from "../../utils/helpers";
import "../../styles/common_styles.css";
import { DashBoardImages } from "../../utils/imageImports";
import { useNavigate } from "react-router-dom";
import { StorageClient } from "../../utils/helpers";
import { ManagementServices } from "../../services/management_services";
import AvatarImg from "../../assets/images/avatar.svg";
import {
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Rating,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { IconButtonStyle, VisibilityStyle } from "../../styles/common_styles";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { ViewMoreModal } from "../../components/viewmoreModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CommonServices } from "../../services/common_services";
import MonthlyRevenueChart from "../../components/chart_components/monthRevenue";
import { Graphdshboard } from "./graphdashboard";

export const DashBoard = () => {
  const navigate = useNavigate();
  const [rolesDetails, setRolesDetails] = React.useState([]);
  const [loaderData, setLoaderData] = React.useState(true);
  const [chefviewModal, setchefViewModal] = React.useState(false);
  const [consumerviewModal, setConsumerviewModal] = React.useState(false);
  const [operationalData, setOperationalData] = React.useState([]);
  const [type, setType] = React.useState("");
  const [chefList, setChefList] = React.useState([]);
  const [consumerList, setConsumerList] = React.useState([]);
  const [foodList, setFoodList] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [selectedState, setSelectedState] = React.useState(0);
  const [selectedCity, setSelectedCity] = React.useState(0);
  const [roleDetails, setRoleDetails] = React.useState({
    role_name: "",
    role_id: "",
  });

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching Countries:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching states:${error}`);
    }
  };

  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching cities:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const getChefandConsumerListandFood = async () => {
    const payload = {
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
    };
    try {
      const chefList = await ManagementServices.getChefandConsumerandFoodItems(
        payload
      );
      setChefList(chefList.data.topChefs);
      setConsumerList(chefList.data.topConsumers);
      setFoodList(chefList.data.topFoods);
    } catch (error) {
      console.error(`Error occured while fetching Chef List:${error}`);
    }
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, value);
    setSelectedState(0);
    setSelectedCity(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    GetCities(3, event.target.value, selectedCountry);
    setSelectedCity(0);
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  const handleChefViewOpen = () => {
    setchefViewModal(true);
    setType("chef");
  };
  const handleConsumerviewOpen = () => {
    setConsumerviewModal(true);
    setType("consumer");
  };

  const handleChefViewClose = () => {
    setchefViewModal(false);
    setType("");
  };

  const handleConsumerviewClose = () => {
    setConsumerviewModal(false);
    setType("");
  };

  const UserAdminDashboard = async () => {
    try {
      const AdminDetails = await GetAdminDetails();
      const GetRoleDetailss = await GetRoleDetails();
      setRoleDetails({
        ...roleDetails,
        role_name: GetRoleDetailss.role_name,
        role_id: GetRoleDetailss.role_id,
      });

      if (AdminDetails) {
        const USRDashboard = await HFDashboard.userAdminDashboard(
          AdminDetails.id
        );
        if (USRDashboard.status === 1) {
          setRolesDetails(USRDashboard.data);
          setLoaderData(false);
        }
      }
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
  };

  const handleOperationalData = async () => {
    const operationalData = await ManagementServices.getOperationalDetails();
    if (operationalData?.status === 1) {
      setOperationalData(operationalData.data);
      setLoaderData(false);
    } else {
      console.log(operationalData);
    }
  };

  const handleViewClick = (item) => {
    switch (item) {
      case 2:
        StorageClient.setItem("previlageId", item);
        navigate("/globalOperations");
        break;
      case 3:
        StorageClient.setItem("previlageId", item);
        navigate("/superAdmin");
        break;
      case 4:
        StorageClient.setItem("previlageId", item);
        navigate("/manager");
        break;
      case 5:
        StorageClient.setItem("previlageId", item);
        navigate("/supervisor");
        break;
      case 6:
        StorageClient.setItem("previlageId", item);
        navigate("/csr");
        break;
      default:
        break;
    }
  };

  const handleClick = (key) => {
    switch (key) {
      case "CEO":
        StorageClient.setItem("previlageId", 2);
        navigate("/globalOperations");
        break;
      case "CSR":
        StorageClient.setItem("previlageId", 6);
        navigate("/csr");
        break;
      case "Manager":
        StorageClient.setItem("previlageId", 4);
        navigate("/manager");
        break;
      case "SuperAdmin":
        StorageClient.setItem("previlageId", 3);
        navigate("/superAdmin");
        break;
      case "Supervisor":
        StorageClient.setItem("previlageId", 5);
        navigate("/supervisor");
        break;
      case "UserAdmin":
        StorageClient.setItem("previlageId", 1);
        navigate("/userAdmin");
        break;
      case "chef":
        navigate("/chef");
        break;
      case "consumer":
        navigate("/Consumer");
        break;
      case "orders":
        navigate("/orders");
        break;
      case "totalRevenue":
        navigate("/dashboard");
        break;
      default:
        break;
    }
  };

  const renderCards = () => {
    const ExcludedKeys = ["consumer", "chef", "orders", "totalRevenue"];

    if (loaderData) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Loader />
        </Grid>
      );
    } else {
      return (
        <>
          {Object.keys(operationalData).map((key) => {
            let imageSrc =
              DashBoardImages[key] || DashBoardImages["SuperAdmin"];
            return (
              <Grid
                container
                alignItems="center"
                key={key}
                item
                lg={2.86}
                xl={2.28}
                sx={Cardstyle}
                onClick={() => handleClick(key)}
              >
                <Grid>
                  <img src={imageSrc} alt="profile" className="cardImage" />
                </Grid>
                <Grid>
                  <Grid sx={Useradminheading}>{operationalData[key]}</Grid>
                  <Grid className="para" sx={Paragraph}>
                    {ExcludedKeys.includes(key) ? key : `Role: ${key}`}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </>
      );
    }
  };

  React.useEffect(() => {
    UserAdminDashboard();
    handleOperationalData();
  }, []);

  React.useEffect(() => {
    GetCountries();
    getChefandConsumerListandFood();
  }, [selectedCountry, selectedState, selectedCity]);

  return (
    <>
      {(roleDetails.role_name === "Global User, UserAdmin" ||
        roleDetails.role_name === "UserAdmin") && (
        <Box>
          <Grid>
            <Grid>
              <Typography variant="h6" sx={Userheading}>
                User Admin Dashboard
              </Typography>
            </Grid>
            {loaderData ? (
              <Loader />
            ) : (
              <>
                <Grid container gap={2} p={2}>
                  {rolesDetails.map((item) => {
                    const imagePath = DashBoardImages[item.role_name];
                    return (
                      <Grid
                        container
                        alignItems="center"
                        key={item.id}
                        item
                        lg={2.86}
                        xl={2.28}
                        sx={Cardstyle}
                        onClick={() => handleViewClick(item.id)}
                      >
                        <Grid>
                          <img
                            src={imagePath}
                            alt="profile"
                            className="cardImage"
                          />
                        </Grid>
                        <Grid>
                          <Grid sx={Useradminheading}>{item.user_count}</Grid>
                          <Grid className="para" sx={Paragraph}>
                            Role: {item.role_name}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}
      {
        <Grid container gap={2} p={2} m={1} pb={5}>
          {(roleDetails.role_name === "CEO" ||
            roleDetails.role_name === "SuperAdmin") &&
            renderCards()}
        </Grid>
      }
      {/* {(roleDetails.role_name==='Global User, UserAdmin' || roleDetails.role_name ==='SuperAdmin') &&  */}
      {(roleDetails.role_name === "SuperAdmin" ||
        roleDetails.role_name === "CEO" ||
        roleDetails.role_name === "Global User, UserAdmin") && (
        <Grid container>
          <Grid
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
            mb={6}
            mt={3}
          >
            <Grid md={1.6} mr={1}>
              <FormControl fullWidth>
                <Select
                  value={selectedCountry !== null ? selectedCountry : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "Country" }}
                  onChange={handleChangeCountry}
                >
                  <MenuItem value={0} disabled>
                    Select Country
                  </MenuItem>
                  {totalCountries}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1.6} mr={1}>
              <FormControl fullWidth>
                <Select
                  value={selectedState !== null ? selectedState : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "State" }}
                  onChange={handleChangeState}
                >
                  <MenuItem value={0} disabled>
                    Select State
                  </MenuItem>
                  {totalStates}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1.6} mr={1}>
              <FormControl fullWidth>
                <Select
                  value={selectedCity !== null ? selectedCity : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "City" }}
                  onChange={handleChangeCity}
                >
                  <MenuItem value={0} disabled>
                    Select City
                  </MenuItem>
                  {totalCities}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid xs={6} mr={1} ml={3}>
            <Box>
              <TableContainer sx="">
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Top 5 Chefs
                </Typography>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx="">#</TableCell>
                      <TableCell sx="">Image</TableCell>
                      <TableCell sx="">Name</TableCell>
                      <TableCell sx="">Rating</TableCell>
                      <TableCell sx="">Orders</TableCell>
                      <TableCell sx="">Country</TableCell>
                      <TableCell sx="">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {chefList.slice(0, 5).map((chef, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <img
                            src={chef.chef_profile}
                            style={{
                              height: "60px",
                              width: "60px",
                              borderRadius: "100px",
                            }}
                            alt=""
                          />
                        </TableCell>
                        <TableCell>{chef.chef_name}</TableCell>
                        <TableCell>
                          <Rating
                            name="half-rating"
                            value={parseFloat(chef.average_rating).toFixed(1)}
                            precision={0.5}
                            readOnly
                          />
                        </TableCell>
                        <TableCell>{chef.order_count}</TableCell>
                        <TableCell>{chef.chef_country}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              navigate("/chef/about", {
                                state: {
                                  module: 2,
                                  userId: chef.chef_id,
                                  type: 11,
                                },
                              })
                            }
                          >
                            <VisibilityIcon
                              sx={{ color: "black !important" }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton alignSelf="center">
                    <Typography color="black" onClick={handleChefViewOpen}>
                      View More &gt;&gt;
                    </Typography>
                  </IconButton>
                </Grid>
              </TableContainer>
              <Grid>
                <ViewMoreModal
                  open={chefviewModal}
                  handleClose={handleChefViewClose}
                  type={type}
                  chefList={chefList}
                />
              </Grid>
            </Box>
            <TableContainer sx={{ mt: 6 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Top 5 Consumers
              </Typography>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell sx="">#</TableCell>
                    <TableCell sx="">Image</TableCell>
                    <TableCell sx="">Name</TableCell>
                    <TableCell sx="">Orders</TableCell>
                    <TableCell sx="">Country</TableCell>
                    <TableCell sx="">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {consumerList.slice(0, 5).map((chef, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <img
                          src={chef.consumer_profile || AvatarImg}
                          style={{
                            height: "60px",
                            width: "60px",
                            borderRadius: "100px",
                          }}
                          alt={chef.name}
                        />
                      </TableCell>
                      <TableCell>{chef.consumer_name}</TableCell>
                      <TableCell>{chef.order_count}</TableCell>
                      <TableCell>{chef.consumer_country}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate("/Consumer/about", {
                              state: {
                                module: 1,
                                userId: chef.consumer_id,
                                type: 11,
                              },
                            })
                          }
                        >
                          <VisibilityIcon sx={{ color: "black !important" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton alignSelf="center">
                  <Typography color="black" onClick={handleConsumerviewOpen}>
                    View More &gt;&gt;
                  </Typography>
                </IconButton>
              </Grid>
              <Grid>
                <ViewMoreModal
                  open={consumerviewModal}
                  handleClose={handleConsumerviewClose}
                  type={type}
                  consumerList={consumerList}
                />
              </Grid>
            </TableContainer>
          </Grid>
          <Grid xs={5}>
            <TableContainer sx="">
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Top 10 Foods
              </Typography>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell sx="">#</TableCell>
                    <TableCell sx="">Item</TableCell>
                    <TableCell sx="">Country</TableCell>
                    <TableCell sx="">Orders</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {foodList.slice(0, 10).map((chef, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <Grid container alignItems={"center"}>
                          <img
                            src={chef.food_image}
                            style={{
                              height: "60px",
                              width: "60px",
                              borderRadius: "100px",
                            }}
                            alt={chef.name ? chef.name : "Chef Avatar"}
                          />
                          <Typography pl={2}>{chef.food_name}</Typography>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Typography>{chef.country_name}</Typography>
                      </TableCell>
                      <TableCell>{chef.order_count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}

      {(roleDetails.role_name === "CEO" ||
        roleDetails.role_name === "Global User, UserAdmin") && (
        <Grid>
          <Graphdshboard />
        </Grid>
      )}
    </>
  );
};

// export const CeoDashBoard = () => {

//     const [rolesDetails, setRolesDetails] = React.useState([]);
//     const [loaderData, setLoaderData] = React.useState(true);

//     // const UserAdminDashboard = async () => {
//     //     try {
//     //         const AdminDetails = await GetAdminDetails();
//     //         if (AdminDetails) {
//     //             const USRDashboard = await HFDashboard.userAdminDashboard(
//     //             AdminDetails.id
//     //             );
//     //             if (USRDashboard.status === 1) {
//     //             setRolesDetails(USRDashboard.data);
//     //                 setLoaderData(false);
//     //             }
//     //         }
//     //     } catch (error) {
//     //         console.error(`Error occurred: ${error}`);
//     //     }

//     // }

// React.useEffect(()=>{
//     // UserAdminDashboard()
// },[])

// return (

//   )
// }
