import CommonRoutes from "./common_routes"
import ManagementRoutes from "./management_routes";

const Router = () => {
    return (
        <>
            {/* These are public routes and can access with out authentication */}
            <CommonRoutes />

            {/* These are private routes and can access only with authentication */}
            <ManagementRoutes />
            
        </>
    )
}

export default Router;