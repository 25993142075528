import React from "react";
import {
  Grid,
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { CommonServices } from "../services/common_services";
import { ManagementServices } from "../services/management_services";
import { FaqModalStyle, modalHeading, Errors } from "../styles/common_styles";
import { Emaillable, CustomField } from "../styles/loginstyles";
import { ButtonComponent } from "./form_components/button";
import CloseIcon from "@mui/icons-material/Close";
import { SuccessAlert } from "./alert";
import { ErrorAlert } from "./alert";

const EditCommercialModal = ({
  open,
  handleClose,
  trigger,
  // type,
  edit,
  editList,
  addType,
}) => {
  const {
    // register: register1,
    handleSubmit: handleSubmit1,
    // setValue: setValue1,
    // reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const editObject = editList.find((item) => item.id === edit);
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);
      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country || selectedCountry || editObject?.country_id,
      };
      const States = await CommonServices.getLocation(payload);
      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (type, country, state) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state || selectedCountry || editObject?.state_id,
      };
      if (state !== null) {
        const Cities = await CommonServices.getLocation(payload);
        console.log("Cities", Cities);
        if (Cities.status === 1) {
          setCities(Cities.data);
        } else {
          alert(Cities.message);
        }
      } else {
        setCities([]);
      }
    } catch (error) {
      console.error(`Error occured while fetching cities:${error}`);
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, event.target.value || editObject?.country_id);
    setSelectedState(null);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    GetCities(2, event.target.value || editObject?.state_id, selectedCountry);
    setSelectedCity(null);
  };
  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  // const totalCountries = countries.map((x) => (
  //   <MenuItem key={x.id} value={x.id}>
  //     {x.name}
  //   </MenuItem>
  // ));

  const totalStates = states?.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities?.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const commercialKitchenSubmit = async (data) => {
    const payload = {
      id: edit,
      country_id: selectedCountry || editObject.country_id,
      state_id: selectedState || editObject.state_id,
      city_id: selectedCity || editObject.city_id,
      type: addType,
    };
    const editDetails = await ManagementServices.editcommercialKitchenItem(
      payload
    );
    if (editDetails.status === 1) {
      handleClose();
      setAlrtMsg(editDetails.message);
      setSuccessAlrtOpen(true);
      trigger();
      setSelectedCountry(null);
      setSelectedState(null);
      setSelectedCity(null);
    } else {
      setAlrtMsg(editDetails.message);
      setErrorAlrtOpen(true);
    }
  };

  React.useEffect(() => {
    GetCountries();
  }, [editObject]);

  React.useEffect(() => {
    GetStates(2, editObject?.country_id);
    GetCities(2, editObject?.country_id, editObject?.state_id);
  }, [editObject?.state_id, editObject?.country_id]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={FaqModalStyle}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography sx={modalHeading}>Update</Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleClose}
                sx={{ color: "black", backgroundColor: "grey" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            component={"form"}
            onSubmit={handleSubmit1(commercialKitchenSubmit)}
          >
            <Grid container justifyContent={"center"} spacing={3}>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Country</Grid>
                  <Select
                    sx={CustomField}
                    defaultValue={editObject?.country_id || ""}
                    size={"small"}
                    onChange={handleChangeCountry}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select country
                    </MenuItem>
                    {countries.map((x) => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
                    {/* {totalCountries} */}
                  </Select>
                  {errors1.countryId &&
                    errors1.countryId.type === "required" && (
                      <Typography sx={Errors}>
                        Please select country.
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>State</Grid>
                  <Select
                    sx={CustomField}
                    defaultValue={editObject?.state_id || ""}
                    size={"small"}
                    onChange={handleChangeState}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select State
                    </MenuItem>
                    {totalStates}
                  </Select>
                </FormControl>
              </Grid>
              {addType === 2 && (
                <Grid item md={10}>
                  <FormControl fullWidth>
                    <Grid sx={Emaillable}>City</Grid>
                    <Select
                      sx={CustomField}
                      defaultValue={editObject?.city_id || ""}
                      size={"small"}
                      onChange={handleChangeCity}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select City
                      </MenuItem>
                      {totalCities}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid container justifyContent={"center"} p={3}>
                <ButtonComponent type={"submit"} btnText={"Update"} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};

export default EditCommercialModal;
