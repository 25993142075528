import React from "react";
import { Grid, Tab, Modal, Box } from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import { ButtonComponent } from "../../components/form_components/button";
import { BackDropLoader } from "../../components/loader_component";
import { TitleComponent } from "../../components/title_component";
import { tabStyle, tabItem, tabHeader } from "../../styles/common_styles";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import { CurrencyTaxHandling } from "../../components/currencyTaxHandling";
import FileUpload from "./currencyExcelUpload";
import DownloadToExcel from "./currencyDownload";
import { ManagementServices } from "../../services/management_services";

export const CurrencyTax = () => {
  const [value, setValue] = React.useState("1");
  const [renderingData, setRenderingData] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [backDropLoaderOpen, setBackDropLoaderOpen] = React.useState(false);
  const [searchData, setSearchData] = React.useState("");
  const [uploadModal, setUploadModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleUploadModalOpen = () => {
    setUploadModal(true);
  };

  const handleUploadModalClose = () => {
    setUploadModal(false);
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleMOdalClose = () => setModalOpen(false);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggleBackDropLoader = () => {
    setBackDropLoaderOpen(!backDropLoaderOpen);
  };

  const handleSearchData = async (data) => {
    setSearchData(data);
  };
  
  const sendDataToBackend = async (data) => {
    const payload = {
      currencyConversionData: data,
    };
    const response = await ManagementServices.uploadCurrencyConversionRate(
      payload
    );
    if (response?.status === 1) {
      handleUploadModalClose();
      setRenderingData(true)
    } else {
      setErrorMessage("file should contain name and conversion rate columns");
    }
  };

  React.useEffect(() => {
    
  }, []);
  const handleUploadModal = () => {
    return (
      <Modal
        open={uploadModal}
        onClose={handleUploadModalClose}
        aria-labelledby="chat-modal-title"
        aria-describedby="chat-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            height: 200,
            bgcolor: "background.paper",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            outline: 0,
            borderRadius: 5,
          }}
        >
          <Box>
            <>
              <Grid>
                <FileUpload modalClose={handleUploadModalClose} sendData = {sendDataToBackend} />
              </Grid>
            </>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
      <Grid
        container
        justifyContent={"space-between"}
        alignItems="center"
        spacing={2}
        p={3}
        sx={tabHeader}
      >
        <Grid item md={3}>
          <TitleComponent title={"Currency&Tax"} />
        </Grid>
        <Grid item md={3}>
          <TabContext value={value}>
            <TabList
              sx={tabStyle}
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
            >
              <Tab label="Currency" value="1" sx={tabItem} />
              <Tab label="Tax" value="2" sx={tabItem} />
            </TabList>
          </TabContext>
        </Grid>
        <Grid item md={6}>
          <Grid container justifyContent={"end"} spacing={2}>
            <Grid item md={5}>
              <SearchFieldComponent searchText={handleSearchData} />
            </Grid>
            <Grid item md={2.5}>
              <ButtonComponent btnText={"ADD"} onClick={handleModalOpen} />
            </Grid>
            <Grid item md={2.5}>
              <ButtonComponent
                btnText={"UPLOAD"}
                onClick={handleUploadModalOpen}
              />
            </Grid>
            <Grid item md={2}>
              <DownloadToExcel />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TabContext value={value}>
        <TabPanel value={value}>
          <CurrencyTaxHandling
            fromModule={value}
            open={modalOpen}
            handleClose={handleMOdalClose}
            searchDataa={searchData}
            renderData = {renderingData}
          />
        </TabPanel>
      </TabContext>
      {handleUploadModal()}
    </>
  );
};
