import { jwtDecode } from "jwt-decode";
import { Secrets } from "../config/config";

const CryptoJS = require("crypto-js");

//Handle over all application storage
export const StorageClient = {
  //will get storage details based on key automatically parse the data
  getItem: (key) => {
    try {
      const serializedItem = localStorage.getItem(key);
      return serializedItem ? JSON.parse(serializedItem) : undefined;
    } catch (error) {
      console.error(`Error getting item from Storage: ${error.message}`);
      return undefined;
    }
  },
  //will set storage item with key and value
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting item in Storage: ${error.message}`);
    }
  },
  //will destroy all application storage
  clear: () => {
    try {
      localStorage.clear();
    } catch (error) {
      console.error(`Error clearing Storage: ${error.message}`);
    }
  },
};

//Will fetch Admin details that were encoded in refreshtoken
export const GetAdminDetails = async () => {
  try {
    const token = await StorageClient.getItem("refreshToken");
    const decoded = jwtDecode(token);
    return decoded;
  } catch (error) {
    console.error(`error occured while decoding: ${error}`);
  }
};

//Will handle encryption and decryption of data for whole application where ever needed
export const cryptoClient = {
  encryptData: async (data) => {
    try {
      const encryptedString = CryptoJS.AES.encrypt(
        data,
        Secrets.cryptoKey
      ).toString();
      return encryptedString;
    } catch (error) {
      console.error(`error occured while encrypting: ${error}`);
    }
  },
  decryptData: async (encryptedString) => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(
        encryptedString,
        Secrets.cryptoKey
      );
      const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
      const decryptedObject = JSON.parse(decryptedString);
      return decryptedObject;
    } catch (error) {
      console.error(`error occured while decrypting: ${error}`);
    }
  },
};

export const GetRoleDetails = async () => {
  try {
    const encryptedString = await StorageClient.getItem("accessToken");
    const decoded = jwtDecode(encryptedString);
    return decoded;
  } catch (error) {
    console.error(`error occured while decoding: ${error}`);
  }
};

// export const GetRoleId = async (role) => {
//   try {
//     const rolePrvlg = await StorageClient.getItem("rolePrivileges");
//     if (rolePrvlg) {
//       const prvlgRecord = rolePrvlg.find((item) => item.id === role);
//       return prvlgRecord.role_id;
//     }
//     return null;
//   } catch (error) {
//     console.error(`error occured : ${error}`);
//   }
// }

//this will convert Date string to date and time

export const ConvertDateString = (date) => {
  try {
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const dateString = date;
    const formattedDate = new Date(dateString).toLocaleString("en-IN", options);
    return formattedDate;
  } catch (error) {
    console.log(`Error occurred: ${error}`);
    return "Error"; // You can return a default value or handle errors as needed
  }
};

export const ReOdering = async (orderingData) => {
  try {
    const ReOrderedData = orderingData.map((obj, index) => ({
      step: index + 1,
      onboarding: obj.id,
    }));

    return ReOrderedData;
  } catch (error) {
    console.log(`Error occurred: ${error}`);
  }
};

export const constructStateDetails = async (
  statesList,
  stateIds,
  citiesList,
  cityIds
) => {
  try {
    const list = [];
    for (const state of stateIds) {
      const stateRecord = statesList?.find((item) => item.id === state);
      list.push({
        country_id: stateRecord.country_id,
        state_id: stateRecord.id,
      });
    }

    const stateId = list.map((state) => state.state_id); // Extract state_ids from the list array

    const filteredCitiesList =
      citiesList?.length > 0
        ? citiesList?.filter((city) => stateId?.includes(city.state_id))
        : citiesList;

    const filtCityIds = filteredCitiesList?.map((city) => city.city_id);

    const filteredCityIds = cityIds?.filter((cityId) =>
      filtCityIds.includes(cityId)
    );

    return {
      list,
      filteredCityIds,
      filteredCitiesList,
    };
  } catch (error) {
    console.log(`Error occurred: ${error}`);
  }
};

export const handleCountryChangeList = async (
  countryIds,
  selectedStates,
  statesList
) => {
  try {
    // Filter statesList based on countryIds
    const filteredStatesList = statesList.filter((state) =>
      countryIds.includes(state.country_id)
    );

    // Extract the IDs of the remaining states
    const remainingStateIds = filteredStatesList.map((state) => state.state_id);

    // Filter selectedStates based on remainingStateIds
    const filteredSelectedStates = selectedStates.filter((stateId) =>
      remainingStateIds.includes(stateId)
    );

    return { filteredSelectedStates, filteredStatesList };
  } catch (error) {
    console.log(`Error occurred: ${error}`);
    return { filteredSelectedStates: [], filteredStatesList: [] }; // Return empty arrays or handle the error as needed
  }
};

export const constructCityDetails = async (citiesList, cityIds) => {
  try {
    const list = [];
    for (const city of cityIds) {
      const cityRecord = citiesList?.find((item) => item.id === city);
      list.push({
        country_id: cityRecord.country_id,
        state_id: cityRecord.state_id,
        city_id: cityRecord.id,
      });
    }
    return list;
  } catch (error) {
    console.log(`Error occurred: ${error}`);
  }
};

export const prepareAddressList = async (
  selectedCountries,
  preparedStates,
  preparedCities
) => {
  try {
    const addressList = [];
    selectedCountries.forEach((country) => {
      addressList.push({
        country_id: country,
        state_id: null,
        city_ids: [],
      });
    });

    preparedStates.forEach((state) => {
      const existingObj = addressList.find(obj => obj.country_id === state.country_id && obj.state_id === null);
      if (existingObj) {
        existingObj.state_id = state.state_id;
      } else {
        addressList.push({
          country_id: state.country_id,
          state_id: state.state_id,
          city_ids: [],
        });
      }
    });

    preparedCities.forEach((city) => {
      addressList.forEach(obj => {
        if (obj.state_id === city.state_id) {
          obj.city_ids.push(city.city_id);
        }
      });
    });

    return addressList;
      } catch (error) {
    console.log(`Error occurred: ${error}`);
    return []; // Return empty array or handle the error as needed
      }
  };

export const splitAddress = async (addressData) => {
  try {
    let countryIds = [];
    let stateIds = [];
    let preparedStates = [];
    let cityIds = [];
    let preparedCitiesList = [];

    addressData.forEach((address) => {
      if (!countryIds.includes(address.country_id)) {
        countryIds.push(address.country_id);
      }
      if (address.state_id && !stateIds.includes(address.state_id)) {
        stateIds.push(address.state_id);
        preparedStates.push({
          country_id: address.country_id,
          state_id: address.state_id,
        });
      }
      address.city_ids.forEach((city_id) => {
        if (city_id && !cityIds.includes(city_id)) {
          cityIds.push(city_id);
          preparedCitiesList.push({
            country_id: address.country_id,
            state_id: address.state_id,
            city_id: city_id,
          });
        }
      });
    });

    countryIds.sort((a, b) => a - b);
    stateIds.sort((a, b) => a - b);
    cityIds.sort((a, b) => a - b);

    return { countryIds, stateIds, preparedStates, cityIds, preparedCitiesList };
  } catch (error) {
    console.log(`Error occurred: ${error}`);
    return []; // Return empty array or handle the error as needed
  }
}