import React, { useState } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { ButtonComponent } from "../../components/form_components/button";
import { ManagementServices } from "../../services/management_services";

const PricingPlanDownload = () => {
  const [pricingPlanData, setPricingPlanData] = useState([]);

  const applyHeaderStyles = (ws) => {
    const headerCells = [
      "A1",
      "B1",
      "C1",
      "D1",
      "E1",
      "F1",
      "G1",
      "H1",
      "I1",
      "J1",
      "K1",
      "L1",
      "M1",
      "N1",
      "O1",
      "P1",
      "Q1",
      "R1",
      "S1",
    ];
    const styles = {
      A1: { fill: { bgColor: { rgb: "FFFF00" } } }, // Yellow for Country
      B1: { fill: { bgColor: { rgb: "FFA500" } } }, // Orange for State
      C1: { fill: { bgColor: { rgb: "ADD8E6" } } }, // Light Blue for City
      D1: { fill: { bgColor: { rgb: "90EE90" } } }, // Light Green for Area Code
      E1: { fill: { bgColor: { rgb: "FFB6C1" } } }, // Light Pink for Chef Username
      F1: { fill: { bgColor: { rgb: "FFB6C1" } } }, // Light Pink for Chef ID
      G1: { fill: { bgColor: { rgb: "FFFF00" } } }, // Yellow for Basic Chef Percent
      H1: { fill: { bgColor: { rgb: "FFA500" } } }, // Orange for Basic Corporate Percent
      I1: { fill: { bgColor: { rgb: "ADD8E6" } } }, // Light Blue for Basic Orders
      J1: { fill: { bgColor: { rgb: "90EE90" } } }, // Light Green for Promo1 Chef Percent
      K1: { fill: { bgColor: { rgb: "FFB6C1" } } }, // Light Pink for Promo1 Corporate Percent
      L1: { fill: { bgColor: { rgb: "FFFF00" } } }, // Yellow for Promo1 Days
      M1: { fill: { bgColor: { rgb: "FFA500" } } }, // Orange for Promo1 Orders
      N1: { fill: { bgColor: { rgb: "ADD8E6" } } }, // Light Blue for Promo2 Chef Percent
      O1: { fill: { bgColor: { rgb: "90EE90" } } }, // Light Green for Promo2 Corporate Percent
      P1: { fill: { bgColor: { rgb: "FFB6C1" } } }, // Light Pink for Promo2 Days
      Q1: { fill: { bgColor: { rgb: "FFFF00" } } }, // Yellow for Promo2 Orders
      R1: { fill: { bgColor: { rgb: "FFA500" } } }, // Orange for Reentry Days
      S1: { fill: { bgColor: { rgb: "ADD8E6" } } }, // Light Blue for Reentry Orders
    };

    headerCells.forEach((cell) => {
      if (ws[cell]) {
        ws[cell].s = styles[cell].fill.bgColor.rgb;
      }
    });
  };
  const transformData = (data) => {
    const transformed = [];
    data.forEach((item) => {
      transformed.push({
        Country: item.country_name || "Global",
        State: item.state_name || "N/A",
        City: item.city_name || "N/A",
        "Area Code": item.area_code,
        "Chef Username": item.chef_user_name || "N/A",
        "Chef ID": item.chef_id || "",
        "Basic Chef Percent": item.basic_chef_percent,
        "Basic Corporate Percent": item.basic_corporate_percent,
        "Basic Orders": item.basic_orders,
        "Promo1 Chef Percent": item.promo1_chef_percent,
        "Promo1 Corporate Percent": item.promo1_corporate_percent,
        "Promo1 Days": item.promo1_days,
        "Promo1 Orders": item.promo1_orders,
        "Promo2 Chef Percent": item.promo2_chef_percent,
        "Promo2 Corporate Percent": item.promo2_corporate_percent,
        "Promo2 Days": item.promo2_days,
        "Promo2 Orders": item.promo2_orders,
        "Reentry Days": item.reentry_days,
        "Reentry Orders": item.reentry_orders,
      });
    });
    return transformed;
  };

  const getPricingPlan = async () => {
    const pricingPlan = await ManagementServices.pricingPlansDownload();
    if (pricingPlan?.status === 1) {
      setPricingPlanData(pricingPlan.data);
    }
  };
  React.useEffect(() => {
    getPricingPlan();
  }, []);

  const downloadExcel = () => {
    const transformedData = transformData(pricingPlanData);
    // Create a worksheet from the data
    const ws = XLSX.utils.json_to_sheet(transformedData);

    applyHeaderStyles(ws);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file and save to client
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
      const view = new Uint8Array(buf); //create uint8array as viewer
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
      return buf;
    };
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "pricingplan.xlsx"
    );
  };

  return (
    <div>
      <ButtonComponent btnText={"Download"} onClick={downloadExcel} />
    </div>
  );
};

export default PricingPlanDownload;
