import { Outlet } from "react-router-dom";

const Consumer = () => {

  return (
      <Outlet />
  )
};

export default Consumer;
