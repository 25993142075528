import React from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { modalHeading, viewmodalStyles } from "../styles/common_styles";
import { Rating, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

export const ViewMoreModal = ({
  open,
  handleClose,
  type,
  chefList,
  consumerList,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="sample-modal-title"
      aria-describedby="sample-modal-description"
    >
      <Box sx={viewmodalStyles}>
        <Typography sx={modalHeading}>
          {type === "chef" ? "Chefs list" : "Consumers list"}
        </Typography>
        {type === "chef" ? (
          <Grid m={4}>
            <TableContainer sx="">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell sx="">#</TableCell>
                    <TableCell sx="">Image</TableCell>
                    <TableCell sx="">Name</TableCell>
                    <TableCell sx="">Rating</TableCell>
                    <TableCell sx="">Orders</TableCell>
                    <TableCell sx="">Country</TableCell>
                    <TableCell sx="">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chefList?.map((chef, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <img
                          src={chef.chef_profile}
                          style={{
                            height: "60px",
                            width: "60px",
                            borderRadius: "100px",
                          }}
                          alt=""
                        />
                      </TableCell>
                      <TableCell>{chef.chef_name}</TableCell>
                      <TableCell>
                        <Rating
                          name="half-rating"
                          value={parseFloat(chef.average_rating).toFixed(1)}
                          precision={0.5}
                          readOnly
                        />
                      </TableCell>
                      <TableCell>{chef.order_count}</TableCell>
                      <TableCell>{chef.chef_country}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate("/chef/about", {
                              state: {
                                module: 2,
                                userId: chef.chef_id,
                                type: 11,
                              },
                            })
                          }
                        >
                          <VisibilityIcon sx={{ color: "black !important" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <Grid m={4}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell sx="">#</TableCell>
                    <TableCell sx="">Image</TableCell>
                    <TableCell sx="">Name</TableCell>
                    <TableCell sx="">Orders</TableCell>
                    <TableCell sx="">Country</TableCell>
                    <TableCell sx="">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {consumerList?.map((chef, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <img
                          src={chef.consumer_profile}
                          style={{
                            height: "60px",
                            width: "60px",
                            borderRadius: "100px",
                          }}
                          alt={chef.name}
                        />
                      </TableCell>
                      <TableCell>{chef.consumer_name}</TableCell>
                      <TableCell>{chef.order_count}</TableCell>
                      <TableCell>{chef.consumer_country}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate("/Consumer/about", {
                              state: {
                                module: 1,
                                userId: chef.consumer_id,
                                type: 11,
                              },
                            })
                          }
                        >
                          <VisibilityIcon sx={{ color: "black !important" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};
