// will handle basic defaults like api urls
export const ApiConfig = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  defaultAuth: process.env.REACT_APP_DEFAULT_AUTH,
};

// will handle media base url
export const MediaBaseUrl = {
  pdfUrl: process.env.REACT_APP_PDF_BASE_URL,
  iconsUrl: process.env.REACT_APP_ICONS_URL,
};

// will handle secret key
export const Secrets = {
  cryptoKey: process.env.REACT_APP_SECRET_KEY,
};
