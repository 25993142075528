import CEO from '../assets/dashboardImages/CEO.png';
import Manager from '../assets/dashboardImages/Manager.png';
import SuperAdmin from "../assets/dashboardImages/SuperAdmin.png";
import CSR from "../assets/dashboardImages/CSR.png";
import Supervisor from "../assets/dashboardImages/Supervisor.png";
import chef from "../assets/dashboardImages/chef.png";
import consumer from "../assets/dashboardImages/consumer.png";

export const DashBoardImages = {
  CEO: CEO,
  Manager: Manager,
  SuperAdmin: SuperAdmin,
  CSR: CSR,
  Supervisor: Supervisor,
  chef: chef,
  consumer: consumer
};