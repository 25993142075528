import { Grid } from "@mui/material";
import { titleName } from "../styles/common_styles";

export const TitleComponent = ({title}) =>{
    return(
        <>
            <Grid sx={titleName}>
                {title}
            </Grid>
        </>
    )
}