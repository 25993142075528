import React from 'react';
import Lottie from 'lottie-react';
import loaderblackdata from '../assets/images/loaderblack.js';
import Backdrop from '@mui/material/Backdrop';
export const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Lottie animationData={loaderblackdata} />
    </div>
  );
};

export const BackDropLoader = ({ backDropLoaderopen, BackDropLoaderHandleClose }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={backDropLoaderopen}
      onClick={BackDropLoaderHandleClose}
    >
      <Lottie animationData={loaderblackdata} />
    </Backdrop>
  );
};




