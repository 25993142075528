import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Checkbox,
  FormControl,
  TextField,
  Box,
  Typography,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { ButtonComponent } from "./form_components/button";
import { AuthServices } from "../services/auth_services";
import { CustomField, Emaillable, LoginForm } from "../styles/loginstyles";
import {
  modalStyle,
  modalHeading,
  Errors,
  selectCheckBoxStyle,
} from "../styles/common_styles";
import { CommonServices } from "../services/common_services";
import { useForm } from "react-hook-form";
import { SuccessAlert, ErrorAlert } from "./alert";
import { ManagementServices } from "../services/management_services";
import { BackDropLoader } from "./loader_component";
import {
  splitAddress,
  constructStateDetails,
  handleCountryChangeList,
  constructCityDetails,
  prepareAddressList,
} from "../utils/helpers";
import { FormExpressions } from "../utils/regularExpressions";
import { GetAdminDetails } from "../utils/helpers";
import debounce from "lodash.debounce";

export const EditModalComponent = ({ open, handleClose, edit }) => {
  const [userDetails, setUserDetails] = useState({
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    role: [],
    countryId: "",
    stateId: "",
    cityId: "",
  });

  const [selectedRoles, setSelectedRoles] = useState(0);
  const [roles, setRoles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showCountry, setShowCountry] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [backDropLoaderOpen, setBackDropLoaderOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [preparedStates, setPreparedStates] = useState([]);
  const [preparedCities, setPreparedCities] = useState([]);
  const [mode, setMode] = useState(false);

  // const handleCountryChange = (event) => {
  //   setSelectedCountries(event.target.value);
  //   GetStates(2, event.target.value);
  // };

  const handleCountryChange = useCallback(
    debounce((event) => {
      const selectedValue = event.target.value;
      setSelectedCountries(selectedValue);
      GetStates(2, selectedValue); // Example of a side effect inside the callback
    }, 300), // Adjust the debounce delay as needed
    []
  );

  const handleToggleBackDropLoader = () => {
    setBackDropLoaderOpen(!backDropLoaderOpen);
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleFirstNameChange = (event) => {
    setUserDetails({ ...userDetails, firstName: event.target.value });
  };

  const handleLastNameChange = (event) => {
    setUserDetails({ ...userDetails, lastName: event.target.value });
  };

  const handleEmailChange = (event) => {
    setUserDetails({ ...userDetails, email: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setUserDetails({ ...userDetails, phone: event.target.value });
  };

  const handleAddressChange = (event) => {
    setUserDetails({ ...userDetails, address: event.target.value });
  };

  const handleChangeRole = (event) => {
    const { value } = event.target;
    setSelectedRoles(value);
    const hasCountryRole = [3, 4, 5, 6].includes(value);
    const hasStateRole = [4, 5, 6].includes(value);
    const hasCityRole = [4, 5, 6].includes(value);
    setShowCountry(hasCountryRole);
    setShowState(hasStateRole);
    setShowCity(hasCityRole);
    setSelectedCountries([]);
    setSelectedStates([]);
    setSelectedCities([]);
    setPreparedStates([]);
    setPreparedCities([]);
  };

  const handleAutocompleteStateChange = (event) => {
    const { value } = event.target;
    setSelectedStates(value);
    GetCities(3, value, selectedCountries);
  };

  const handleAutocompleteCityChange = (event) => {
    const { value } = event.target;
    setSelectedCities(value);
  };

  /**
   * A function that fetches admin roles and sets them if the status is 1, otherwise alerts the message.
   *
   */
  const AdminRoles = async () => {
    try {
      const Roles = await AuthServices.getRoles();
      if (Roles.status === 1) {
        setRoles(Roles.data);
      } else {
        setAlrtMsg(Roles.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  /**
   * Function to asynchronously retrieve countries.
   *
   * @return {Promise} Promise that resolves after retrieving countries
   */
  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);
      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        setAlrtMsg(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  /**
   * GetStates function to retrieve states based on type and country.
   *
   * @param {type} type - the type of states to retrieve
   * @param {country} country - the country for which states are to be retrieved
   * @return {Promise} returns a Promise
   */
  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        setAlrtMsg(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  /**
   * GetCities function to retrieve cities based on type, state, and country.
   *
   * @param {type} type - type of cities to retrieve
   * @param {type} state - state for which cities are to be retrieved
   * @param {type} country - country for which cities are to be retrieved
   * @return {type} undefined
   */
  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        setAlrtMsg(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  /**
   * A function to edit user details. It prepares the user's address(es) for the API call,
   * gets the user's admin details from the backend, prepares the payload to send to the API,
   * makes the API call to update the user's details, and handles the success and error scenarios.
   *
   * @return {Promise} a promise that resolves when the user's details are successfully updated
   */
  const EditUserDetails = async () => {
    handleClose();
    setBackDropLoaderOpen(true);

    try {
      // Prepare the user's address(es) for the API call
      const superAdminAddress = await prepareAddressList(
        selectedCountries,
        preparedStates,
        preparedCities
      );

      // Get the user's admin details from the backend
      const AdminDetails = await GetAdminDetails();
      // Prepare the payload to send to the API
      const payload = {
        userId: userDetails.id,
        adminId: AdminDetails.id,
        roleId: selectedRoles,
        updated_location_list: superAdminAddress,
      };

      // Make the API call to update the user's details
      const response = await ManagementServices.editAdmin(payload);

      // If the API call was successful, display a success message
      if (response.status === 1) {
        setBackDropLoaderOpen(false);
        handleClose();
        setAlrtMsg(response.message);
        setSuccessAlrtOpen(true);
      }

      // If the API call failed, display an error message
      else {
        setBackDropLoaderOpen(false);
        setAlrtMsg(response.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      // If there was an error, log it to the console
      console.error(`Error occurred while updating user details: ${error}`);
    }
  };

  /**
   * Retrieve user details from the backend and store them in the component state.
   *
   * @param {}
   * @return {}
   */
  const GetUserDetails = async () => {
    try {
      if (edit !== "") {
        let UserDetailsResponse = await CommonServices.getUserList(edit);

        // Get the user details from the backend
        if (UserDetailsResponse.status === 1) {
          // Determine if the user has country, state or city roles
          const hasCountryRole = [3, 4, 5, 6].includes(
            UserDetailsResponse.data[0].role_id
          );

          const hasStateRole = [4, 5, 6].includes(
            UserDetailsResponse.data[0].role_id
          );

          const hasCityRole = [5, 6, 4].includes(
            UserDetailsResponse.data[0].role_id
          );

          // Store the user's role in the component state
          setSelectedRoles(UserDetailsResponse.data[0].role_id);

          // Toggle component state based on the user's role
          setShowCountry(hasCountryRole);
          setShowState(hasStateRole);
          setShowCity(hasCityRole);

          // Destructure the user's details from the response
          const details = UserDetailsResponse.data[0];

          // Store the user's details in the component state
          setUserDetails({
            ...userDetails,
            id: details.id,
            firstName: details.first_name,
            lastName: details.last_name,
            phone: details.phone,
            email: details.email,
            address: details.address,
            role: details.roles,
            countryId: details.country_id,
            stateId: details.state_id,
            cityId: details.city_id,
          });

          // Split the user's address(es) into separate arrays
          const {
            countryIds,
            stateIds,
            preparedStates,
            cityIds,
            preparedCitiesList,
          } = await splitAddress(details.addresses);

          // Store the selected country, state and city ids in component state
          setSelectedCountries(countryIds);
          setSelectedStates(stateIds);
          setSelectedCities(cityIds);

          // Store the prepared states and cities in component state
          setPreparedStates(preparedStates);
          setPreparedCities(preparedCitiesList);
        } else {
          // If the API call fails, show the error message
          setAlrtMsg(UserDetailsResponse.message);
          setErrorAlrtOpen(true);
        }
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  useEffect(() => {
    AdminRoles();
    GetCountries();
  }, []);

  useEffect(() => {
    if (open === true) {
      GetUserDetails();
    }
  }, [open]);

  useEffect(() => {
    if (open) GetCities(3, selectedStates, selectedCountries);
    consStateObj();
  }, [selectedStates]);

  useEffect(() => {
    consCityObj();
  }, [selectedCities]);

  useEffect(() => {
    if (open) GetStates(2, selectedCountries);

    if (selectedCountries?.length > 0) {
      consCountryObj();
    }
  }, [selectedCountries]);

  const consStateObj = async () => {
    const data = await constructStateDetails(
      states,
      selectedStates,
      preparedCities,
      selectedCities
    );
    if (data) {
      setPreparedStates(data.list);
      setPreparedCities(data.filteredCitiesList);
      setSelectedCities(data.filteredCityIds);
    }
  };

  const consCountryObj = async () => {
    const { filteredSelectedStates, filteredStatesList } =
      await handleCountryChangeList(
        selectedCountries,
        selectedStates,
        preparedStates
      );
    setPreparedStates(filteredStatesList);
    setSelectedStates(filteredSelectedStates);
  };

  const [cityDataAlreadyProcessed, setCityDataAlreadyProcessed] =
    useState(false);

  // const consCityObj = async () => {
  //   if (!cityDataAlreadyProcessed) {
  //     setCityDataAlreadyProcessed(true);
  //     const data = await constructCityDetails(cities, selectedCities);
  //     if (mode) {
  //       setPreparedCities(data);
  //     } else {
  //       setMode(true);
  //     }
  //   }
  // };
  const consCityObj = async () => {
    if (!cityDataAlreadyProcessed) {
      const data = await constructCityDetails(cities, selectedCities);
      // setCityDataAlreadyProcessed(true);
      if (mode) {
        setPreparedCities(data);
      } else {
        setMode(true);
      }
    }
  };

  return (
    <>
      <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
      <Modal
        open={open}
        onClose={() => handleClose(setSelectedRoles([]))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography sx={modalHeading}>Edit user</Typography>
          <Grid
            component={"form"}
            onSubmit={handleSubmit(async () => await EditUserDetails())}
          >
            <Grid container spacing={2} pl={4} pr={4}>
              <Grid item md={6}>
                <FormControl fullWidth sx={LoginForm}>
                  <Grid sx={Emaillable}>First Name</Grid>
                  <TextField
                    sx={CustomField}
                    disabled={true}
                    value={userDetails.firstName}
                    fullWidth
                    size={"small"}
                    type={"text"}
                    inputProps={{
                      placeholder: "Enter first name",
                    }}
                    {...register("firstName", {
                      required: {
                        value: !userDetails.firstName,
                        message: "Please enter first name",
                      },
                    })}
                    onChange={handleFirstNameChange}
                  />
                  {errors.firstName && (
                    <Typography sx={Errors}>
                      {errors.firstName.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth sx={LoginForm}>
                  <Grid sx={Emaillable}>Last Name</Grid>
                  <TextField
                    sx={CustomField}
                    disabled={true}
                    fullWidth
                    value={userDetails.lastName}
                    size={"small"}
                    type={"text"}
                    inputProps={{
                      placeholder: "Enter last name",
                    }}
                    {...register("lastName", {
                      required: {
                        value: !userDetails.lastName,
                        message: "Please enter lastName ",
                      },
                    })}
                    onChange={handleLastNameChange}
                  />
                  {errors.lastName && (
                    <Typography sx={Errors}>
                      {errors.lastName.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth sx={LoginForm}>
                  <Grid sx={Emaillable}>Phone Number</Grid>
                  <TextField
                    sx={CustomField}
                    disabled={true}
                    fullWidth
                    value={userDetails.phone}
                    size={"small"}
                    type={"number"}
                    inputProps={{
                      placeholder: "Enter phone number",
                    }}
                    {...register("phone", {
                      required: {
                        value: !userDetails.phone,
                        pattern: /^\d{10}$/,
                      },
                    })}
                    onChange={handlePhoneChange}
                  />
                  {errors.phone && errors.phone.type === "required" && (
                    <Typography sx={Errors}>Please enter your phone</Typography>
                  )}
                  {errors.phone && errors.phone.type === "pattern" && (
                    <Typography sx={Errors}>
                      please enter valid phone
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth sx={LoginForm}>
                  <Grid sx={Emaillable}>Email</Grid>
                  <TextField
                    sx={CustomField}
                    disabled={true}
                    fullWidth
                    value={userDetails.email}
                    size={"small"}
                    type={"email"}
                    inputProps={{
                      placeholder: "Enter email",
                    }}
                    {...register("email", {
                      required: {
                        value: !userDetails.email,
                        pattern: FormExpressions.email,
                      },
                    })}
                    onChange={handleEmailChange}
                    helperText={errors?.email?.message}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <Typography sx={Errors}>Please enter your email</Typography>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <Typography sx={Errors}>
                      please enter valid email
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth sx={LoginForm}>
                  <Grid sx={Emaillable}>Address</Grid>
                  <TextField
                    sx={CustomField}
                    disabled={true}
                    fullWidth
                    value={userDetails.address}
                    size={"small"}
                    type={"text"}
                    inputProps={{
                      placeholder: "Enter address",
                    }}
                    {...register("address", {
                      required: {
                        value: !userDetails.address,
                        message: "Please enter address ",
                      },
                    })}
                    onChange={handleAddressChange}
                  />
                  {errors.address && (
                    <Typography sx={Errors}>
                      {errors.address.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Role</Grid>
                  <Select
                    displayEmpty
                    size={"small"}
                    sx={CustomField}
                    value={selectedRoles}
                    {...register("role", {
                      required: "Please select a role",
                    })}
                    onChange={handleChangeRole}
                    renderValue={(selected) =>
                      selected ? (
                        <Grid sx={selectCheckBoxStyle}>
                          {roles
                            .filter((role) => role.id === selected)
                            .map((role) => (
                              <span key={role.id}>{role.role_name}</span>
                            ))}
                        </Grid>
                      ) : (
                        "Select Role"
                      )
                    }
                  >
                    <MenuItem disabled value="">
                      {selectedRoles && selectedRoles.length === 0
                        ? "Select Role"
                        : ""}
                    </MenuItem>
                    {roles
                      .filter((option) => option.role_name !== "UserAdmin")
                      .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.role_name}
                        </MenuItem>
                      ))}
                  </Select>
                  {selectedRoles.length === 0 && errors.role && (
                    <Typography sx={Errors}>{errors.role.message}</Typography>
                  )}
                </FormControl>
              </Grid>
              {showCountry && (
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <Grid sx={Emaillable}>Country</Grid>
                    <Select
                      displayEmpty
                      size={"small"}
                      sx={CustomField}
                      multiple // Enable multiple selection
                      value={selectedCountries}
                      onChange={handleCountryChange}
                      renderValue={(selected) =>
                        selected.length === 0 ? (
                          "Select Country"
                        ) : (
                          <Grid sx={selectCheckBoxStyle}>
                            {Array.isArray(selected) &&
                              selected.map((countryId, index) => {
                                const country = countries.find(
                                  (country) => country.id === countryId
                                );
                                return (
                                  <span key={country?.id}>
                                    {index > 0 && ", "}
                                    {country?.name}
                                  </span>
                                );
                              })}
                          </Grid>
                        )
                      }
                    >
                      <MenuItem disabled value="">
                        {selectedCountries.length === 0 ? "Select Country" : ""}
                      </MenuItem>
                      {countries.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Checkbox
                            checked={selectedCountries.includes(option.id)}
                          />
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {selectedCountries.length === 0 && errors.country && (
                      <Typography sx={Errors}>
                        Please select at least one country.
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              )}
              {showState && (
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <Grid sx={Emaillable}>State</Grid>
                    <Select
                      displayEmpty
                      size={"small"}
                      sx={CustomField}
                      multiple // Enable multiple selection
                      value={selectedStates}
                      onChange={handleAutocompleteStateChange}
                      renderValue={(selected) =>
                        selected.length === 0 ? (
                          "Select State"
                        ) : (
                          <Grid sx={selectCheckBoxStyle}>
                            {Array.isArray(selected) &&
                              selected.map((stateId, index) => {
                                const state = states?.find(
                                  (stateRecord) => stateRecord.id === stateId
                                );
                                return (
                                  <span key={state?.id}>
                                    {index > 0 && ", "}
                                    {state?.name}
                                  </span>
                                );
                              })}
                          </Grid>
                        )
                      }
                    >
                      <MenuItem disabled value="">
                        {selectedStates.length === 0 ? "Select State" : ""}
                      </MenuItem>
                      {states.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Checkbox
                            checked={
                              Array.isArray(selectedStates) &&
                              selectedStates.includes(option.id)
                            }
                          />
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {selectedStates &&
                      selectedStates.length === 0 &&
                      errors.state && (
                        <Typography sx={Errors}>
                          Please select at least one state.
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
              )}
              {showCity && (
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <Grid sx={Emaillable}>City</Grid>
                    <Select
                      displayEmpty
                      size={"small"}
                      sx={CustomField}
                      multiple // Enable multiple selection
                      value={selectedCities}
                      onChange={handleAutocompleteCityChange}
                      renderValue={(selected) =>
                        selected.length === 0 ? (
                          "Select City"
                        ) : (
                          <Grid sx={selectCheckBoxStyle}>
                            {Array.isArray(selected) &&
                              selected.map((cityId, index) => {
                                const city = cities.find(
                                  (city) => city.id === cityId
                                );
                                return (
                                  <span key={city?.id}>
                                    {index > 0 && ", "}
                                    {city?.name}
                                  </span>
                                );
                              })}
                          </Grid>
                        )
                      }
                    >
                      <MenuItem disabled value="">
                        {selectedCities.length === 0 ? "Select City" : ""}
                      </MenuItem>
                      {cities.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Checkbox
                            checked={
                              Array.isArray(selectedCities) &&
                              selectedCities.includes(option.id)
                            }
                          />
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {selectedCities &&
                      selectedCities.length === 0 &&
                      errors.cities && (
                        <Typography sx={Errors}>
                          Please select at least one city.
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
              )}
              <Grid item md={12}>
                <Grid container justifyContent={"center"} p={3}>
                  <ButtonComponent
                    type={"submit"}
                    btnText={"SAVE"}
                    onClick={EditUserDetails}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};
