import React from "react";
import {
  Grid,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import {
  moduleHeadContainer,
  tableParentHolder,
} from "../../styles/common_styles";
import { TitleComponent } from "../../components/title_component";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import { CommonServices } from "../../services/common_services";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OrderModalComponent from "../../components/order_modal_component";
import { TableComponent } from "../../components/table_component";
import { EmaillableTwo } from "../../styles/loginstyles";
import { tableHeadCell } from "../../styles/common_styles";
import { HFPagination } from "../../components/pagination";
import { TableCellComponent } from "../../components/table_cell_component";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import { Loader } from "../../components/loader_component";
import "../../styles/common_styles.css";
const Orders = () => {
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [selectedState, setSelectedState] = React.useState(0);
  const [selectedCity, setSelectedCity] = React.useState(0);
  const [orderStatus, setOrderStatus] = React.useState(null);
  const [searchData, setSearchData] = React.useState("");
  const [orderData, setOrderData] = React.useState([]);
  const [orderId, setOrderId] = React.useState("");
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [loaderData, setLoaderData] = React.useState(true);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };
  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const handleOpenModal = (id) => {
    setIsModalOpen(true);
    setOrderId(id);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };
  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, value);
    setSelectedState(0);
    setSelectedCity(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setSelectedCity(0);
    GetCities(3, event.target.value, selectedCountry);
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  const getTotalOrders = async () => {
    try {
      const payload = {
        min: pageConfig.min,
        max: pageConfig.max,
        status: orderStatus,
        search: searchData,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
        fromdate: startDate,
        todate: endDate,
      };
      const Orders = await ManagementServices.getTotalOrders(payload);
      if (Orders.status === 1) {
        setOrderData(Orders.data.orderDetails);
        setPaginationData({
          ...paginationData,
          currentPage: Orders.data.currentPage,
          RecordsForPage: Orders.data.pageSize,
          totalRecords: Orders.data.totalOrders,
          pageCount: Orders.data.totalPages,
        });
        setLoaderData(false);
        // setAlrtMsg(Orders.message);
        // setSuccessAlrtOpen(true);
      } else {
        setErrorAlrtOpen(Orders.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const getStatusName = (statusNumber) => {
    const colors = [
      "#FF5733",
      "#3357FF",
      "#3357FF",
      "#FF33A8",
      "#A833FF",
      "#33A8FF",
      "#FF3333",
      "#33A8FF",
      "#A8FF33",
    ];

    const statusMap = {
      1: { name: "Pending", color: colors[0] },
      // 1: { name: "Accepted", color: colors[1] },
      // 2: { name: "Rejected", color: colors[2] },
      3: { name: "Started", color: colors[3] },
      4: { name: "Prepared", color: colors[4] },
      5: { name: "Ready to deliver/pickup", color: colors[5] },
      6: { name: "Delivered", color: "green" },
      7: { name: "Cancelled", color: colors[7] },
      8: { name: "Missed(AutoCancel)", color: colors[8] },
      9: { name: "Quote Accepted", color: "green" },
    };

    return statusMap[statusNumber] || { name: "Unknown", color: "#CCCCCC" };
  };

  let tableHeaders = [
    "S no",
    "Order Id",
    "Chef Name",
    "Consumer Name",
    "Order Date",
    "Order Status",
    "Order Type",
    "Total Spent",
    "Actions",
  ];

  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  function formatCreatedAtToGMT(created_at) {
    const date = new Date(created_at);

    // Format the date to GMT string
    const gmtDateString = new Intl.DateTimeFormat("en-US", {
      timeZone: "GMT",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(date);

    // Extract the date part and time part
    const [datePart, timePart] = gmtDateString.split(", ");

    return `${datePart}`;
  }

  const TableBody = orderData?.map((data, index) => {
    return (
      <TableRow key={data.id}>
        <TableCellComponent
          value={
            (paginationData.currentPage - 1) * paginationData.RecordsForPage +
            1 +
            index
          }
          indexColoumn={true}
        />
        <TableCellComponent value={data.order_id || "-"} />
        <TableCellComponent value={data.chef_name} />
        <TableCellComponent value={data.consumer_name} />
        <TableCellComponent
          value={
            (data.payment_date !== null &&
              formatCreatedAtToGMT(data.payment_date)) ||
            "N/A"
          }
        />
        <TableCell
          sx={{
            color: getStatusName(data.order_status).color + " !important",
            textAlign: "center",
          }}
        >
          {getStatusName(data.order_status).name}
        </TableCell>
        <TableCellComponent
          value={data.order_type === 1 ? "Instant Order" : "Food Request"}
        />
        <TableCellComponent
          value={`${data.currency}${data.total.toFixed(2)}`}
        />
        <TableCell>
          <IconButton onClick={() => handleOpenModal(data.id)}>
            <VisibilityIcon sx={{ color: "black" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  const handlestatusChange = (event) => {
    setOrderStatus(event.target.value);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  React.useEffect(() => {
    GetCountries();
    getTotalOrders();
  }, [
    selectedCountry,
    selectedState,
    selectedCity,
    pageConfig.min,
    pageConfig.max,
    searchData,
    orderStatus,
  ]);

  React.useEffect(() => {
    getTotalOrders();
  }, [orderStatus, startDate, endDate]);

  return (
    <>
      <Grid container alignItems={"center"} sx={moduleHeadContainer}>
        <Grid item md={3}>
          <TitleComponent title={"Orders"} />
        </Grid>
        <Grid item md={9}>
          <Grid container alignItems={"center"} justifyContent={"end"} gap={1}>
            <Grid item md={1.9}>
              <TextField
                type="date"
                size="small"
                InputProps={{
                  placeholder: "startDate",
                }}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item md={1.9}>
              <TextField
                type="date"
                size="small"
                InputProps={{
                  placeholder: "startDate",
                }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setPageConfig({ ...pageConfig, min: 1, max: 10 });
                  // getTotalOrders();
                }}
              ></TextField>
            </Grid>
            <Grid item md={2}>
              <SearchFieldComponent searchText={handleSearchData} />
            </Grid>
            <Grid md={1.3}>
              <FormControl fullWidth>
                <Select
                  value={selectedCountry !== null ? selectedCountry : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "Country" }}
                  onChange={handleChangeCountry}
                >
                  <MenuItem value={0}>Select country</MenuItem>
                  {totalCountries}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1.3}>
              <FormControl fullWidth>
                <Select
                  value={selectedState !== null ? selectedState : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "State" }}
                  onChange={handleChangeState}
                >
                  <MenuItem value={0} disabled>
                    Select State
                  </MenuItem>
                  {totalStates}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1.5}>
              <FormControl fullWidth>
                <Select
                  value={selectedCity !== null ? selectedCity : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "City" }}
                  onChange={handleChangeCity}
                >
                  <MenuItem value={0} disabled>
                    Select city
                  </MenuItem>
                  {totalCities}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1.5}>
              <FormControl fullWidth>
                <Grid sx={EmaillableTwo}></Grid>
                <Select
                  value={orderStatus}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "Order status" }}
                  onChange={handlestatusChange}
                >
                  <MenuItem value={null} disabled>
                    Order status
                  </MenuItem>
                  <MenuItem value={1}>Pending</MenuItem>
                  {/* <MenuItem value={1}>Accepted</MenuItem> */}
                  {/* <MenuItem value={2}>Reject</MenuItem> */}
                  <MenuItem value={3}>Start</MenuItem>
                  <MenuItem value={4}>Prepared</MenuItem>
                  <MenuItem value={5}>Ready to deliver/pickup</MenuItem>
                  <MenuItem value={6}>Delivered</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loaderData ? (
        <Loader />
      ) : (
        <Grid sx={tableParentHolder}>
          <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
          <HFPagination details={paginationData} paginate={handlePaginate} />
        </Grid>
      )}
      <OrderModalComponent
        openhandler={isModalOpen}
        handleClose={handleCloseModal}
        orderId={orderId}
      />
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};

export default Orders;
