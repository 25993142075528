import axios from "axios";
import { ApiConfig } from "../config/config";
import { attachTokenInterceptor, refreshTokenInterceptor } from "./interceptor";

const AxiosClient = axios.create({
  baseURL: ApiConfig.baseUrl,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
// Used to add refresh token to the request if want or it will add default token
AxiosClient.interceptors.request.use(attachTokenInterceptor, (error) =>
  Promise.reject(error)
);

// Response interceptor
// Used to handle refresh token if api got failed with 401 it will call refresh token api and add new token
AxiosClient.interceptors.response.use(
  (response) => response.data,
  refreshTokenInterceptor
);

export default AxiosClient;
