import * as React from "react";
import { Grid, Typography, TableCell, TableRow } from "@mui/material";
import { TitleComponent } from "../../components/title_component";
import { ButtonComponent } from "../../components/form_components/button";
import PricingTable from "../../components/pricing_table_component";
import { makeStyles } from "@mui/styles";
import {
  PricetableHeaderCell,
  priceHeading,
  PricetableHeaderCellStyle,
  formControlAdjust,
} from "../../styles/common_styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { CommonServices } from "../../services/common_services";
import { ManagementServices } from "../../services/management_services";
import { Loader } from "../../components/loader_component";
import { TableRowComponent } from "../common_pages/pricingSelectTableComponent";
import PricingPlanDownload from "./pricingPlanDownload";
import { GetRoleDetails } from "../../utils/helpers";

const Pricing = () => {
  const [checkValue, setCheckValue] = React.useState(true);
  const [countries, setCountries] = React.useState([]);
  const [pricingData, setPricingData] = React.useState({});
  const [pricingSendData, setPricingSendData] = React.useState(pricingData);
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(0);
  const [cities, setCities] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState(0);
  const [selectAreaCode, setSelectAreaCode] = React.useState(0);
  // const [selectChef] = React.useState(0);
  const [areas, setAreas] = React.useState([]);
  const [chefs, setChefs] = React.useState([]);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [accordionVisibility, setAccordionVisibility] = React.useState({});
  const [accordionFetched, setAccordionFetched] = React.useState({});
  const [loaderData, setLoaderData] = React.useState(true);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [pricingTable, setPricingTable] = React.useState([]);
  const [tablePricingPlan, setTablePricingplan] = React.useState({});
  const [selectedObject, setSelectedObject] = React.useState({});
  const [roleDetails, setRoleDetails] = React.useState({
    role_name: "",
    role_id: "",
  });

  const defaultCountryText = 0;
  const defaultStateText = 0;
  const defaultCityText = 0;
  const defaultAreaText = 0;
  const selectChefText = 0;

  const headData = ["Chef", "Corporate", "orders"];

  const headerBasicMapping = {
    Chef: "basic_chef_percent",
    Corporate: "basic_corporate_percent",
    orders: "basic_orders",
  };

  const headDataTwo = ["Chef", "Corporate", "Days", "orders"];

  const updatePricingData = (updatedData) => {
    // Update the pricing data in the parent component state
    setPricingSendData(updatedData);
  };

  const AdminDetails = async () => {
    const GetRoleDetailss = await GetRoleDetails();
    setRoleDetails({
      ...roleDetails,
      role_name: GetRoleDetailss.role_name,
      role_id: GetRoleDetailss.role_id,
    });
  };

  const tableData = [
    {
      Chef: pricingData?.basic_chef_percent || 0,
      Corporate: pricingData.basic_corporate_percent || 0,
      orders: pricingData?.basic_orders || 0,
    },
  ];

  const prom1PricingData = [
    {
      Chef: pricingData?.promo1_chef_percent || 0,
      Corporate: pricingData?.promo1_corporate_percent || 0,
      Days: pricingData?.promo1_days || 0,
      orders: pricingData?.promo1_orders || 0,
    },
  ];

  const headerPromo1Mapping = {
    Chef: "promo1_chef_percent",
    Corporate: "promo1_corporate_percent",
    Days: "promo1_days",
    orders: "promo1_orders",
  };

  const prom2PricingData = [
    {
      Chef: pricingData?.promo2_chef_percent || 0,
      Corporate: pricingData?.promo2_corporate_percent || 0,
      Days: pricingData?.promo2_days || 0,
      orders: pricingData?.promo2_orders || 0,
    },
  ];

  const headerPromo2Mapping = {
    Chef: "promo2_chef_percent",
    Corporate: "promo2_corporate_percent",
    Days: "promo2_days",
    orders: "promo2_orders",
  };

  const reEntryData = [
    {
      Chef: pricingData?.basic_chef_percent || 0,
      Corporate: pricingData?.basic_corporate_percent || 0,
      Days: pricingData?.reentry_days || 0,
      orders: pricingData?.reentry_orders || 0,
    },
  ];

  const headerreEnterMapping = {
    Chef: "reentry_Chef",
    Corporate: "reentry_Corporate",
    Days: "reentry_days",
    orders: "reentry_orders",
  };

  const useStyles = makeStyles((theme) => ({
    priceTable: {
      "& .MuiSelect-select": {
        color: "white !important", // Set text color to white for Select component
      },
      "& .MuiList-root .MuiMenuItem-root": {
        color: "white !important", // Set text color to white for all menu items
      },
      "& .MuiSelect-select:focus": {
        color: "white !important",
        outline: "none", // Set text color to white for focused Select component
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
      "& .MuiSelect-icon": {
        color: "white !important" /* Change arrow color to white */,
      },
      "& .MuiSelect-select:active": {
        color: "white !important",
      },
      "& .MuiSelect-select option": {
        color: "white !important",
      },
      "& .MuiMenuItem-root[data-value='']": {
        color: "white !important",
      },
    },
  }));

  const classes = useStyles();

  const getPricingPlans = async () => {
    const payload = {
      type: 1,
    };
    const pricingPlans = await ManagementServices.GetPricingPlans(payload);
    if (pricingPlans?.status === 1) {
      setPricingData(pricingPlans.data);
      setPricingSendData(pricingPlans.data);
      setLoaderData(false);
    } else {
      setAlrtMsg(pricingPlans.message);
      setErrorAlrtOpen(true);
    }
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      // const Countries = await ManagementServices.locationsData(payload);
      const Countries = await CommonServices.getLocation(payload);
      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        setAlrtMsg(Countries.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      setAlrtMsg(error);
      setErrorAlrtOpen(true);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: 0,
      };
      // const States = await ManagementServices.locationsData(payload);
      const States = await CommonServices.getLocation(payload);
      if (States.status === 1) {
        setStates(States.data);
      } else {
        setSelectedState(0);
        setStates([]);
        setAlrtMsg(States.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      setAlrtMsg(error);
      setErrorAlrtOpen(true);
      // console.error(`Error occured while fetching states:${error}`);
    }
  };

  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        setSelectedCity(0);
        setCities([]);
        setAlrtMsg(Cities.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      setAlrtMsg(error);
      setErrorAlrtOpen(true);
      // console.error(`Error occured while fetching cities:${error}`);
    }
  };

  const GetAreas = async (type, city, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
        city: city,
      };

      const areas = await ManagementServices.locationsData(payload);
      if (areas.status === 1) {
        setAreas(areas.data);
      } else {
        setSelectAreaCode(0);
        setAreas([]);
        setAlrtMsg(areas.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      setAlrtMsg(error);
      setErrorAlrtOpen(true);
      // console.error(`Error occured while fetching cities:${error}`);
    }
  };

  const GetChefs = async (type, area_code, city, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
        city: city,
        area: area_code,
      };

      const chefs = await ManagementServices.locationsData(payload);
      if (chefs.status === 1) {
        setChefs(chefs.data);
      } else {
        setChefs([]);
        setAlrtMsg(chefs.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      setAlrtMsg(error);
      setErrorAlrtOpen(true);
      // console.error(`Error occured while fetching cities:${error}`);
    }
  };

  //country handle change
  const handleChangeCountry = (event) => {
    const { value } = event.target;
    const countryName =
      totalCountries.find((country) => country.props.value === value)?.props
        .children || "";
    // // Check if the country already exists in the pricing table
    const isCountryExist = pricingTable.some((country) => country.id === value);
    // // If the country does not exist, add it to the pricing table
    if (!isCountryExist) {
      const selectedCountry = { id: value, country: countryName, state: [] };
      setPricingTable((prevTable) => [...prevTable, selectedCountry]);
    }
    setAccordionVisibility((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      return newState;
    });
    // Check if the selected country already exists in the pricing table
    setSelectedCountry(value);
    GetStates(2, value);
  };

  //state handle change
  const handleChangeState = (event) => {
    const { value } = event.target;
    const selectedStateObject = states.find(
      (state) => state.id === parseInt(value)
    );
    setSelectedObject(selectedStateObject);
    // Check if the state already exists in the country's state array
    const isStateExist = pricingTable.some(
      (country) => country.stateId === selectedStateObject?.id
    );
    if (!isStateExist) {
      const newCountry = {
        id: selectedStateObject.country_id,
        country: selectedStateObject.country_name,
        stateId: selectedStateObject?.id,
        stateName: selectedStateObject.name,
      };
      setPricingTable((prevTable) => [...prevTable, newCountry]);
    }
    setAccordionVisibility((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      return newState;
    });
    setSelectedState(value);
    GetCities(3, value, selectedCountry);
  };

  //city handle change
  const handleChangeCity = (event) => {
    const { value } = event.target;
    const cityObject = cities.find((city) => city.id === parseInt(value));

    console.log(cityObject, "cityObject");

    // Check if the city already exists in the pricingTable
    const isCityExist = pricingTable.some(
      (country) => country.cityID === cityObject.id
    );
    // console.log(...selectedObject, "selectedObject");
    const city = {
      ...selectedObject,
      cityID: cityObject.id,
      cityName: cityObject.name,
    };
    setAccordionVisibility((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      return newState;
    });
    setSelectedObject(city);

    if (!isCityExist) {
      // Create a new city object containing all necessary details
      const newCity = {
        cityID: cityObject.id,
        cityName: cityObject.name,
        stateId: cityObject.state_id,
        id: selectedCountry,
        country: selectedObject?.country_name,
        stateName: selectedObject?.name,
      };

      setPricingTable((prevTable) => [...prevTable, newCity]);
    }

    setSelectedCity(value);
    GetAreas(3, value, selectedState, selectedCountry);
  };

  //area handle change
  const handleChangeAreaCode = (event) => {
    const { value } = event.target;
    const areaObject = areas.find((area) => area.area_code === value);

    // Check if the area already exists in the pricingTable
    const isAreaExist = pricingTable.some(
      (country) => country.areaCode === areaObject.area_code
    );

    const area = {
      ...selectedObject,
      areaCode: areaObject.area_code,
    };

    setSelectedObject(area);

    if (!isAreaExist) {
      // Create a new area object containing all necessary details
      const newArea = {
        areaCode: areaObject.area_code,
        cityID: areaObject.city_id,
        cityName: selectedObject?.cityName,
        stateId: selectedObject?.state_id,
        id: selectedCountry,
        country: selectedObject?.country_name,
        stateName: selectedObject?.state_name,
      };
      setAccordionVisibility((prevState) => {
        const newState = { ...prevState };
        Object.keys(newState).forEach((key) => {
          newState[key] = false;
        });
        return newState;
      });
      setPricingTable((prevTable) => [...prevTable, newArea]);
    }

    setSelectAreaCode(value);
    GetChefs(4, value, selectedCity, selectedState, selectedCountry);
  };

  //chef handle change
  const handleChangeChefCode = (event) => {
    const { value } = event.target;
    const chef = chefs.find((chef) => chef.id === value);
    // Check if the chef already exists in the pricingTable
    const isChefExist = pricingTable.some(
      (country) => country.chefId === chef.id
    );

    const chefObject = {
      ...selectedObject,
      chefId: chef.id,
    };
    setSelectedObject(chefObject);
    if (!isChefExist) {
      // Create a new chef object containing all necessary details
      const newChef = {
        chefId: chef.id,
        ChefName: chef.user_name,
        areaCode: selectedObject?.areaCode,
        cityID: selectedObject?.cityID,
        cityName: selectedObject?.cityName,
        stateId: selectedObject?.state_id,
        id: selectedCountry,
        country: selectedObject?.country_name,
        stateName: selectedObject?.state_name,
      };
      setAccordionVisibility((prevState) => {
        const newState = { ...prevState };
        Object.keys(newState).forEach((key) => {
          newState[key] = false;
        });
        return newState;
      });
      setPricingTable((prevTable) => [...prevTable, newChef]);
    }
  };

  const toggleAccordion = async (
    rowIndex,
    countryId,
    stateId,
    cityId,
    areaZipcode,
    chefId,
    type
  ) => {
    const areaCode = areaZipcode?.toString();
    const chefid = chefId?.toString();
    const payload = {
      type: type,
      country: countryId,
      state: stateId,
      city: cityId,
      area: areaCode,
      chefId: chefid,
    };

    // Close the accordion if it's already open
    if (accordionVisibility[rowIndex]) {
      setAccordionVisibility((prevState) => ({
        ...prevState,
        [rowIndex]: false,
      }));

      return;
    }

    // Close any open accordions first
    setAccordionVisibility((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });

      return newState;
    });

    // Check if pricing plans for the specified criteria have already been fetched
    if (!accordionFetched[rowIndex]) {
      const TablePricingpalns = await ManagementServices.GetPricingPlans(
        payload
      );
      if (TablePricingpalns.status === 1) {
        setAccordionFetched((prevState) => ({
          ...prevState,
          [rowIndex]: true,
        }));
        // Set the fetched pricing plans in the state if needed
        setTablePricingplan(TablePricingpalns.data);
      } else {
        setTablePricingplan({});
        setAlrtMsg(TablePricingpalns.message);
        setErrorAlrtOpen(true);
      }
    }

    // Open the clicked accordion
    setAccordionVisibility((prevState) => ({
      ...prevState,
      [rowIndex]: true,
    }));

    setAccordionFetched((prevState) => ({
      ...prevState,
      [rowIndex]: false,
    }));
  };

  React.useEffect(() => {
    GetCountries();
    getPricingPlans();
    AdminDetails();
  }, [pricingTable]);

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const TotalAreaCodes = areas.map((x) => (
    <MenuItem key={x.area_code} value={x.area_code}>
      {x.area_code}
    </MenuItem>
  ));

  const TotalChefs = chefs.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.user_name}
    </MenuItem>
  ));

  const handleEditClick = () => {
    setCheckValue((prevCheckValue) => !prevCheckValue);
  };

  const transformData = (data) => {
    //rename Id to planId
    data.planid = data.id;
    delete data.id;
    // Convert specific string values to numbers
    const numericKeys = [
      "basic_chef_percent",
      "basic_corporate_percent",
      "basic_orders",
      "promo1_chef_percent",
      "promo1_corporate_percent",
      "promo1_days",
      "promo1_orders",
      "promo2_chef_percent",
      "promo2_corporate_percent",
      "promo2_days",
      "promo2_orders",
      "reentry_days",
      "reentry_orders",
    ];

    numericKeys.forEach((key) => {
      if (!isNaN(data[key])) {
        // Check if the value is already a number
        data[key] = parseFloat(data[key]); // Convert string to float
      }
    });

    //Convert chefId to string
    return data;
  };

  // Update pricing data
  const handleEditSubmit = async () => {
    transformData(pricingSendData);

    // Check if any of the pricing data values (except planId) are zero
    const pricingDataKeys = Object.keys(pricingSendData);
    const invalidValues = pricingDataKeys.filter(
      (key) =>
        key !== "planid" &&
        key !== "country" &&
        key !== "state" &&
        key !== "city" &&
        key !== "areaCode" &&
        key !== "chefId" &&
        (pricingSendData[key] === 0 ||
          pricingSendData[key] === "" ||
          isNaN(pricingSendData[key]))
    );

    if (invalidValues.length === 0) {
      if (
        pricingSendData?.basic_chef_percent +
          pricingSendData?.basic_corporate_percent ===
          100 &&
        pricingSendData?.promo1_chef_percent +
          pricingSendData?.promo1_corporate_percent ===
          100 &&
        pricingSendData?.promo2_chef_percent +
          pricingSendData?.promo2_corporate_percent ===
          100
      ) {
        const payload = {
          basicChefPercent: pricingSendData?.basic_chef_percent,
          basicCorporatePercent: pricingSendData?.basic_corporate_percent,
          basicOrders: pricingSendData?.basic_orders,
          promo1ChefPercent: pricingSendData?.promo1_chef_percent,
          promo1CorporatePercent: pricingSendData?.promo1_corporate_percent,
          promo1Days: pricingSendData?.promo1_days,
          promo1Orders: pricingSendData?.promo1_orders,
          promo2ChefPercent: pricingSendData?.promo2_chef_percent,
          promo2CorporatePercent: pricingSendData?.promo2_corporate_percent,
          promo2Days: pricingSendData?.promo2_days,
          promo2Orders: pricingSendData?.promo2_orders,
          reentryDays: pricingSendData?.reentry_days,
          reentryOrders: pricingSendData?.reentry_orders,
          planId: pricingSendData?.planid || "",
          country: 0,
          state: 0,
          city: 0,
          areaCode: "0",
          chefId: "",
        };
        const EditPricing = await ManagementServices.EditGlobalPricing(payload);
        if (EditPricing.status === 1) {
          setAlrtMsg(EditPricing.message);
          setSuccessAlrtOpen(true);
          setCheckValue((prevCheckValue) => !prevCheckValue);
          getPricingPlans();
        } else {
          setAlrtMsg(EditPricing.message);
          setErrorAlrtOpen(true);
        }
      } else {
        setAlrtMsg(
          "Please make sure that chef percentage and corporate percentage add up to 100%"
        );
        setErrorAlrtOpen(true);
      }
    } else {
      // Display an error message
      setAlrtMsg("Please ensure that no value is zero");
      setErrorAlrtOpen(true);
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const TableBodyData = pricingTable.map((item, index) => {
    const reverseIndex = pricingTable.length - 1 - index;

    return (
      <React.Fragment key={reverseIndex}>
        <TableRowComponent
          item={pricingTable[index]}
          accordionVisibility={accordionVisibility}
          toggleAccordion={() =>
            toggleAccordion(
              reverseIndex,
              item?.id,
              item?.stateId,
              item?.cityID,
              item?.areaZipcode,
              item?.chefId,
              item?.stateId
                ? item?.cityID
                  ? item?.areaZipcode
                    ? item?.chefId
                      ? 6
                      : 5
                    : 4
                  : 3
                : 2
            )
          }
          indexes={[reverseIndex]}
          tablePricingData={tablePricingPlan}
        />
      </React.Fragment>
    );
  });

  return (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid>
            <Grid p={3}>
              <header
                style={{
                  position: "fixed",
                  width: "80%",
                  backgroundColor: "#ffffff",
                  zIndex: 2,
                  top: "13.3%",
                  paddingTop: "10px",
                }}
              >
                <TitleComponent title={"Pricing"} />
              </header>
            </Grid>
            {roleDetails.role_id === 2 && (
              <Grid
                container
                sx={{ marginTop: "80px" }}
                pl={3}
                pr={3}
                justifyContent={"space-between"}
              >
                <Grid md={6}>
                  <Typography sx={priceHeading}>Global Pricing</Typography>
                </Grid>
                <Grid>
                  <ButtonComponent btnText={"EDIT"} onClick={handleEditClick} />
                </Grid>
              </Grid>
            )}
            {roleDetails.role_id === 2 && (
              <Grid p={3} container justifyContent={"space-between"}>
                <Grid md={3}>
                  <Typography sx={priceHeading}>Base Price</Typography>
                  <PricingTable
                    ColumnData={headData}
                    Rowdata={tableData}
                    check={checkValue}
                    pricingData={pricingSendData}
                    headerKeyMapping={headerBasicMapping}
                    onUpdatePricingData={updatePricingData}
                  />
                </Grid>
                <Grid md={4}>
                  <Typography sx={priceHeading}>
                    Promotional - 1 Price
                  </Typography>
                  <PricingTable
                    ColumnData={headDataTwo}
                    Rowdata={prom1PricingData}
                    check={checkValue}
                    pricingData={pricingSendData}
                    headerKeyMapping={headerPromo1Mapping}
                    onUpdatePricingData={updatePricingData}
                  />
                </Grid>
                <Grid md={4}>
                  <Typography sx={priceHeading}>
                    Promotinal - 2 Price
                  </Typography>
                  <PricingTable
                    ColumnData={headDataTwo}
                    Rowdata={prom2PricingData}
                    check={checkValue}
                    pricingData={pricingSendData}
                    headerKeyMapping={headerPromo2Mapping}
                    onUpdatePricingData={updatePricingData}
                  />
                </Grid>
              </Grid>
            )}
            {roleDetails.role_id === 2 && (
              <Grid p={3}>
                <Grid md={4}>
                  <Typography sx={priceHeading}>Re Entry Price</Typography>
                  <PricingTable
                    ColumnData={headDataTwo}
                    Rowdata={reEntryData}
                    check={checkValue}
                    pricingData={pricingSendData}
                    headerKeyMapping={headerreEnterMapping}
                    onUpdatePricingData={updatePricingData}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              container
              sx={{ display: "flex" }}
              justifyContent="flex-end"
              pr={3}
            >
              {!checkValue && (
                <ButtonComponent
                  btnText={"Submit"}
                  onClick={() => handleEditSubmit()}
                />
              )}
            </Grid>
          </Grid>
          <Grid>
            <Grid
              container
              pl={3}
              pr={3}
              pt={3}
              justifyContent={"space-between"}
            >
              <Grid md={6} pt={2}>
                <Typography sx={priceHeading}>Pricing</Typography>
              </Grid>
              <Grid>
                <PricingPlanDownload />
              </Grid>
              {/* <Grid>
                <ButtonComponent btnText={"Add"} />
              </Grid> */}
            </Grid>
            <Grid p={3}>
              {/* <PricingTable ColumnData={headDataa} Rowdata={tableData}/> */}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={PricetableHeaderCellStyle}>
                        <FormControl sx={formControlAdjust}>
                          <Select
                            // value={selectedCountry}
                            value={defaultCountryText}
                            onChange={(e) => handleChangeCountry(e)}
                            displayEmpty
                            className={classes.priceTable}
                          >
                            <MenuItem value={0} disabled>
                              Select Country
                            </MenuItem>
                            {totalCountries}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell sx={PricetableHeaderCell}>
                        <FormControl sx={formControlAdjust}>
                          <Select
                            value={defaultStateText}
                            displayEmpty
                            className={classes.priceTable}
                            onChange={(e) => handleChangeState(e)}
                          >
                            <MenuItem value={0} disabled>
                              Select State
                            </MenuItem>
                            {totalStates}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell sx={PricetableHeaderCell}>
                        <FormControl sx={formControlAdjust}>
                          <Select
                            value={defaultCityText}
                            displayEmpty
                            className={classes.priceTable}
                            onChange={(e) => handleChangeCity(e)}
                          >
                            <MenuItem value={0} disabled>
                              Select City
                            </MenuItem>
                            {totalCities}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell sx={PricetableHeaderCell}>
                        <FormControl sx={formControlAdjust}>
                          <Select
                            value={defaultAreaText}
                            displayEmpty
                            className={classes.priceTable}
                            onChange={(e) => handleChangeAreaCode(e)}
                          >
                            <MenuItem value={0} disabled>
                              Select Area Code
                            </MenuItem>
                            {TotalAreaCodes}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell sx={PricetableHeaderCell}>
                        <FormControl sx={formControlAdjust}>
                          <Select
                            value={selectChefText}
                            displayEmpty
                            className={classes.priceTable}
                            onChange={(e) => handleChangeChefCode(e)}
                          >
                            <MenuItem value={0} disabled>
                              Select Chef
                            </MenuItem>
                            {TotalChefs}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell sx={PricetableHeaderCell}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{TableBodyData}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <SuccessAlert
            alertMsg={alrtMsg}
            open={successAlrtOpen}
            close={closeSuccess}
          />
          <ErrorAlert
            alertMsg={alrtMsg}
            open={errorAlrtOpen}
            close={closeError}
          />
        </>
      )}
    </>
  );
};

export default Pricing;
