import React from "react";
import { TextField } from "@mui/material";
import { SearchOutlined } from '@material-ui/icons';
import { searchField } from "../../styles/common_styles";

export const SearchFieldComponent = ({ searchText }) => {
    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    const debouncedSearch = React.useMemo(
      () => debounce(searchText, 300),
      [searchText]
    );
    
    return (
      <>
        <TextField
          fullWidth
          size="small"
          id="standard-bare"
          variant="outlined"
          placeholder="Search"
          inputProps={{
            endadornment: <SearchOutlined />,
          }}
          sx={searchField}
          onChange={(event) => debouncedSearch(event.target.value)}
        />
      </>
    );
}