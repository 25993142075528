import React from "react";
import { Typography, Box, Grid, FormControl, TextField, } from "@mui/material";
import { LoginImage, LoginButtonContainer, BlockCenter, Emaillable, LoginForm, CustomField } from "../../styles/loginstyles";
import { useForm } from "react-hook-form";
import { Errors } from "../../styles/common_styles";
import { FormExpressions } from "../../utils/regularExpressions";
import { ButtonComponent } from "../../components/form_components/button";
import { CardTitle } from "../../components/form_components/card_title";
import { AuthServices } from "../../services/auth_services";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { LogoCard } from "../../components/logo";
import { useNavigate } from "react-router-dom";
import { BackDropLoader } from "../../components/loader_component";

const ForgotPassword = () => {

  const { register,handleSubmit,formState: { errors }, } = useForm();
  const [ alrtMsg, setAlrtMsg ] = React.useState("");
  const [ successAlrtOpen, setSuccessAlrtOpen ] = React.useState(false);
  const [ errorAlrtOpen, setErrorAlrtOpen ] = React.useState(false);
  const Navigate = useNavigate();
  const [backDropLoaderOpen, setBackDropLoaderOpen] = React.useState(false);


  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };
  const handleToggleBackDropLoader = () => {
    setBackDropLoaderOpen(!backDropLoaderOpen);
  };
  const SubmitForm = async (fromData) => {
    setBackDropLoaderOpen(true);
    const forgot = await AuthServices.forgotPassword(fromData);
    if (forgot.status === 1) {
      setAlrtMsg(forgot.message);
      setSuccessAlrtOpen(true);
      setBackDropLoaderOpen(false);
      setTimeout(() => {
        Navigate("/login");
      },2000)
    } else {
      setAlrtMsg(forgot.message);
      setErrorAlrtOpen(true);
    }
  };

  return (
    <>
     <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
    <Box sx = {{ flexGrow: 1 }}>
      <Grid container>
        <Grid item md = {6} sx = {LoginImage}></Grid>
        <Grid item md = {6}>
          <Grid conatiner sx = {BlockCenter} >
            <Grid md = {6}>
              <Grid container justifyContent={'center'} >
                <LogoCard /> 
              </Grid>
              <Grid>
                <CardTitle titleText={"FORGOT PASSWORD"} />
              </Grid>
              <Grid>
                <Grid component={'form'} onSubmit = {handleSubmit(SubmitForm)}>
                  <FormControl fullWidth  sx = {LoginForm} >
                    <Grid sx={Emaillable}>Email</Grid>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      size={"small"}
                      type={"text"}
                      autoComplete="off"
                      inputProps={{
                        placeholder: "Enter your email",
                      }}
                      {...register("email", {
                        required: true,
                        pattern: FormExpressions.email,
                      })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <Typography sx={Errors}>
                        oops! please enter your email
                      </Typography>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <Typography sx={Errors}>
                        please enter valid email
                      </Typography>
                    )}
                  </FormControl>
                  <Grid sx={LoginButtonContainer}>
                    <ButtonComponent
                      btnText = {"submit"}
                      buttonSize = {"large"}
                      type = {"submit"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </Box>
    </>
    
    
  );
};

export default ForgotPassword;
