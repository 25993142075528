import React from "react";
import { Typography, Box, Grid, FormControl , TextField } from "@mui/material";
import {
  LoginImage,
  LoginContainer,
  LoginButtonContainer,
  LoginFormContainer,
  eyeIconParentPosition
} from "../../styles/loginstyles";
import { ButtonComponent } from "../../components/form_components/button";
import { useForm } from "react-hook-form";
import { Emaillable, LoginForm, CustomField } from "../../styles/loginstyles";
import { FormExpressions } from "../../utils/regularExpressions";
import { Errors, IconStyles } from "../../styles/common_styles";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { CardTitle } from "../../components/form_components/card_title";
import { AuthServices } from "../../services/auth_services";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { useParams, useNavigate } from "react-router-dom";
import { LogoCard } from "../../components/logo";
import { StorageClient } from "../../utils/helpers";


const ResetPassword = () => {

  const [passIconType, setPassIconType] = React.useState("password");
  const [newpassIconType, setNewPassIconType] = React.useState("password");
  const [password, setPassword] = React.useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [checkPassword, setCheckPassword] = React.useState(false);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const { email, token } = useParams();
  const Navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handlePasswordIcon = () => {
    passIconType === "password"
      ? setPassIconType("text")
      : setPassIconType("password");
  };

  const newhandlePasswordIcon = () => {
    newpassIconType === "password"
      ? setNewPassIconType("text")
      : setNewPassIconType("password");
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const SubmitForm = async (formData) => {
    if (password.newPassword !== password.confirmPassword) {
      setCheckPassword(true);
      return;
    }
    setCheckPassword(false);

    const Reset = await AuthServices.resetPassword( formData, email, token );
    if (Reset.status === 1) {
      setAlrtMsg(Reset.message);
      setSuccessAlrtOpen(true);
      StorageClient.clear();
      setTimeout(() => {
        Navigate("/login")
      },3000)
    } else {
      setAlrtMsg(Reset.message);
      setErrorAlrtOpen(true);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item md={6} sx={LoginImage}></Grid>
        <Grid item md={6}>
          <Box sx={LoginContainer}>
            <Grid>
              <LogoCard />
            </Grid>
            <Grid>
              <CardTitle titleText={"NEW PASSWORD"} />
            </Grid>
            <Grid sx={LoginFormContainer}>
              <Grid component={'form'} onSubmit={handleSubmit(SubmitForm)}>
                <FormControl fullWidth sx={LoginForm}>
                  <Grid sx={Emaillable}>New Password</Grid>
                  <Grid sx={eyeIconParentPosition}>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      size={"small"}
                      value={password.newPassword}
                      type={passIconType}
                      autoComplete="off"
                      inputProps={{
                        placeholder: "Enter your password",
                      }}
                      {...register("newpassword", {
                        required: true,
                        pattern: FormExpressions.password,
                      })}
                      onChange={(e) =>
                        setPassword({
                          ...password,
                          newPassword: e.target.value,
                        })
                      }
                    />
                    {passIconType === "password" ? (
                      <RemoveRedEyeRoundedIcon
                        sx={IconStyles}
                        onClick={handlePasswordIcon}
                      />
                    ) : (
                      <VisibilityOffRoundedIcon
                        sx={IconStyles}
                        onClick={handlePasswordIcon}
                      />
                    )}
                  </Grid>

                    {errors.newpassword &&
                    errors.newpassword.type === "required" && (
                      <Typography sx={Errors}>
                        Oops! please enter your new password
                      </Typography>
                    )}
                    {errors.newpassword &&
                    errors.newpassword.type === "pattern" && (
                      <Typography sx={Errors}>
                        please enter valid password
                      </Typography>
                    )}
                </FormControl>
                <FormControl fullWidth sx={LoginForm}>
                  <Grid sx={Emaillable}>Confirm Password</Grid>
                  <Grid sx={eyeIconParentPosition}>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      size={"small"}
                      value={password.confirmPassword}
                      type={newpassIconType}
                      autoComplete="off"
                      inputProps={{
                        placeholder: "Enter your password",
                      }}
                      {...register("password", {
                        required: true,
                        pattern: FormExpressions.password,
                      })}
                      onChange={(e) =>
                        setPassword({
                          ...password,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                    {newpassIconType === "password" ? (
                      <RemoveRedEyeRoundedIcon
                        sx={IconStyles}
                        onClick={newhandlePasswordIcon}
                      />
                    ) : (
                      <VisibilityOffRoundedIcon
                        sx={IconStyles}
                        onClick={newhandlePasswordIcon}
                      />
                    )}
                  </Grid>

                  {errors.password && errors.password.type === "required" && (
                    <Typography sx={Errors}>
                      Oops! password should match with above password
                    </Typography>
                  )}
                  {errors.password && errors.password.type === "pattern" && (
                    <Typography sx={Errors}>
                      please enter valid password
                    </Typography>
                  )}
                  {checkPassword && (
                    <Typography sx={Errors}>
                      new password and confirm password should match
                    </Typography>
                  )}
                </FormControl>
                <Grid sx={LoginButtonContainer}>
                  <ButtonComponent
                    btnText={"submit"}
                    buttonSize={"large"}
                    type={"submit"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </Box>
  );
};

export default ResetPassword;
