import React from "react";
import { Typography, Box, Grid, TextField, FormControl } from "@mui/material";
import {
  LoginImage,
  LoginContainer,
  ForgotTextContainer,
  Paragraph,
  LoginFormContainer,
  LoginButtonContainer,
  eyeIconParentPosition,
} from "../../styles/loginstyles";
import { ButtonComponent } from "../../components/form_components/button";
import { Emaillable, LoginForm, CustomField } from "../../styles/loginstyles";
import { useForm } from "react-hook-form";
import { FormExpressions } from "../../utils/regularExpressions";
import { Errors, IconStyles } from "../../styles/common_styles";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { CardTitle } from "../../components/form_components/card_title";
import { AuthServices } from "../../services/auth_services";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { StorageClient, cryptoClient } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { LogoCard } from "../../components/logo";
import { BackDropLoader } from "../../components/loader_component";
const LoginComponent = () => {
  // state declarations
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [roles] = React.useState([]);
  const [passIconType, setPassIconType] = React.useState("password");
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const Navigate = useNavigate();
  const [backDropLoaderOpen, setBackDropLoaderOpen] = React.useState(false);

  // function declarations
  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };
  const handleToggleBackDropLoader = () => {
    setBackDropLoaderOpen(!backDropLoaderOpen);
  };

  const handlePasswordIcon = () => {
    passIconType === "password"
      ? setPassIconType("text")
      : setPassIconType("password");
  };

  const SubmitForm = async (formData) => {
    setBackDropLoaderOpen(true);

    try {
      const login = await AuthServices.adminSignIn(formData);
      if (login.status === 1) {
        const loggedPrevilages = roles.find(
          (item) => item.id === formData.role
        );
        const loggedDetails = JSON.stringify(loggedPrevilages);
        const encryptedString = await cryptoClient.encryptData(loggedDetails);
        StorageClient.setItem("loggedPrevilages", encryptedString);
        const {
          data: { accessToken, refreshToken, rolePrivileges },
          message,
        } = login;
        setAlrtMsg(message);
        setSuccessAlrtOpen(true);
        setBackDropLoaderOpen(false);
        StorageClient.setItem("accessToken", accessToken);
        StorageClient.setItem("refreshToken", refreshToken);
        StorageClient.setItem("rolePrivileges", rolePrivileges);
        StorageClient.setItem("previlageId", 6);
        setTimeout(() => {
          Navigate(`${rolePrivileges[0].path}`);
        }, 1000);
      } else {
        setAlrtMsg(login.message);
        setErrorAlrtOpen(true);
        setBackDropLoaderOpen(false);
      }
    } catch (error) {
      console.error(`Error occured while submitting form:${error}`);
    }
  };

  const CheckLogin = async () => {
    try {
      const check = await StorageClient.getItem("rolePrivileges");
      if (check !== undefined) {
        Navigate(`${check[0].path}`);
      }
    } catch (error) {
      console.error(`Error occured while checking login:${error}`);
    }
  };

  React.useEffect(() => {
    CheckLogin();
    // AdminRoles();
  }, []);

  return (
    <>
      <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item md={6} sx={LoginImage}></Grid>
          <Grid item md={6}>
            <Box sx={LoginContainer}>
              <Grid>
                <LogoCard />
              </Grid>
              <Grid>
                <CardTitle titleText={"LOGIN"} />
              </Grid>
              <Grid sx={LoginFormContainer}>
                <Grid component={"form"} onSubmit={handleSubmit(SubmitForm)}>
                  <FormControl fullWidth sx={LoginForm}>
                    <Grid sx={Emaillable}>Email</Grid>
                    <TextField
                      sx={CustomField}
                      fullWidth
                      size={"small"}
                      type={"text"}
                      autoComplete="off"
                      inputProps={{
                        placeholder: "Enter your email",
                      }}
                      {...register("email", {
                        required: true,
                        pattern: FormExpressions.email,
                      })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <Typography sx={Errors}>
                        oops! please enter your email to login
                      </Typography>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <Typography sx={Errors}>
                        please enter valid email
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth sx={LoginForm}>
                    <Grid sx={Emaillable}>Password</Grid>
                    <Grid sx={eyeIconParentPosition}>
                      <TextField
                        sx={CustomField}
                        fullWidth
                        size={"small"}
                        type={passIconType}
                        autoComplete="off"
                        inputProps={{
                          placeholder: "Enter your password",
                        }}
                        {...register("password", {
                          required: true,
                          pattern: FormExpressions.password,
                        })}
                      />
                      {passIconType === "password" ? (
                        <RemoveRedEyeRoundedIcon
                          sx={IconStyles}
                          onClick={handlePasswordIcon}
                        />
                      ) : (
                        <VisibilityOffRoundedIcon
                          sx={IconStyles}
                          onClick={handlePasswordIcon}
                        />
                      )}
                    </Grid>

                    {errors.password && errors.password.type === "required" && (
                      <Typography sx={Errors}>
                        Oops! please enter your password to login
                      </Typography>
                    )}
                    {errors.password && errors.password.type === "pattern" && (
                      <Typography sx={Errors}>
                        please enter valid password
                      </Typography>
                    )}
                  </FormControl>
                  <Grid sx={ForgotTextContainer}>
                    <Typography
                      variant="h6"
                      component="a"
                      href="https://homefood.krify.com/forgotPassword"
                      sx={Paragraph}
                    >
                      Forgot password?
                    </Typography>
                  </Grid>
                  <Grid sx={LoginButtonContainer}>
                    <ButtonComponent
                      btnText={"Login"}
                      buttonSize={"large"}
                      type={"submit"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <SuccessAlert
          alertMsg={alrtMsg}
          open={successAlrtOpen}
          close={closeSuccess}
        />
        <ErrorAlert
          alertMsg={alrtMsg}
          open={errorAlrtOpen}
          close={closeError}
        />
      </Box>
    </>
  );
};

export default LoginComponent;
