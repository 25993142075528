import React from "react";
import { Grid, TableCell, TableRow } from "@mui/material";
import { moduleHeadContainer } from "../../styles/common_styles";
import { TitleComponent } from "../../components/title_component";
import { TableComponent } from "../../components/table_component";
import { ManagementServices } from "../../services/management_services";
import { tableHeadCell } from "../../styles/common_styles";
import { TableCellComponent } from "../../components/table_cell_component";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import { HFPagination } from "../../components/pagination";
import { Loader } from "../../components/loader_component";

const ContactUs = () => {
  const [contactDetails, setContactDetails] = React.useState([]);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [loaderData, setLoaderData] = React.useState(true);
  const [searchData, setSearchData] = React.useState("");
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });
  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  function formatCreatedAtToGMT(created_at) {
    const date = new Date(created_at);
    // Format the date to GMT string
    const gmtDateString = new Intl.DateTimeFormat("en-US", {
      timeZone: "GMT",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(date);

    // Extract the date part and time part
    const [datePart, timePart] = gmtDateString.split(", ");

    return `${datePart},${timePart}`;
  }

  const tableHeaders = ["Sno", "Name", "Email", "Message", "created at"];

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const contactUsDetails = async () => {
    const payload = {
      min: pageConfig.min,
      max: pageConfig.max,
      search: searchData,
    };
    let contactUsDetailss;
    try {
      contactUsDetailss = await ManagementServices.getContactusDetails(payload);
      if (contactUsDetailss.status === 1) {
        setContactDetails(contactUsDetailss.data.contactUsData);
        setLoaderData(false);
        setPaginationData({
          ...paginationData,
          currentPage: contactUsDetailss.data.currentPage,
          RecordsForPage: contactUsDetailss.data.pageSize,
          totalRecords: contactUsDetailss.data.totalItems,
          pageCount: contactUsDetailss.data.totalPages,
        });
        // setAlrtMsg(contactUsDetailss.message)
        // setSuccessAlrtOpen(true)
      } else {
        setLoaderData(false);
        setAlrtMsg(contactUsDetailss.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const TableBody = contactDetails.map((data, index) => (
    <TableRow key={data.id}>
      <TableCellComponent value={index + 1} />
      <TableCellComponent value={data.name} />
      <TableCellComponent value={data.email} />
      <TableCellComponent value={data.message} />
      <TableCellComponent value={formatCreatedAtToGMT(data.created_at)} />
    </TableRow>
  ));

  React.useEffect(() => {
    contactUsDetails();
  }, [pageConfig.min, searchData]);

  return (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            justifyContent={"space-around"}
            alignItems={"center"}
            sx={moduleHeadContainer}
            height={"18vh"}
          >
            <Grid item md={9}>
              <TitleComponent title={"Contact Us"} />
            </Grid>
            <Grid item md={3}>
              <SearchFieldComponent searchText={handleSearchData} />
            </Grid>
          </Grid>
          <Grid height={"70vh"}>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
            <HFPagination details={paginationData} paginate={handlePaginate} />
          </Grid>
          <Grid>
            <SuccessAlert
              alertMsg={alrtMsg}
              open={successAlrtOpen}
              close={closeSuccess}
            />
            <ErrorAlert
              alertMsg={alrtMsg}
              open={errorAlrtOpen}
              close={closeError}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default ContactUs;
