import * as React from "react";
import { Grid, Tab, Select, FormControl, MenuItem } from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import { TitleComponent } from "../../components/title_component";
import {
  tabStyle,
  tabItem,
  tabHeader,
  onboardingTab,
} from "../../styles/common_styles";
import "../../styles/common_styles.css";
import { BackDropLoader } from "../../components/loader_component";
import { OnBoardingHandling } from "../../components/onboarding_handling";
import { CommonServices } from "../../services/common_services";
import { ButtonComponent } from "../../components/form_components/button";

const Onboarding = () => {
  const [value, setValue] = React.useState("1");
  const [backDropLoaderOpen, setBackDropLoaderOpen] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleMOdalClose = () => setModalOpen(false);
  const handleToggleBackDropLoader = () => {
    setBackDropLoaderOpen(!backDropLoaderOpen);
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, event.target.value);
    setSelectedState(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));
  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  React.useEffect(() => {
    GetCountries();
  }, []);

  return (
    <>
      <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
      <Grid
        container
        justifyContent={"space-between"}
        alignItems="center"
        spacing={2}
        p={3}
        sx={tabHeader}
      >
        <Grid item md={4}>
          <TitleComponent title={"Onboarding"} />
        </Grid>
        <Grid item md={4}>
          <TabContext value={value}>
            <TabList
              sx={tabStyle}
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
            >
              <Tab label="Consumer" value="1" sx={tabItem} />
              <Tab label="Chef" value="2" sx={tabItem} />
            </TabList>
          </TabContext>
        </Grid>
        <Grid item md={4}>
          <Grid container justifyContent={"end"} spacing={2}>
            <Grid item md={4}>
              <FormControl fullWidth>
                <Select
                  value={selectedCountry}
                  size={"small"}
                  inputProps={{ "aria-label": "Country" }}
                  onChange={handleChangeCountry}
                >
                  <MenuItem value={0}>All</MenuItem>
                  {totalCountries}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <Select
                  value={selectedState}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "State" }}
                  onChange={handleChangeState}
                >
                  <MenuItem value={0} disabled>
                    Select state
                  </MenuItem>
                  {totalStates}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <ButtonComponent btnText={"ADD"} onClick={handleModalOpen} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* TabPanel component moved after the parent Grid */}
      <TabContext value={value}>
        <TabPanel value={value} sx={onboardingTab}>
          <OnBoardingHandling
            fromModule={value}
            country={selectedCountry}
            state={selectedState}
            open={modalOpen}
            handleClose={handleMOdalClose}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default Onboarding;
