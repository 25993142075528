export const LayOutColors = {
  LayOutbackground: "#F2F7FF",
  bodyBackGround: "#FFFFFF",
  headerBackGround: "#FFFFFF",
  sideNavBackGround: "#FFFFFF",
  sideNavTextColor:'#000000'
};

export const ButtonColors = {
  backGround: '#000000',
  color:'#FFFFFF',
};

export const componentTitleColor = {
  color:'#000000'
};

export const searchFieldColor = {
  backGround:'#EEEEEE !important'
};

export const IconColors = {
  eidtIconColor:'#0FBB00',
  blockIconColor:'#A7A7A',
  blockIconColorActive:'#FF6421',
  deleteIconColor:'#D30000',
  emailIconColor:'#FFB525'
};

export const TableColor = {
  tableHeadBackGround:'#000',
  tableHeadColor:'#FFFFFF',
  tableBodyColor:'#333843',
};

export const ErrorColor = {
  errorColor:'#FF0800'
};

export const SpanColor = {
  SpanColor: '#8492A6'
};

export const PaginationColors = {
  TextColor: "#667085",
};

export const DashBoardColors = {
  count: "#000000",
  paragraph:'#030229'
};