import AxiosClient from "../axios/client";

export const AuthServices = {

  /**
   * Retrieves the roles from the server.
   *
   * @return {Promise<Array>} A promise that resolves to an array of roles.
   */
  getRoles: async () => {
    try {
      const roles = await AxiosClient.get("getRoles");
      return roles;
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  },
  
  /**
   * adminSignIn: async (formData) => {
   * @param {type} formData - description of parameter
   * @return {type} description of return value
   */
  adminSignIn: async (formData) => {
    try {
      const login = await AxiosClient.post("adminSignIn", {
        email: formData.email,
        password: formData.password,
      });
      return login;
    } catch (error) {
      console.error("Error occured while logging in:", error);
    }
  },

  /**
   * A function to handle the forgot password functionality.
   *
   * @param {Object} formData - the form data containing the email for password reset
   * @return {Promise} The result of the password reset request
   */
  forgotPassword: async (formData) => {
    try {
      const forgot = await AxiosClient.post("forgotPassword", {
        email: formData.email,
      });
      return forgot;
    } catch (error) {
      console.error("Error occured :", error);
    }
  },

  /**
   * Resets the user's password using the provided form data, email, and token.
   *
   * @param {Object} formData - An object containing the new password.
   * @param {string} email - The user's email address.
   * @param {string} token - The reset token.
   * @return {Promise<Object>} A promise that resolves to the response from the server.
   */
  resetPassword: async (formData, email, token) => {
    try {
      const reset = await AxiosClient.post("resetPassword", {
        password: formData.password,
        email,
        token,
      });

      return reset;
    } catch (error) {
      console.error("Error occured :", error);
    }
  },
};