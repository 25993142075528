import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import PricingTable from "../../components/pricing_table_component";
import { priceHeading } from "../../styles/common_styles";
import { ButtonComponent } from "../../components/form_components/button";
import { ManagementServices } from "../../services/management_services";
import { ErrorAlert, SuccessAlert } from "../../components/alert";
import DeleteIcon from "../../assets/icons/delete.svg";

function AccordionComponent({
  tableShowData,
  countryName,
  countryID,
  stateId,
  cityId,
  areaCode,
  chefId,
}) {
  const [checkValue, setCheckValue] = useState(true);
  const [showPricingData, setShowPricingData] = useState({});
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const headData = ["Chef", "Corporate", "orders"];

  useEffect(() => {
    setShowPricingData({
      basic_chef_percent: tableShowData?.basic_chef_percent || 0,
      basic_corporate_percent: tableShowData?.basic_corporate_percent || 0,
      basic_orders: tableShowData?.basic_orders || 0,
      promo1_chef_percent: tableShowData?.promo1_chef_percent || 0,
      promo1_corporate_percent: tableShowData?.promo1_corporate_percent || 0,
      promo1_days: tableShowData?.promo1_days || 0,
      promo1_orders: tableShowData?.promo1_orders || 0,
      promo2_chef_percent: tableShowData?.promo2_chef_percent || 0,
      promo2_corporate_percent: tableShowData?.promo2_corporate_percent || 0,
      promo2_days: tableShowData?.promo2_days || 0,
      promo2_orders: tableShowData?.promo2_orders || 0,
      reentry_days: tableShowData?.reentry_days || 0,
      reentry_orders: tableShowData?.reentry_orders || 0,
      id: tableShowData?.id || "",
    });
  }, [tableShowData]);

  const headerBasicMapping = {
    Chef: "basic_chef_percent",
    Corporate: "basic_corporate_percent",
    orders: "basic_orders",
  };

  const tableData = [
    {
      Chef: showPricingData?.basic_chef_percent || 0,
      Corporate: showPricingData.basic_corporate_percent || 0,
      orders: showPricingData?.basic_orders || 0,
    },
  ];

  const prom1PricingData = [
    {
      Chef: showPricingData?.promo1_chef_percent || 0,
      Corporate: showPricingData?.promo1_corporate_percent || 0,
      Days: showPricingData?.promo1_days || 0,
      orders: showPricingData?.promo1_orders || 0,
    },
  ];
  const prom2PricingData = [
    {
      Chef: showPricingData?.promo2_chef_percent || 0,
      Corporate: showPricingData?.promo2_corporate_percent || 0,
      Days: showPricingData?.promo2_days || 0,
      orders: showPricingData?.promo2_orders || 0,
    },
  ];

  const reEntryData = [
    {
      Chef: showPricingData?.basic_chef_percent || 0,
      Corporate: showPricingData?.basic_corporate_percent || 0,
      Days: showPricingData?.reentry_days || 0,
      orders: showPricingData?.reentry_orders || 0,
    },
  ];

  const headerPromo1Mapping = {
    Chef: "promo1_chef_percent",
    Corporate: "promo1_corporate_percent",
    Days: "promo1_days",
    orders: "promo1_orders",
  };

  const headDataTwo = ["Chef", "Corporate", "Days", "orders"];

  const headerPromo2Mapping = {
    Chef: "promo2_chef_percent",
    Corporate: "promo2_corporate_percent",
    Days: "promo2_days",
    orders: "promo2_orders",
  };

  const headerreEnterMapping = {
    Chef: "reentry_Chef",
    Corporate: "reentry_Corporate",
    Days: "reentry_days",
    orders: "reentry_orders",
  };

  //Update pricing data
  const updatePricingData = (updatedData) => {
    // Update the pricing data in the parent component state
    setShowPricingData(updatedData);
  };

  //Close toast alert message
  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  //Close toast error message
  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  //convert Id to planId and specific string values to numbers
  const transformData = (data) => {
    //rename Id to planId
    data.planid = data.id;
    delete data.id;
    // Convert specific string values to numbers
    const numericKeys = [
      "basic_chef_percent",
      "basic_corporate_percent",
      "basic_orders",
      "promo1_chef_percent",
      "promo1_corporate_percent",
      "promo1_days",
      "promo1_orders",
      "promo2_chef_percent",
      "promo2_corporate_percent",
      "promo2_days",
      "promo2_orders",
      "reentry_days",
      "reentry_orders",
    ];

    numericKeys.forEach((key) => {
      if (!isNaN(data[key])) {
        // Check if the value is already a number
        data[key] = parseFloat(data[key]);
      }
    });
    //Convert chefId to string
    return data;
  };

  //Submit button click
  const handleEditSubmit = async () => {
    transformData(showPricingData);

    const pricingDataKeys = Object.keys(showPricingData);
    //expect keys planid, country, state, city, areaCode, chefId to be present
    const invalidValues = pricingDataKeys.filter(
      (key) =>
        key !== "planid" &&
        key !== "country" &&
        key !== "state" &&
        key !== "city" &&
        key !== "areaCode" &&
        key !== "chefId" &&
        (showPricingData[key] === 0 ||
          showPricingData[key] === "" ||
          isNaN(showPricingData[key]))
    );

    if (invalidValues.length === 0) {
      const BasePrice_percentage =
        showPricingData?.basic_chef_percent +
        showPricingData?.basic_corporate_percent;
      const promo1_percentage =
        showPricingData?.promo1_chef_percent +
        showPricingData?.promo1_corporate_percent;
      const promo2_percentage =
        showPricingData?.promo2_chef_percent +
        showPricingData?.promo2_corporate_percent;

      // Check if all values are equal than 100
      if (
        BasePrice_percentage === 100 &&
        promo1_percentage === 100 &&
        promo2_percentage === 100
      ) {
        // All values are greater than 0, proceed with the API call
        const payload = {
          basicChefPercent: showPricingData?.basic_chef_percent,
          basicCorporatePercent: showPricingData?.basic_corporate_percent,
          basicOrders: showPricingData?.basic_orders,
          promo1ChefPercent: showPricingData?.promo1_chef_percent,
          promo1CorporatePercent: showPricingData?.promo1_corporate_percent,
          promo1Days: showPricingData?.promo1_days,
          promo1Orders: showPricingData?.promo1_orders,
          promo2ChefPercent: showPricingData?.promo2_chef_percent,
          promo2CorporatePercent: showPricingData?.promo2_corporate_percent,
          promo2Days: showPricingData?.promo2_days,
          promo2Orders: showPricingData?.promo2_orders,
          reentryDays: showPricingData?.reentry_days,
          reentryOrders: showPricingData?.reentry_orders,
          planId: showPricingData?.planid || "",
          country: countryID,
          state: stateId || 0,
          city: cityId || 0,
          areaCode: areaCode || "0",
          chefId: chefId || "",
        };
        //Api Calling
        const EditTablePricing = await ManagementServices.EditGlobalPricing(
          payload
        );

        if (EditTablePricing.status === 1) {
          //Success message
          setAlrtMsg(EditTablePricing.message);
          setSuccessAlrtOpen(true);
          // setShowPricingData(EditTablePricing.data);
          setCheckValue((prevCheckValue) => !prevCheckValue);
        } else {
          // Display an error message
          setAlrtMsg(EditTablePricing.message);
          setErrorAlrtOpen(true);
        }
      } else {
        setAlrtMsg(
          "Please ensure that the  sum of chef and corporate percentages equals 100%"
        );
        setErrorAlrtOpen(true);
      }
    } else {
      // Validation failed, some values are zero
      setAlrtMsg(
        "All values must be filled. Please ensure that no value is zero."
      );
      setErrorAlrtOpen(true);
    }
  };

  //Edit button click
  const handleEditClick = () => {
    setCheckValue((prevCheckValue) => !prevCheckValue);
  };

  /**
   * The DeleteHandleOpen function handles the deletion process.
   *
   * @return {JSX.Element} The JSX elements for deleting a pricing plan
   */

  const DeleteHandleOpen = async () => {
    const payload = {
      pricingPlanId: showPricingData?.id
        ? showPricingData.id
        : showPricingData?.planid,
    };
    //DeletePricing Api Calling
    const Deletepricing = await ManagementServices.DeletePricingPlan(payload);
    if (Deletepricing.status === 1) {
      //Success message
      setAlrtMsg(Deletepricing.message);
      setSuccessAlrtOpen(true);
      setShowPricingData({
        basic_chef_percent: 0,
        basic_corporate_percent: 0,
        basic_orders: 0,
        promo1_chef_percent: 0,
        promo1_corporate_percent: 0,
        promo1_days: 0,
        promo1_orders: 0,
        promo2_chef_percent: 0,
        promo2_corporate_percent: 0,
        promo2_days: 0,
        promo2_orders: 0,
        reentry_days: 0,
        reentry_orders: 0,
        id: "",
      });
    } else {
      // Display an error message
      setAlrtMsg(Deletepricing.message);
      setErrorAlrtOpen(true);
    }
  };

  const pricingPlanId = showPricingData?.id
    ? showPricingData.id
    : showPricingData?.planid;

  return (
    <>
      <Grid container pl={2} pr={3} justifyContent={"space-between"}>
        <Grid md={6} pt={2}>
          <Typography sx={priceHeading}>{countryName}</Typography>
        </Grid>
        <Grid>
          <ButtonComponent btnText={"EDIT"} onClick={handleEditClick} />
          {pricingPlanId && (
            <IconButton onClick={() => DeleteHandleOpen()}>
              <img src={DeleteIcon} alt="delete" />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid p={3} container justifyContent={"space-between"}>
        <Grid md={3}>
          <Typography sx={priceHeading}>Base Price</Typography>
          <PricingTable
            ColumnData={headData}
            Rowdata={tableData}
            check={checkValue}
            pricingData={showPricingData}
            headerKeyMapping={headerBasicMapping}
            onUpdatePricingData={updatePricingData}
          />
        </Grid>
        <Grid md={4}>
          <Typography sx={priceHeading}>Promotinal - 1 Price</Typography>
          <PricingTable
            Rowdata={prom1PricingData}
            check={checkValue}
            ColumnData={headDataTwo}
            pricingData={showPricingData}
            headerKeyMapping={headerPromo1Mapping}
            onUpdatePricingData={updatePricingData}
          />
        </Grid>
        <Grid md={4}>
          <Typography sx={priceHeading}>Promotinal - 2 Price</Typography>
          <PricingTable
            ColumnData={headDataTwo}
            Rowdata={prom2PricingData}
            check={checkValue}
            pricingData={showPricingData}
            headerKeyMapping={headerPromo2Mapping}
            onUpdatePricingData={updatePricingData}
          />
        </Grid>
      </Grid>
      <Grid p={3}>
        <Grid md={4}>
          <Typography sx={priceHeading}>Re Entry Price</Typography>
          <PricingTable
            ColumnData={headDataTwo}
            Rowdata={reEntryData}
            check={checkValue}
            pricingData={showPricingData}
            headerKeyMapping={headerreEnterMapping}
            onUpdatePricingData={updatePricingData}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ display: "flex" }} justifyContent="flex-end" pr={3}>
        {!checkValue && (
          <ButtonComponent
            btnText={"Submit"}
            onClick={() => handleEditSubmit()}
          />
        )}
      </Grid>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
}

export default AccordionComponent;
