import React from "react";
import { Modal, Box, Typography, Grid, IconButton } from "@mui/material";
import {
  modalStyles,
  modalHeading,
  roleDetailStyle,
} from "../styles/common_styles";
import { ManagementServices } from "../services/management_services";
import { Loader } from "./loader_component";
import CloseIcon from "@mui/icons-material/Close";

export const RoleDetailModal = ({ open, handleClose, userId }) => {
  const [roleViewDetails, setRoleViewDetails] = React.useState({});
  const [loaderData, setLoaderData] = React.useState(true);
  const getViewRoleDetails = async () => {
    const payload = {
      userId: userId,
    };
    const roleDetails = await ManagementServices.getRoleDetailview(payload);
    if (roleDetails.status === 1) {
      setRoleViewDetails(roleDetails.data);
      setLoaderData(false);
    }
  };

  React.useEffect(() => {
    if (open === true) {
      getViewRoleDetails();
    }
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="sample-modal-title"
        aria-describedby="sample-modal-description"
      >
        <Box sx={modalStyles}>
          {loaderData ? (
            <Loader />
          ) : (
            <Grid container>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs>
                    <div style={{ textAlign: "center", fontWeight: "bold" }}>
                      View Details
                    </div>
                  </Grid>
                  <Grid item>
                    <IconButton
                      mb={2}
                      onClick={handleClose}
                      sx={{ color: "black", backgroundColor: "grey" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Grid md={4} sx={roleDetailStyle}>
                    Frist Name
                  </Grid>
                  <Grid pl={2} md={2}>
                    :
                  </Grid>
                  <Grid md={4}>{roleViewDetails?.user?.first_name}</Grid>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Grid md={4} sx={roleDetailStyle}>
                    last Name
                  </Grid>
                  <Grid md={2}>:</Grid>
                  <Grid md={4}>{roleViewDetails?.user?.last_name}</Grid>
                </Grid>
              </Grid>
              <Grid item container md={12} mt={4}>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Grid md={4} sx={roleDetailStyle}>
                    Phone Number
                  </Grid>
                  <Grid pl={2} md={2}>
                    :
                  </Grid>
                  <Grid md={4}>{roleViewDetails?.user?.phone}</Grid>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Grid md={4} sx={roleDetailStyle}>
                    Email
                  </Grid>
                  <Grid md={1}>:</Grid>
                  <Grid md={5}>{roleViewDetails?.user?.email}</Grid>
                </Grid>
              </Grid>
              <Grid item container md={12} mt={4}>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Grid md={4} sx={roleDetailStyle}>
                    Address
                  </Grid>
                  <Grid pl={2} md={2}>
                    :
                  </Grid>
                  <Grid md={4}>{roleViewDetails?.user?.address}</Grid>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Grid md={4} sx={roleDetailStyle}>
                    Role
                  </Grid>
                  <Grid md={2}>:</Grid>
                  <Grid md={4}>{roleViewDetails?.user?.role_name}</Grid>
                </Grid>
              </Grid>
              <Grid item container md={12} mt={4}>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {roleViewDetails?.assignedLocations?.some(
                    (location) => location?.country_name
                  ) ? (
                    <>
                      <Grid md={4} sx={roleDetailStyle}>
                        Country
                      </Grid>
                      <Grid pl={2} md={2}>
                        :
                      </Grid>
                      <Grid md={4}>
                        {roleViewDetails?.assignedLocations
                          .filter(
                            (location, index, array) =>
                              array.findIndex(
                                (item) =>
                                  item.country_name === location.country_name
                              ) === index
                          )
                          .map((location, index, array) => (
                            <Grid key={index}>
                              {location.country_name}
                              {index !== array.length - 1 && ","}
                            </Grid>
                          ))}
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {roleViewDetails?.assignedLocations?.some(
                    (location) => location?.state_name
                  ) ? (
                    <>
                      <Grid md={4} sx={roleDetailStyle}>
                        State
                      </Grid>
                      <Grid md={2}>:</Grid>
                      <Grid md={4}>
                        {roleViewDetails?.assignedLocations
                          .filter((location) => location.state_name)
                          .map((location, index, array) => (
                            <span key={index}>
                              {location.state_name}
                              {index !== array.length - 1 && ","}
                            </span>
                          ))}
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item container md={12} mt={4}>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {roleViewDetails?.assignedLocations?.some(
                    (location) =>
                      location?.city_names?.some((city) => city !== null) // Check if any city name is not null
                  ) ? (
                    <>
                      <Grid md={4} sx={roleDetailStyle}>
                        City
                      </Grid>
                      <Grid pl={2} md={2}>
                        :
                      </Grid>
                      <Grid md={4}>
                        {roleViewDetails?.assignedLocations
                          .filter((location) =>
                            location.city_names?.some((city) => city !== null)
                          ) // Filter out locations where city_names contains non-null values
                          .map((location, index) => (
                            <div key={index}>
                              {location.city_names
                                .filter((city) => city !== null)
                                .join(", ")}
                            </div>
                          ))}
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              {roleViewDetails?.modifiedFields?.length > 0 && (
                <Grid item md={12}>
                  <Typography sx={modalHeading} mt={2}>
                    Modified Details
                  </Typography>
                </Grid>
              )}
              {
                roleViewDetails?.modifiedFields &&
                  roleViewDetails?.modifiedFields?.map((item) => (
                    // item.type === "New" && (
                    <>
                      <Grid item container md={12} mt={4}>
                        <Grid
                          item
                          md={6}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Grid md={4} sx={roleDetailStyle}>
                            Type
                          </Grid>
                          <Grid pl={2} md={2}>
                            :
                          </Grid>
                          <Grid
                            pl={2}
                            md={4}
                            sx={{
                              color: item.type === "New" ? "green" : "red",
                            }}
                          >
                            {item.type}
                          </Grid>
                          <Grid md={4} sx={roleDetailStyle}>
                            Country
                          </Grid>
                          <Grid pl={2} md={2}>
                            :
                          </Grid>
                          <Grid pl={2} md={4}>
                            {item.country_name}
                          </Grid>
                        </Grid>
                        {item.state_name && (
                          <Grid
                            item
                            md={6}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <Grid md={4} sx={roleDetailStyle}>
                              State
                            </Grid>
                            <Grid pl={2} md={2}>
                              :
                            </Grid>
                            <Grid pl={2} md={4}>
                              {item.state_name}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item container md={12} mt={4}>
                        <Grid
                          item
                          md={6}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {item.city_names && item.city_names.length > 0 ? (
                            <Grid md={4} sx={roleDetailStyle}>
                              City
                            </Grid>
                          ) : null}
                          {item.city_names && item.city_names.length > 0 ? (
                            <Grid pl={2} md={2}>
                              :
                            </Grid>
                          ) : null}
                          {item.city_names && item.city_names.length > 0 ? (
                            <Grid pl={2} md={4}>
                              {item.city_names.map((city) => city)}
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    </>
                  ))
                // )
              }
            </Grid>
          )}
          <Grid mb={6}></Grid>
        </Box>
      </Modal>
    </>
  );
};
