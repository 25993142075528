import React from "react";
import {
  Grid,
  TableCell,
  TableRow,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { moduleHeadContainer } from "../../styles/common_styles";
import { TitleComponent } from "../../components/title_component";
import { TableComponent } from "../../components/table_component";
import { ManagementServices } from "../../services/management_services";
import { tableHeadCell } from "../../styles/common_styles";
import { TableCellComponent } from "../../components/table_cell_component";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import { HFPagination } from "../../components/pagination";
import { Loader } from "../../components/loader_component";
import { tableParentHolder } from "../../styles/common_styles";
import { CommonServices } from "../../services/common_services";

const OrderBreakDown = () => {
  const [contactDetails, setContactDetails] = React.useState([]);
  const [ordersBreakDownDetailsData, setOrdersBreakDownDetailsData] =
    React.useState([]);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(0);
  const [cities, setCities] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState(0);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [loaderData, setLoaderData] = React.useState(true);
  const [searchData, setSearchData] = React.useState("");
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });
  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const tableHeaders = [
    "Sno",
    "OrderId",
    "TotalPrice",
    "FoodPrice",
    "Corporate",
    "Chef",
    "SalesTax",
    "Tip",
    "DeliveryFee",
    "StoreCredit",
    "Reward",
  ];

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, event.target.value);
    setSelectedState(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    GetCities(2, event.target.value);
  };
  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);
      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);
      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching cities:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const orderBreakDownDetails = async () => {
    const payload = {
      min: pageConfig.min,
      max: pageConfig.max,
      search: searchData,
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
    };
    let orderBreakDownDetailsResponse;
    try {
      orderBreakDownDetailsResponse =
        await ManagementServices.getOrdersBreakdownDetails(payload);
      if (orderBreakDownDetailsResponse.status === 1) {
        setOrdersBreakDownDetailsData(
          orderBreakDownDetailsResponse.data.orders
        );
        setLoaderData(false);
        setPaginationData({
          ...paginationData,
          currentPage: orderBreakDownDetailsResponse.data.currentPage,
          RecordsForPage: orderBreakDownDetailsResponse.data.pageSize,
          totalRecords: orderBreakDownDetailsResponse.data.totalOrders,
          pageCount: orderBreakDownDetailsResponse.data.totalPages,
        });
        // setAlrtMsg(orderBreakDownDetailsResponse.message)
        // setSuccessAlrtOpen(true)
      } else {
        setLoaderData(false);
        setAlrtMsg(orderBreakDownDetailsResponse.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const TableBody = ordersBreakDownDetailsData.map((data, index) => (
    <TableRow key={data.id}>
      <TableCellComponent
        value={
          (paginationData.currentPage - 1) * paginationData.RecordsForPage +
          1 +
          index
        }
        indexColoumn={true}
      />
      <TableCellComponent value={data.order_id} />
      <TableCellComponent value={`${data.currency}${data.total}`} />
      <TableCellComponent value={`${data.currency}${data.subtotal}`} />
      <TableCellComponent value={`${data.currency}${data.corporate_amount}`} />
      <TableCellComponent value={`${data.currency}${data.chef_amount}`} />
      <TableCellComponent value={`${data.currency}${data.tax}`} />
      <TableCellComponent value={`${data.currency}${data.tip}`} />
      <TableCellComponent value={`${data.currency}${data.delivery_fee}`} />
      <TableCellComponent value={`${data.currency}${data.store_credit}`} />
      <TableCellComponent value={`${data.currency}${data.rewards}`} />
    </TableRow>
  ));

  React.useEffect(() => {
    orderBreakDownDetails();
    GetCountries();
  }, [pageConfig.min, searchData]);

  React.useEffect(() => {
    orderBreakDownDetails();
    GetCountries();
  }, [
    pageConfig.min,
    searchData,
    selectedCountry,
    selectedState,
    selectedCity,
  ]);

  return (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            justifyContent={"space-around"}
            alignItems={"center"}
            sx={moduleHeadContainer}
          >
            <Grid item md={3}>
              <TitleComponent title={"Orders Breakdown"} />
            </Grid>
            <Grid item md={3}>
              <SearchFieldComponent searchText={handleSearchData} />
            </Grid>
            <Grid item md={4}>
              <Grid container justifyContent={"end"} spacing={2}>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedCountry}
                      size={"small"}
                      inputProps={{ "aria-label": "Country" }}
                      onChange={handleChangeCountry}
                    >
                      <MenuItem value={0}>Select Country</MenuItem>
                      {totalCountries}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedState}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "State" }}
                      onChange={handleChangeState}
                    >
                      <MenuItem value={0} disabled>
                        Select state
                      </MenuItem>
                      {totalStates}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedCity}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "State" }}
                      onChange={handleChangeCity}
                    >
                      <MenuItem value={0} disabled>
                        Select City
                      </MenuItem>
                      {totalCities}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={tableParentHolder}>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
            <HFPagination details={paginationData} paginate={handlePaginate} />
          </Grid>
          <Grid>
            <SuccessAlert
              alertMsg={alrtMsg}
              open={successAlrtOpen}
              close={closeSuccess}
            />
            <ErrorAlert
              alertMsg={alrtMsg}
              open={errorAlrtOpen}
              close={closeError}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default OrderBreakDown;
