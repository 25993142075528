import {
  Grid,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import * as React from "react";
import { TitleComponent } from "../../components/title_component";
import {
  tableHeadCell,
  tableParentHolder,
  moduleHeadContainer,
} from "../../styles/common_styles";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import { HFPagination } from "../../components/pagination";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import {
  AddModalComponent,
  BlockOrDeleteModalComponent,
} from "../../components/modal_component";
import { ManagementServices } from "../../services/management_services";
import { TableComponent } from "../../components/table_component";
import { Loader } from "../../components/loader_component";
import { CommonServices } from "../../services/common_services";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../assets/icons/delete.svg";
import BlockIcon from "../../assets/icons/block.svg";
import UnBlockIcon from "../../assets/icons/unblock.svg";
import ViewIcon from "../../assets/icons/view.svg";
import {
  TableCellComponent,
  IconTableCellComponent,
} from "../../components/table_cell_component";

export const ConsumerDetails = () => {
  const Navigate = useNavigate();
  const [actionType, setActiontype] = React.useState({
    title: "",
    type: null,
  });
  const [loaderData, setLoaderData] = React.useState(true);
  const [addModal, setAddModal] = React.useState(false);
  const [actionId, setActionId] = React.useState("");
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [consumerDetails, setConsumerDetails] = React.useState([]);
  const [searchData, setSearchData] = React.useState("");

  const addModalCloseModal = () => setAddModal(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const DeleteModalClose = () => setDeleteModal(false);
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, value);
    setSelectedState(null);
    setSelectedCity(null);
    getConsumerDetails();
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    GetCities(3, event.target.value, selectedCountry);
    setSelectedCity(null);
    getConsumerDetails();
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
    getConsumerDetails();
  };

  React.useEffect(() => {
    getConsumerDetails();
  }, [selectedCountry, selectedState, selectedCity]);

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
        callingType: 2,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const getConsumerDetails = async () => {
    try {
      const payload = {
        min: pageConfig.min,
        max: pageConfig.max,
        search: searchData,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
      };
      const ConsumerDetails = await ManagementServices.getConsumerDetails(
        payload
      );
      if (ConsumerDetails.status === 1) {
        const {
          data: { currentPage, pageSize, totalPages, totalUsers, consumers },
        } = ConsumerDetails;
        setPaginationData({
          ...paginationData,
          currentPage: currentPage,
          RecordsForPage: pageSize,
          totalRecords: totalUsers,
          pageCount: totalPages,
        });
        setConsumerDetails(consumers);
        setLoaderData(false);
      } else {
        setAlrtMsg(ConsumerDetails.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const handleBlockOrDelete = async (data) => {
    try {
      //handles No condition
      if (data === 1) {
        DeleteModalClose();
      } else {
        //handles block condition
        if (actionType.type === 1) {
          const blockedConsumer = await ManagementServices.blockConsumer(
            actionId
          );
          if (blockedConsumer.status === 1) {
            setAlrtMsg(blockedConsumer.message);
            setSuccessAlrtOpen(true);
            DeleteModalClose();
            getConsumerDetails();
          } else {
            setAlrtMsg(blockedConsumer.message);
            setErrorAlrtOpen(true);
          }
        } else if (actionType.type === 3) {
          const unblockedConsumer = await ManagementServices.unblockConsumer(
            actionId
          );
          if (unblockedConsumer.status === 1) {
            setAlrtMsg(unblockedConsumer.message);
            setSuccessAlrtOpen(true);
            DeleteModalClose();
            getConsumerDetails();
          } else {
            setAlrtMsg(unblockedConsumer.message);
            setErrorAlrtOpen(true);
          }
        }
        //handles delete condition
        else {
          const deletedConsumer = await ManagementServices.deleteConsumer(
            actionId
          );
          if (deletedConsumer.status) {
            setAlrtMsg(deletedConsumer.message);
            setSuccessAlrtOpen(true);
            DeleteModalClose();
            getConsumerDetails();
          } else {
            setAlrtMsg(deletedConsumer.message);
            setErrorAlrtOpen(true);
          }
        }
      }
    } catch (error) {
      console.log(`Error occured :${error}`);
    }
  };

  const blockOrDeleteHandleOpen = (id, title, type) => {
    setActionId(id);
    setDeleteModal(true);
    setActiontype({ ...actionType, title, type });
  };

  const tableHeaders = [
    "S No",
    "User Id",
    "User Name",
    "First Name",
    "Last Name",
    "Phone",
    "Email",
    "Total Spent",
    "Actions",
  ];
  const iconConfigurations = (data) => [
    {
      condition: true,
      icon: ViewIcon,
      alt: "View",
      onClick: () =>
        Navigate("/Consumer/about", {
          state: { module: 1, userId: data.id, type: 11 },
        }),
    },
    {
      condition: data.block_status === 1,
      icon: UnBlockIcon,
      alt: "Unblock",
      onClick: () => blockOrDeleteHandleOpen(data.id, "Unblock", 3),
    },
    {
      condition: data.block_status === 0,
      icon: BlockIcon,
      alt: "Block",
      onClick: () => blockOrDeleteHandleOpen(data.id, "Block", 1),
    },
    {
      condition: true,
      icon: DeleteIcon,
      alt: "Delete",
      onClick: () => blockOrDeleteHandleOpen(data.id, "Delete", 2),
    },
  ];

  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const TableBody = consumerDetails?.map((data, index) => {
    return (
      <TableRow key={data.id}>
        <TableCellComponent
          value={
            (paginationData.currentPage - 1) * paginationData.RecordsForPage +
            1 +
            index
          }
          indexColoumn={true}
        />
        <TableCellComponent value={data.user_id} />
        <TableCellComponent value={data.user_name} />
        <TableCellComponent value={data.first_name} />
        <TableCellComponent value={data.last_name} />
        <TableCellComponent value={data.mobile} />
        <TableCellComponent value={data.email} />
        <TableCellComponent value={data?.total_spent?.toFixed(2) || "N/A"} />
        <IconTableCellComponent
          data={data}
          iconConfigurations={iconConfigurations}
        />
      </TableRow>
    );
  });

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  React.useEffect(() => {
    getConsumerDetails();
    GetCountries();
  }, []);

  React.useEffect(() => {
    getConsumerDetails();
    GetCountries();
  }, [pageConfig.min, searchData]);

  return (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={moduleHeadContainer}
          >
            <Grid item md={4}>
              <TitleComponent title={"Consumers"} />
            </Grid>
            <Grid item md={8}>
              <Grid container alignItems={"center"} gap={1}>
                <Grid item md={4}>
                  <SearchFieldComponent searchText={handleSearchData} />
                </Grid>
                <Grid item md={2.5}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedCountry !== null ? selectedCountry : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "Country" }}
                      onChange={handleChangeCountry}
                    >
                      <MenuItem value="" disabled>
                        Select country
                      </MenuItem>
                      {totalCountries}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2.5}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedState !== null ? selectedState : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "State" }}
                      onChange={handleChangeState}
                    >
                      <MenuItem value="" disabled>
                        Select state
                      </MenuItem>
                      {totalStates}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2.5}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedCity !== null ? selectedCity : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "City" }}
                      onChange={handleChangeCity}
                    >
                      <MenuItem value="" disabled>
                        Select city
                      </MenuItem>
                      {totalCities}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={tableParentHolder}>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
            <HFPagination details={paginationData} paginate={handlePaginate} />
          </Grid>
          <AddModalComponent
            openhandler={addModal}
            handleClose={addModalCloseModal}
            title="Add"
          />
          <BlockOrDeleteModalComponent
            deleteopen={deleteModal}
            resType={handleBlockOrDelete}
            onClose={DeleteModalClose}
            title={`Do you want to ${actionType.title}?`}
          />
          <SuccessAlert
            alertMsg={alrtMsg}
            open={successAlrtOpen}
            close={closeSuccess}
          />
          <ErrorAlert
            alertMsg={alrtMsg}
            open={errorAlrtOpen}
            close={closeError}
          />
        </>
      )}
    </>
  );
};

export const ChefDetails = () => {
  const Navigate = useNavigate();
  const [actionType, setActiontype] = React.useState({
    title: "",
    type: null,
  });
  const [loaderData, setLoaderData] = React.useState(true);
  const [addModal, setAddModal] = React.useState(false);
  const addModalCloseModal = () => setAddModal(false);
  const [actionId, setActionId] = React.useState("");
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [chefDetails, setChefDetails] = React.useState([]);
  const [searchData, setSearchData] = React.useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const DeleteModalClose = () => setDeleteModal(false);
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, value);
    setSelectedState(null);
    setSelectedCity(null);
    getChefDetails();
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    GetCities(3, event.target.value, selectedCountry);
    setSelectedCity(null);
    getChefDetails();
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  React.useEffect(() => {
    getChefDetails();
  }, [selectedCountry, selectedState, selectedCity]);

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const blockOrDeleteHandleOpen = (id, title, type) => {
    setActionId(id);
    setDeleteModal(true);
    setActiontype({ ...actionType, title, type });
  };
  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const getChefDetails = async () => {
    try {
      const payload = {
        min: pageConfig.min,
        max: pageConfig.max,
        search: searchData,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
      };
      const ConsumerDetails = await ManagementServices.getChefList(payload);
      if (ConsumerDetails.status === 1) {
        const {
          data: { currentPage, pageSize, totalPages, totalUsers, chefs },
        } = ConsumerDetails;
        setPaginationData({
          ...paginationData,
          currentPage: currentPage,
          RecordsForPage: pageSize,
          totalRecords: totalUsers,
          pageCount: totalPages,
        });
        setChefDetails(chefs);
        setLoaderData(false);
      } else {
        setAlrtMsg(ConsumerDetails.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const handleBlockOrDelete = async (data) => {
    try {
      //handles No condition
      if (data === 1) {
        DeleteModalClose();
      } else {
        //handles block condition
        if (actionType.type === 1) {
          const blockedConsumer = await ManagementServices.blockChef(actionId);
          if (blockedConsumer.status === 1) {
            setAlrtMsg(blockedConsumer.message);
            setSuccessAlrtOpen(true);
            DeleteModalClose();
            getChefDetails();
          } else {
            setAlrtMsg(blockedConsumer.message);
            setErrorAlrtOpen(true);
          }
          //handles unblock condition
        } else if (actionType.type === 3) {
          const unblockedConsumer = await ManagementServices.unblockChef(
            actionId
          );
          if (unblockedConsumer.status === 1) {
            setAlrtMsg(unblockedConsumer.message);
            setSuccessAlrtOpen(true);
            DeleteModalClose();
            getChefDetails();
          } else {
            setAlrtMsg(unblockedConsumer.message);
            setErrorAlrtOpen(true);
          }
        }
        //handles delete condition
        else {
          const deletedConsumer = await ManagementServices.deleteChef(actionId);
          if (deletedConsumer.status) {
            setAlrtMsg(deletedConsumer.message);
            setSuccessAlrtOpen(true);
            DeleteModalClose();
            getChefDetails();
          } else {
            setAlrtMsg(deletedConsumer.message);
            setErrorAlrtOpen(true);
          }
        }
      }
    } catch (error) {
      console.log(`Error occured :${error}`);
    }
  };

  const tableHeaders = [
    "S No",
    "User Id",
    "User Name",
    "First Name",
    "Last Name",
    "Phone",
    "Email Id",
    "Total Spent",
    "Actions",
  ];
  const iconConfigurations = (data) => [
    {
      condition: true,
      icon: ViewIcon,
      alt: "View",
      onClick: () =>
        Navigate("/chef/about", {
          state: { module: 2, userId: data.id },
        }),
    },
    {
      condition: data.block_status === 1,
      icon: UnBlockIcon,
      alt: "Unblock",
      onClick: () => blockOrDeleteHandleOpen(data.id, "Unblock", 3),
    },
    {
      condition: data.block_status === 0,
      icon: BlockIcon,
      alt: "Block",
      onClick: () => blockOrDeleteHandleOpen(data.id, "Block", 1),
    },
    {
      condition: true,
      icon: DeleteIcon,
      alt: "Delete",
      onClick: () => blockOrDeleteHandleOpen(data.id, "Delete", 2),
    },
  ];
  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));
  const TableBody = chefDetails?.map((data, index) => {
    return (
      <TableRow key={data.id}>
        <TableCellComponent
          value={
            (paginationData.currentPage - 1) * paginationData.RecordsForPage +
            1 +
            index
          }
          indexColoumn={true}
        />
        <TableCellComponent value={data.user_id} />
        <TableCellComponent value={data.user_name} />
        <TableCellComponent value={data.first_name} />
        <TableCellComponent value={data.last_name} />
        <TableCellComponent value={data.mobile} />
        <TableCellComponent value={data.email} />
        <TableCellComponent value={data?.total_spent?.toFixed(2) || "N/A"} />
        <IconTableCellComponent
          data={data}
          iconConfigurations={iconConfigurations}
        />
      </TableRow>
    );
  });

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  React.useEffect(() => {
    getChefDetails();
    GetCountries();
  }, []);

  React.useEffect(() => {
    getChefDetails();
    GetCountries();
  }, [pageConfig.min, searchData]);

  return (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={moduleHeadContainer}
          >
            <Grid item md={4}>
              <TitleComponent title={"Chefs"} />
            </Grid>
            <Grid item md={8}>
              <Grid container alignItems={"center"} gap={1}>
                <Grid item md={4}>
                  <SearchFieldComponent searchText={handleSearchData} />
                </Grid>
                <Grid item md={2.5}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedCountry !== null ? selectedCountry : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "Country" }}
                      onChange={handleChangeCountry}
                    >
                      <MenuItem value="" disabled>
                        Select country
                      </MenuItem>
                      {totalCountries}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2.5}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedState !== null ? selectedState : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "State" }}
                      onChange={handleChangeState}
                    >
                      <MenuItem value="" disabled>
                        Select state
                      </MenuItem>
                      {totalStates}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2.5}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedCity !== null ? selectedCity : ""}
                      size={"small"}
                      displayEmpty
                      inputProps={{ "aria-label": "City" }}
                      onChange={handleChangeCity}
                    >
                      <MenuItem value="" disabled>
                        Select city
                      </MenuItem>
                      {totalCities}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={tableParentHolder}>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
            <HFPagination details={paginationData} paginate={handlePaginate} />
          </Grid>

          <AddModalComponent
            openhandler={addModal}
            handleClose={addModalCloseModal}
            title="Add"
          />
          <BlockOrDeleteModalComponent
            deleteopen={deleteModal}
            resType={handleBlockOrDelete}
            onClose={DeleteModalClose}
            title={`Do you want to ${actionType.title}?`}
          />
          <SuccessAlert
            alertMsg={alrtMsg}
            open={successAlrtOpen}
            close={closeSuccess}
          />
          <ErrorAlert
            alertMsg={alrtMsg}
            open={errorAlrtOpen}
            close={closeError}
          />
        </>
      )}
    </>
  );
};
