import React from "react";
import { Grid, ListItemText, Checkbox, IconButton } from "@mui/material";
import "../../styles/common_styles.css";
import { ManagementServices } from "../../services/management_services";
import { MenuItem, FormControl, Select } from "@mui/material";
import { CommonServices } from "../../services/common_services";
import MonthlyRevenueChart from "../../components/chart_components/monthRevenue";
// import { TableCellComponent } from "../../components/table_cell_component";
// import { tableHeadCell } from "../../styles/common_styles";
import tableIcon from "../../assets/icons/table_icon.svg";
import graphIcon from "../../assets/icons/graph_icon.svg";
import { ButtonComponent } from "../../components/form_components/button";
// import { TableRow } from "@mui/material";
import { ConsumerTableComponent } from "../../components/consumer_table_component";

export const Graphdshboard = () => {
  const [countries, setCountries] = React.useState([]);
  const [revenueData, setRevenueData] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [selectedCountries, setSelectedCountries] = React.useState([]);
  const [selectedStates, setSelectedStates] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState(
    new Date().getFullYear()
  );
  // const [loaderData, setLoaderData] = React.useState(true);
  const [tableStatus, setTableStatus] = React.useState(false);
  const [graphStatus, setGraphStatus] = React.useState(true);

  const getdashboardRevenueDetails = async () => {
    const payload = {
      year: selectedYear,
      countries: selectedCountries,
      states: selectedStates,
      cities: selectedCities,
    };
    const revenueresponse = await ManagementServices.getRevenueData(payload);
    if (revenueresponse?.status === 1) {
      // setLoaderData(false);
      setRevenueData(revenueresponse.data);
    } else {
      setRevenueData(revenueresponse.data);
    }
  };
  const handleYearChange = (event) => {
    const year = parseInt(event.target.value);
    setSelectedYear(year);
    getdashboardRevenueDetails();
  };

  const handleChangeCountry = (event) => {
    setSelectedCountries(event.target.value);
    GetStates(2, event.target.value);
  };

  const handleChangeState = (event) => {
    setSelectedStates(event.target.value);
    GetCities(3, event.target.value, selectedCountries);
  };

  const handleChangeCity = (event) => {
    setSelectedCities(event.target.value);
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const TableHeader = [
    "Country",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // const TableHeaderRow = TableHeader.map((row, index) => (
  //   <TableCell key={index} sx={tableHeadCell}>
  //     {row}
  //   </TableCell>
  // ));
  const countryNames = revenueData && Object.keys(revenueData);

  // const TableBody = countryNames?.map((countryName, index) => (
  //   <TableRow key={index}>
  //     <TableCellComponent value={countryName} />
  //     {revenueData[countryName]?.revenue?.map((revenue, monthIndex) => (
  //       <TableCellComponent key={monthIndex} value={revenue} />
  //     ))}
  //   </TableRow>
  // ));

  const handleTableVisibleStatus = () => {
    setTableStatus((tableStatus) => !tableStatus);
    setGraphStatus((graphStatus) => !graphStatus);
  };
  const handleGraphVisibleStatus = () => {
    setGraphStatus((graphStatus) => !graphStatus);
    setTableStatus((tableStatus) => !tableStatus);
  };

  const handleExportData = async () => {
    const payload = {
      year: selectedYear,
      countries: selectedCountries,
      states: selectedStates,
      cities: selectedCities,
    };
    try {
      const revenueTableResponse =
        await ManagementServices.getExportPdfRevenueData(payload);
      if (revenueTableResponse && revenueTableResponse.size > 0) {
        const blobUrl = URL.createObjectURL(revenueTableResponse);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "RevenueTableData.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error("Failed to fetch data or empty response");
      }
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  };

  React.useEffect(() => {
    GetCountries();
    getdashboardRevenueDetails();
  }, [selectedCountries, selectedStates, selectedCities, selectedYear]);

  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      mt={3}
    >
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Grid item md={2} mr={1}>
          <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
            <Select
              multiple
              size="small"
              value={selectedCountries}
              onChange={handleChangeCountry}
              renderValue={(selected) => {
                const selectedCountryNames = countries
                  .filter((country) => selected.includes(country.id))
                  .map((country) => country.name);
                return selectedCountryNames.join(", ") || "Select countries";
              }}
            >
              <MenuItem value="">select countries</MenuItem>
              {countries.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                  <Checkbox checked={selectedCountries.indexOf(name.id) > -1} />
                  <ListItemText primary={name.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={2} mr={1}>
          <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
            <Select
              multiple
              size="small"
              value={selectedStates}
              onChange={handleChangeState}
              renderValue={(selected) => {
                const selectedStateNames = states
                  .filter((state) => selected.includes(state.id))
                  .map((state) => state.name);
                return selectedStateNames.join(", ") || "Select states";
              }}
            >
              <MenuItem value="">select states</MenuItem>
              {states?.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                  <Checkbox checked={selectedStates.indexOf(name.id) > -1} />
                  <ListItemText primary={name.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={2} mr={1}>
          <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
            <Select
              multiple
              size="small"
              value={selectedCities}
              onChange={handleChangeCity}
              renderValue={(selected) => {
                const selectedCountryNames = cities
                  .filter((country) => selected.includes(country.id))
                  .map((country) => country.name);
                return selectedCountryNames.join(", ") || "Select cities";
              }}
            >
              <MenuItem value="">select cities</MenuItem>
              {cities?.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                  <Checkbox checked={selectedCities.indexOf(name.id) > -1} />
                  <ListItemText primary={name.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={1.8}>
          <FormControl sx={{ m: 1 }} fullWidth>
            <Select
              id="year"
              value={selectedYear}
              onChange={handleYearChange}
              size="small"
              sx={{ width: "100px" }}
            >
              {Array.from(
                { length: 50 },
                (_, index) => selectedYear - 25 + index
              ).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={0.5}>
          <IconButton
            onClick={() => handleTableVisibleStatus()}
            sx={tableStatus && { backgroundColor: "lightgreen" }}
          >
            <img src={tableIcon} alt="edit" style={{ height: "40px" }} />
          </IconButton>
        </Grid>
        <Grid item md={1}>
          <IconButton
            onClick={() => handleGraphVisibleStatus()}
            sx={graphStatus && { backgroundColor: "lightgreen" }}
          >
            <img
              src={graphIcon}
              alt="edit"
              style={{ height: "40px", color: "yellow" }}
            />
          </IconButton>
        </Grid>
      </Grid>

      {graphStatus && (
        <Grid sx={{ display: "flex", justifyContent: "center" }} mt={5}>
          <MonthlyRevenueChart countryData={revenueData} className="chart" />
        </Grid>
      )}
      <Grid m={4}>
        {tableStatus && (
          <>
            <ConsumerTableComponent
              headers={TableHeader}
              data={revenueData}
              countryNames={countryNames}
            />
          </>
        )}
      </Grid>
      {revenueData && (
        <Grid sx={{ display: "flex", justifyContent: "end" }} mr={3} mb={2}>
          <ButtonComponent
            btnText={"Export"}
            type={"button"}
            onClick={() => handleExportData()}
          />
        </Grid>
      )}
    </Grid>
  );
};
