import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LinearScale, PointElement, LineElement, CategoryScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LinearScale, PointElement, LineElement, CategoryScale, Title, Tooltip, Legend);

const LineChart = ({ data }) => {
  const options = {
    scales: {
      x: {
        type: 'category',
        position: 'bottom',
        grid: { display: false }
      },
      y: {
        type: 'linear',
        position: 'left',
        grid: { display: false }
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;

