import React from "react";
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import LineChart from "./chart";
import { Grid } from "@mui/material";

const MonthlyRevenueChart = ({ countryData }) => {
  const combineObjects = (inputObject) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const countries = Object?.entries(inputObject)?.map(
      ([country, { months, revenue }]) => ({
        name: country,
        revenue,
      })
    );

    return { months, countries };
  };

  const combinedObject = countryData && combineObjects(countryData);

  function transformCountryData(countryData) {
    if (!countryData) {
      return [];
    }

    const allMonths = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Create an object to store data for each country
    const transformedData = Object.entries(countryData).map(
      ([country, { months, revenue }]) => {
        const countryDataObj = {};
        months.forEach((month, index) => {
          // if(index===11) return
          countryDataObj[month] = {
            month: month,
            revenue: revenue[index] || 0, // Default revenue to 0 if data is missing
          };
        });
        return {
          name: country,
          data: Object.values(countryDataObj), // Extract values to get an array of data points
        };
      }
    );

    return transformedData;
  }

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const data1 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: combinedObject?.countries.map((country) => ({
      label: country.name,
      data: country.revenue,
      fill: false,
      borderColor: getRandomColor(),
      tension: 0.4,
    })),
  };

  const data = countryData && transformCountryData(countryData);

  return (
    <div style={{ width: "1000px" }}>
      {countryData ? (
        <LineChart data={data1} grid={false} />
      ) : (
        <Grid sx={{ textAlign: "center", marginTop: "20px", fontWeight: 600 }}>
          No Data
        </Grid>
      )}
    </div>
  );
};

export default MonthlyRevenueChart;
