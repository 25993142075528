import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  PriceTableContainer,
  PricetableHeaderCell,
  PricetableRowCell,
} from "../styles/common_styles";
import { makeStyles } from "@mui/styles";

const PricingTable = ({
  ColumnData,
  Rowdata,
  check,
  pricingData,
  headerKeyMapping,
  onUpdatePricingData,
}) => {
  const useStyles = makeStyles((theme) => ({
    priceTable: {
      "& .MuiTableBody-root": {
        textAlign: "center !important",
      },
    },
  }));

  const classes = useStyles();

  const handleChange = (e, rowIndex, header) => {
    const newValue = e.target.value;
    const key = headerKeyMapping[header]; // Get the corresponding key from the mapping object
    const updatedPricingData = { ...pricingData }; // Make a copy of the pricing data object
    updatedPricingData[key] = newValue; // Update the value corresponding to the header key
    onUpdatePricingData(updatedPricingData);
  };

  const isDisabled = (header) => {
    const mappedHeader = headerKeyMapping[header];
    return (
      !pricingData.hasOwnProperty(mappedHeader) &&
      pricingData[mappedHeader] !== 0
    );
  };

  return (
    <TableContainer component={Paper} sx={PriceTableContainer}>
      <Table className={classes.priceTable}>
        <TableHead>
          <TableRow>
            {ColumnData.map((header, index) => (
              <TableCell key={index} sx={PricetableHeaderCell}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Rowdata.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {ColumnData.map((header, cellIndex) =>
                check ? (
                  <TableCell key={cellIndex} sx={PricetableRowCell}>
                    {row[header]}
                  </TableCell>
                ) : (
                  <TableCell>
                    <input
                      style={{
                        height: "25px",
                        width: "40px",
                        borderRadius: "10px",
                        border: isDisabled(header) ? "none" : "1px solid grey",
                        textAlign: "center",
                      }}
                      defaultValue={row[header]}
                      onChange={(e) => handleChange(e, cellIndex, header)}
                      disabled={isDisabled(header)}
                    />
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingTable;
