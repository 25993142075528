import React from "react";
import { Grid, Typography, TextField, Tab } from "@mui/material";
import { TitleComponent } from "../../components/title_component";
import { ButtonComponent } from "../../components/form_components/button";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { Loader } from "../../components/loader_component";
import { TabList, TabContext } from "@mui/lab";
import { tabStyle, tabItem } from "../../styles/common_styles";

const DynamicText = () => {
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [saveEnabled, setSaveEnabled] = React.useState(true);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [loaderData, setLoaderData] = React.useState(true);
  const [value, setValue] = React.useState("1");
  const [dynamicTextDetails, setDynamicTextDetails] = React.useState([]);
  const [dynamicTextValues, setDynamicTextValues] = React.useState({});
  const [changeVlaue, setChangeValue] = React.useState({
    id: null,
    text: "",
    type: null,
    appType: null,
  });

  const handleApiDynamicText = async () => {
    try {
      if (changeVlaue.text.length > 150) {
        setAlrtMsg("Text length should be less than 150 characters");
        setErrorAlrtOpen(true);
        return;
      }
      setLoaderData(true);
      const dynamicTextResponse = await ManagementServices.dynamixTextDetails(
        changeVlaue
      );
      if (dynamicTextResponse.status === 1) {
        setAlrtMsg(dynamicTextResponse.message);
        setSuccessAlrtOpen(true);
        setLoaderData(false);
        setSaveEnabled(false);
      } else {
        setAlrtMsg(dynamicTextResponse.message);
        setErrorAlrtOpen(true);
        setLoaderData(false);
        setSaveEnabled(false);
      }
    } catch (error) {
      console.error("Error fetching dynamic text details:", error);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setLoaderData(true);
    setValue(newValue);
    setTimeout(() => {
      setLoaderData(false);
    }, 700);
  };

  const handleApiDynamicTextDetails = async () => {
    const payload = {
      type: "get",
      appType: value,
    };
    const dynamicTextResponseDetails =
      await ManagementServices.dynamixTextDetails(payload);
    if (dynamicTextResponseDetails?.status === 1) {
      setDynamicTextDetails(dynamicTextResponseDetails.data);
      setLoaderData(false);
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  React.useEffect(() => {
    if (dynamicTextDetails.length > 0) {
      const initialValues = dynamicTextDetails.reduce((acc, item) => {
        acc[item.id] = item.text;
        return acc;
      }, {});
      setDynamicTextValues(initialValues);
    }
  }, [dynamicTextDetails]);

  const typeHeadings = {
    1: "Launch Screen",
    2: "Onboarding Screen",
    3: "Kitchen Details Screen",
    4: "Order Caution Screen",
    5: "Allergy Caution",
    6: "Consumer Onboarding Not Completed",
    7: "Consumer Logout",
  };

  const handleDynamicTextChange = (e, id) => {
    setDynamicTextValues({
      ...dynamicTextValues,
      [id]: e.target.value,
    });
    setChangeValue({
      id: id,
      text: e.target.value,
      type: "edit",
      appType: value,
    });
  };

  React.useEffect(() => {
    handleApiDynamicTextDetails();
  }, [value]);

  return (
    <>
      <Grid container sx={{ padding: 3 }}>
        <Grid
          item
          md={12}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          height={"10vh"}
        >
          <Grid item container>
            <TitleComponent title={"Dynamic Text"} />
            <Grid item md={4} justifyContent={"center"} ml={15}>
              <TabContext value={value}>
                <TabList
                  sx={tabStyle}
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Consumer" value="1" sx={tabItem} />
                  <Tab label="Chef" value="2" sx={tabItem} />
                </TabList>
              </TabContext>
            </Grid>
          </Grid>
        </Grid>
        {loaderData ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "70vh" }} // Ensures the loader container takes up 70% of the viewport height
          >
            <Loader />
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{ height: "70vh", overflow: "scroll" }}
          >
            {dynamicTextDetails.map((item) => (
              <React.Fragment key={item.id}>
                <Grid item md={9}>
                  <Typography
                    variant="h6"
                    mt={2}
                    sx={{ fontWeight: "bold" }}
                    mb={1}
                  >
                    {typeHeadings[item.category] && typeHeadings[item.category]}
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter Your Text....."
                    value={
                      (dynamicTextValues[item.id] &&
                        dynamicTextValues[item.id]) ||
                      null
                    }
                    onChange={(e) => handleDynamicTextChange(e, item.id)}
                  />
                </Grid>
                <Grid mt={7} item ml={3}>
                  <ButtonComponent
                    btnText={"Save"}
                    onClick={handleApiDynamicText}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}
        <SuccessAlert
          alertMsg={alrtMsg}
          open={successAlrtOpen}
          close={closeSuccess}
        />
        <ErrorAlert
          alertMsg={alrtMsg}
          open={errorAlrtOpen}
          close={closeError}
        />
      </Grid>
    </>
  );
};

export default DynamicText;
