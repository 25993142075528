import axios from "axios";
import { ApiConfig } from "../config/config";
import { StorageClient } from "../utils/helpers";

const attachTokenInterceptor = (config) => {
  const token = config.useAccessToken // will check if api request for access token or not
    ? StorageClient.getItem("accessToken") || ApiConfig.defaultAuth
    : ApiConfig.defaultAuth;

  // Attaching the token to the headers
  config.headers.Authorization = `${token}`;

  return config;
};

const refreshTokenInterceptor = async (error) => {
  const refreshToken = StorageClient.getItem("refreshToken");
  const BaseUrl = process.env.REACT_APP_BASE_URL;

  // Check if the error is due to an expired token
  if (error.response && error.response.status === 401) {
    // Attempt to refresh the access token
    try {
      const response = await axios.post(`${BaseUrl}regenerateAccessKey`, {
        refreshToken,
      });

      const newAccessToken = response.data.data;

      // Save the new access token to storage or state
      if (response.data.status === 1) {
        StorageClient.setItem("accessToken", newAccessToken);
        const rolePrivileges = await StorageClient.getItem("rolePrivileges");
        //navigating to dashboard
        window.location.replace(`${rolePrivileges[0].path}`);
      } else {
        // Handle the case when refresh is unsuccessful
        StorageClient.clear();
        alert("Your session was expired try again from login");
        window.location.replace(`/login`);
      }
    } catch (refreshError) {
      // Handle the refresh token error (e.g., redirect to login page, clear user session)
      throw refreshError;
    }
  }

  // Handle other errors
  return Promise.reject(error);
};

export { attachTokenInterceptor, refreshTokenInterceptor };
