import { Routes, Route } from "react-router-dom";
import Login from "../pages/common_pages/login";
import ForgotPassword from "../pages/common_pages/forgot_password";
import ResetPassword from "../pages/common_pages/reset_password";
import { Homepage } from "../pages/Informative_site/home_page";
import { DeleteUser } from "../pages/Informative_site/delete_user";

const CommonRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/informative" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/deleteUser" element={<DeleteUser />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route
          path="/resetPassword/:email/:token"
          element={<ResetPassword />}
        />
      </Routes>
    </>
  );
};

export default CommonRoutes;
