import { Outlet } from "react-router-dom";

const Chef = () => {

  return (
    <Outlet />
  )
};

export default Chef;
