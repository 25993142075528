import React from "react";
import { MenuItem, FormControl, Select } from "@mui/material";

export const TableFilter = ({ filterChange }) => {
  const [filter, setFilter] = React.useState(0);

  const handleChange = (event) => {
    setFilter(event.target.value);
    const selectedValue = event.target.value;
    if (selectedValue === 1) {
      filterChange({
        isPending: 0,
        blocked: false,
        edited: false,
        deleted: false,
        unBlocked: false,
      });
    } else if (selectedValue === 2) {
      filterChange({
        deleted: true,
        isPending: null,
        blocked: false,
        edited: false,
        unBlocked: false,
      });
    } else if (selectedValue === 3) {
      filterChange({
        blocked: true,
        deleted: false,
        isPending: null,
        edited: false,
        unBlocked: false,
      });
    } else if (selectedValue === 4) {
      filterChange({
        edited: true,
        blocked: false,
        deleted: false,
        isPending: null,
        unBlocked: false,
      });
    } else if (selectedValue === 0) {
      filterChange({
        edited: false,
        blocked: false,
        deleted: false,
        isPending: null,
        unBlocked: false,
      });
    } else if (selectedValue === 5) {
      filterChange({
        unBlocked: true,
        edited: false,
        blocked: false,
        deleted: false,
        isPending: null,
      });
    }
  };

  return (
    <>
      <FormControl>
        <Select
          className={"FilterStyle"}
          value={filter}
          size={"small"}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Select Role" }}
        >
          <MenuItem value={0}>None</MenuItem>
          <MenuItem value={1}>Pending</MenuItem>
          <MenuItem value={2}>Deleted</MenuItem>
          <MenuItem value={3}>Blocked</MenuItem>
          <MenuItem value={4}>Edited</MenuItem>
          <MenuItem value={5}>Un blocked</MenuItem>
          
        </Select>
      </FormControl>
    </>
  );
};
