import React from "react";
import {
  Grid,
  Box,
  Typography,
  Modal,
  FormControl,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { MessagemodalStyle, Errors } from "../styles/common_styles";
import { Emaillable, CustomField } from "../styles/loginstyles";
import { ButtonComponent } from "./form_components/button";
import { useForm } from "react-hook-form";
import { ManagementServices } from "../services/management_services";
import { SuccessAlert } from "./alert";
import { ErrorAlert } from "./alert";
export const MessageModalComponent = ({
  open,
  handleClose,
  formModule,
  iconValue,
}) => {
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };
  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const onSubmitData = async (data) => {
    try {
      const payload = {
        userId: iconValue,
        message: data.message,
        title: data.title,
      };
      if (formModule === "1") {
        const ConsumerMessage =
          await ManagementServices.IncompleteOnboardingMessageToConsumer(
            payload
          );
        if (ConsumerMessage.status === 1) {
          setAlrtMsg(ConsumerMessage.message);
          setSuccessAlrtOpen(true);
          reset();
          handleClose();
        } else {
          setAlrtMsg(ConsumerMessage.message);
          setErrorAlrtOpen(true);
        }
      } else {
        const chefMessage =
          await ManagementServices.IncompleteOnboardingMessageToChef(payload);
        if (chefMessage.status === 1) {
          setAlrtMsg(chefMessage.message);
          setSuccessAlrtOpen(true);
          reset();
          handleClose();
        } else {
          setAlrtMsg(chefMessage.message);
          setErrorAlrtOpen(true);
        }
      }
    } catch (error) {
      console.log(`Error occured :${error}`);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MessagemodalStyle}>
          <Grid container flexDirection={"column"} justifyContent={"center"}>
            <Grid component={"form"} onSubmit={handleSubmit(onSubmitData)}>
              <Grid item>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Title</Grid>
                  <TextField
                    sx={CustomField}
                    fullWidth
                    displayEmpty
                    size={"small"}
                    type="text"
                    inputProps={{
                      placeholder: "Please enter title",
                    }}
                    {...register("title", {
                      required: "Please enter title",
                    })}
                  />
                  {errors.title && (
                    <Typography sx={Errors}> Please enter title</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Message</Grid>
                  <TextareaAutosize
                    className={"textArea"}
                    aria-label="empty textarea"
                    placeholder="Text here"
                    minRows={6}
                    inputProps={{
                      placeholder: "Please enter message",
                    }}
                    {...register("message", {
                      required: "Please enter Message",
                    })}
                  />
                  {errors.message && (
                    <Typography sx={Errors}>Please enter message.</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item>
                <Grid container justifyContent={"center"} mt={5}>
                  <ButtonComponent btnText={"Submit"} type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};
