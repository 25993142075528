import React, { useEffect } from "react";
import {
  Grid,
  FormControl,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { ManagementServices } from "../services/management_services";
import { addButton, rewardFieldSet } from "../styles/common_styles";
import { SuccessAlert, ErrorAlert } from "./alert";
import { BlockOrDeleteModalComponent } from "./modal_component";
import { CommonServices } from "../services/common_services";

export const RewardListComponent = ({ item, revertFunction, toggleButton, cancelToggle }) => {
  
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [handleEditState, setHandleEditState] = React.useState(true);
  const [consumerPercentage, setConsumerPercentage] = React.useState(0);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState("");
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [selectedState, setSelectedState] = React.useState(0);
  const [selectedCity, setSelectedCity] = React.useState(0);
  const [selectedArea, setSelectedArea] = React.useState("");
  const [countryList, setCountryList] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [areaList, setAreaList] = React.useState([]);

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const getCountry = async () => {
    // API call to get country list
    const country = await CommonServices.getLocation({ type: 1 });
    if (country.status === 1) {
      setCountryList(country.data);
    }
  };

  const getState = async () => {
    if (!handleEditState) {
      // API call to get state list
      const state = await CommonServices.getLocation({
        country: selectedCountry,
      });
      if (state.status === 1) {
        // Filter out the previously selected state from the state list
        const filteredStates = state.data.filter(stateItem => stateItem.id !== selectedState);
        setStateList(filteredStates);
      }
    }
  };

  const getCity = async () => {
    if (handleEditState === false) {
      // API call to get city list
      const city = await CommonServices.getLocation({
        country: selectedCountry,
        state: selectedState,
      });
      if (city.status === 1) {
        const filteredCities = city.data.filter(cityItem => cityItem.id !== selectedCity);
        setCityList(filteredCities);
      }
    }
  };

  const getArea = async () => {
    if (handleEditState === false) {
      // API call to get area list
      const area = await CommonServices.getLocation({
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
      });
      if (area.status === 1) {
        setAreaList(area.data);
      }
    }
  };

  const handleDelete = async (id) => {
    // API call to delete rewards
    setDeleteModal(true);
    setDeleteId(id);
  };

  const DeleteModalClose = () => {
    setDeleteModal(false);
    setDeleteId("");
  };
const handleChangeCountry =(event)=>{
    const selectedCountryValue = event.target.value;
    setSelectedCountry(selectedCountryValue);
    setSelectedState(0); // Reset state when the country changes
    setSelectedCity(0); // Reset city when the country changes
    setSelectedArea(""); // Reset area when the country changes
    item.stateid = undefined;
    // item.state.name = undefined;
    item.cityid = undefined;
    if (item.state && item.state.name !== undefined) {
      item.state.name = undefined;
    }
    
    // Similar check for other properties
    if (item.city && item.city.name !== undefined) {
      item.city.name = undefined;
    }
    // item.city.name = undefined;
    item.area_code = undefined;
  }

  const handleChangeState = (event) => {
    const selectedStateValue = event.target.value;
    setSelectedState(selectedStateValue);
    setSelectedCity(0); // Reset city when the state changes
    setSelectedArea(""); // Reset area when the state changes
    item.cityid = undefined; // Reset the city id in the item
    if (item.city && item.city.name !== undefined) {
      item.city.name = undefined; // Reset the city name in the item
    }
    item.area_code = undefined; // Reset the area code in the item
  };

  const handleChangecity = (event) => {
    const selectedCityValue = event.target.value;
    setSelectedCity(selectedCityValue);
    setSelectedArea(""); // Reset area when the city changes
    item.area_code = undefined; // Reset the area code in the item
  };
  const handleRes = async (data) => {
    try {
      if (data === 1) {
        DeleteModalClose();
      } else {
        const deleteRewards = await ManagementServices.DeleteRewards(deleteId);
        if (deleteRewards.status === 1) {
          setAlrtMsg(deleteRewards.message);
          setSuccessAlrtOpen(true);
          DeleteModalClose();
          revertFunction();
        } else {
          setAlrtMsg(deleteRewards.message);
          setErrorAlrtOpen(true);
        }
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const handleConsumerChange = (e, index) => {
    const { value } = e.target;
    setConsumerPercentage(value);
  };

  // Function to toggle edit mode for an item
  const toggleEditMode = async () => {
    toggleButton(true);
    setHandleEditState(!handleEditState);
    if (item.state===null) {
     setCityList([])
    }
  };

 useEffect(() => {
   if (!handleEditState) {
     getCountry();
     getState();
    //  getCity();
    //  getArea();
     if((item.state !== null || item.state!=="") && selectedState !== 0 ){
      getCity();
     }
     if((item.city !== null || item.city!=="") && selectedCity !== 0 ){
      getArea()
     }
   }
 }, [handleEditState]);

  const handleUpdateRewards = async () => {
    toggleButton(true);
    cancelToggle();

    const updateRewards = await ManagementServices.updateRewards({
      id: item.id,
      consumerPercentage: consumerPercentage,
      countryId: selectedCountry,
      stateId: selectedState,
      cityId: selectedCity,
      areaCode: selectedArea,
    });
    if (updateRewards.status === 1) {
      setAlrtMsg(updateRewards.message);
      setSuccessAlrtOpen(true);
      setHandleEditState(!handleEditState);
      revertFunction();
    } else {
      setAlrtMsg(updateRewards.message);
      setErrorAlrtOpen(true);
    }
  };

  useEffect(() => {
    
    setConsumerPercentage(item.consumer_percentage);
    setSelectedCountry(item.countryid);
    setSelectedState(item.stateid);
    setSelectedCity(item.cityid);
    setSelectedArea(item.area_code);
  }, []);

  useEffect(() => {
    getState();
  }, [selectedCountry]);

  // Inside useEffect for state
  useEffect(() => {
    if(selectedState!==0){
      getCity();
    }else{
      setCityList([]);
    }
  }, [selectedState]);

  // Inside useEffect for city
  useEffect(() => {
    if(selectedCity!==0){
      getArea();
    }else{
      setAreaList([]);
    }
  }, [selectedCity]);

  // Rendering dropdown menus
  const countries = countryList?.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  ));
  const states = stateList?.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  ));
  
  const cities = cityList?.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  ));
  const areas = areaList?.map((item) => (
    <MenuItem key={item.area_code} value={item.area_code}>
      {item.area_code}
    </MenuItem>
  ));

  console.log(areaList,'area')

  return (
    <>
      <Grid key={item.id} container mt={3}>
        <Grid item md={2.2}>
          <Grid container justifyContent={"space-around"}>
            <Grid item md={5}>
              <FormControl fullWidth>
                <Grid mb={1}>Admin%</Grid>
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  value={"100"}
                  sx={{
                    ...rewardFieldSet,
                  }}
                  inputProps={{
                    placeholder: "Name",
                    style: { textAlign: "center" },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={5}>
              <FormControl fullWidth>
                <Grid mb={1}>Consumer%</Grid>
                <TextField
                  fullWidth
                  disabled={handleEditState}
                  type="number"
                  size="small"
                  value={consumerPercentage}
                  onChange={(e) => handleConsumerChange(e)}
                  sx={{
                    ...rewardFieldSet,
                  }}
                  inputProps={{
                    placeholder: "Name",
                    style: { textAlign: "center" },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={7.3}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Grid mb={1}>Country</Grid>
                <Select
                  className={"FilterStyle"}
                  disabled={handleEditState}
                  value={selectedCountry}
                  size={"small"}
                  displayEmpty
                  // onChange={(e) => setSelectedCountry(e.target.value)}
                  onChange={handleChangeCountry}
                  inputProps={{ "aria-label": "Select country" }}
                >
                  <MenuItem value={0}>select country</MenuItem>
                  <MenuItem value={item.countryid !== 0 && item.countryid}>
                    {item.country?.name}
                  </MenuItem>
                  {countries}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Grid mb={1}>State</Grid>
                <Select
                  className={"FilterStyle"}
                  disabled={handleEditState}
                  value={selectedState}
                  size={"small"}
                  displayEmpty
                  onChange={handleChangeState}
                  inputProps={{ "aria-label": "Select state" }}
                >
                  <MenuItem value={0}>select state</MenuItem>
                  <MenuItem value={item.stateid !== 0 && item.stateid}>
                    {item.state?.name || ""} 
                  </MenuItem>
                  {states}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Grid mb={1}>City</Grid>
                <Select
                  className={"FilterStyle"}
                  disabled={handleEditState}
                  value={selectedCity}
                  size={"small"}
                  onChange={handleChangecity}
                  displayEmpty
                  inputProps={{ "aria-label": "Select city" }}
                >
                  <MenuItem value={0}>select city</MenuItem>
                  <MenuItem value={item.cityid !== 0 && item.cityid}>
                    {item.city?.name || ""}
                  </MenuItem>
                  {cities || ""}

                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Grid mb={1}>Area</Grid>
                <Select
                  className={"FilterStyle"}
                  disabled={handleEditState}
                  value={selectedArea}
                  size={"small"}
                  onChange={(e) => setSelectedArea(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Select Area" }}
                >
                  <MenuItem value={""}>Select Area</MenuItem>
                  <MenuItem value={item.area_code}>{item.area_code}</MenuItem>
                  {areas}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2.5}>
          <Grid container mt={4} justifyContent={"space-around"}>
            <Grid item>
              {handleEditState ? (
                <Button sx={addButton} onClick={() => toggleEditMode()}>
                  Edit
                </Button>
              ) : (
                <Button sx={addButton} onClick={() => handleUpdateRewards()}>
                  Update
                </Button>
              )}
            </Grid>
            <Grid item>
              {handleEditState ? (<Button sx={addButton} onClick={() => handleDelete(item.id)}>
                Delete
              </Button>): (
                <Button sx={addButton} onClick={() =>{cancelToggle(); setHandleEditState(true)}}>
                  cancel
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BlockOrDeleteModalComponent
        deleteopen={deleteModal}
        resType={handleRes}
        onClose={DeleteModalClose}
        title={"Do you want to Delete ?"}
      />
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};
