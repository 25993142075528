import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  "type": "service_account",
  "projectId": "home-food-consumer",
  "project_id": "home-food-consumer",
  "private_key_id": "28ad947253f4d76d9bbbd2dbe2fc0b0a03d6d1c7",
  "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCZN+p18sV+yIvW\nMPka7p9g81QCgBOVkYYGq8aMbmtjlqpvCaz2dFJKotx/vEEfX+9h6qZioMLZzmkB\nYo8fvtzbkyl0K5XOmMc2BoUjhd040Jjo/OAiSS2bZDUSWbO3As+c9QvC/I6/D8Kx\njsRVPcAp91JAbJKGcbIf0FWWKrIQs1pDcfqngZKEtIXDcguoin6chdfMM/mzbOz/\n3OgYcCUwcL1VQRfY00qykvvQFOOdqAxVqVzsGKFxrJSBgxCsb2q735P38ncnCQui\n/mPUR963KdTyHL+jc91HEi/jNDBnReLz1RaZKzY/byJE8Ype3JwCGvNXnU8g8+pN\n7uoArDlBAgMBAAECggEACYcJ2BRE1iWr1mGct+FDPWDcJjynGxuduS/4zHY4TMzL\nY9S28etZS6VVb0b6SLRrOww/lO34t9kHPAwQKNHyQ2AjCYOFUP+8kX41zLfwAe6s\nt340BMXGpX1FdJLp4zU0SkcNmG4ihTx6Z5agXEmW97UWowVtVhlfUW2fyNL2WCXq\nSKfDelyOrfv/4u1j5T7ZbwiQz66AbbLFove+7YgZQFJmDdg1scCKLqDMFRf0EUkY\neMRvlO4XLh1A/YLTpOXYNILknz0zsC54xO1uqDOeTv9mVloj6cYGlIq1K3uadpdx\ngZiXSFoVZ8yAwDbmJ2UycmlePjzZuIpAuKdyoqWTnQKBgQDR24jVpOru1v5JUYOf\nSeDUT+uWYskTz16F0vjegiSG+Sd30getmdmgq8eCyX6UrUrVWBe+z7zhgcI6Xqzc\n+stp30WaoHPr1DhZQ5U+TUXzPCXxK6/VdIN1UExauA+22On55CgHcUHV5pqWFsMs\nrwYTlmCNMvwqJlATXusv35C7RQKBgQC66EbcanDB5DoRe2JBbMwUD6Mzf2prgiQ2\nGWinPlEYy4cftk9yMeT0OFQKxVkhBP1ajeJBGeHH/xcWettHzB6GG7Y/4NuBC1l0\nhM5rg6Ehy5kP35XnGFAKVEJ3aoJIExGDArKsYLkxvkWR9jNeR+YfKXtyBlhfQ/tg\nRp6iZM/nzQKBgGFcTaZ4xbobKoxPchZDizhU/8KcgBddvMevcmCg1lt8WnA/k+pZ\nl1rwdzZZTw2hPDewkAupi0LAFaFI7T/0Zszulfc/+6fspFvde+8UoKBRRnUWRJRu\nYtLZdyMbg8bb6elSjbOuOtIHE68QTmp7pQEPYin//nd7oOjrv4dNXHNVAoGAa8/O\nCBhRwFXQdQMR0yFZunymq+7kSO1By/7uEgJtLvs/jzhPLVTAd2sCd1AMsHI7Xf+X\nseunBAENpUbqs8h3Fu5nOwOOOip8yCxPEU9VI7RYLwLOagXpLXwa9tz2F3MAV+y3\neZ01Vg8l/CLi0JBymikIbuZgFVuWZZt2p7+0+UUCgYAB+ct8r8TAsBVaRjAgy6aa\nbZz4etqwJ7mUUi8naFZoqo89DbLsP/u0z170h8TIYic44Yzax4vSK4/4oOwPhdaO\n1HdmVM9fO1nbhNkpUi1QJK8uxXBfCNcGlYF7pUiCxOBjcAU8/RnhlDv7oRmKo0/v\n17CJxvwZqnUZAA7SvznUfA==\n-----END PRIVATE KEY-----\n",
  "client_email": "firebase-adminsdk-dghjz@home-food-consumer.iam.gserviceaccount.com",
  "client_id": "106975311202488784267",
  "auth_uri": "https://accounts.google.com/o/oauth2/auth",
  "token_uri": "https://oauth2.googleapis.com/token",
  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
  "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-dghjz%40home-food-consumer.iam.gserviceaccount.com",
  "universe_domain": "googleapis.com"
}



// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);


