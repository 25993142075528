import React from "react";
import {
  Grid,
  TableCell,
  TableRow,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import { TitleComponent } from "../../components/title_component";
import {
  tableHeadCell,
  tableParentHolder,
  SpanStyle,
  tabHeader,
} from "../../styles/common_styles";
import { HFPagination } from "../../components/pagination";
import { TableComponent } from "../../components/table_component";
import { TableCellComponent } from "../../components/table_cell_component";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import { Loader } from "../../components/loader_component";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import CloseIcon from "@mui/icons-material/Close";

const StoreCredits = () => {
  const [storeCreditList, setStoreCreditList] = React.useState([]);
  const [chatViewModal, setChatViewModal] = React.useState(false);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [loaderData, setLoaderData] = React.useState(true);
  const [viewStoreCreditDetails, setViewStoreCreditDetails] = React.useState(
    {}
  );
  const [searchData, setSearchData] = React.useState("");

  const openChatViewModal = async (id) => {
    setChatViewModal(true);
    await getTicketViewDetails(id);
  };

  const closeChatViewModal = () => {
    setChatViewModal(false);
  };

  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const getStoreCreditDetails = async () => {
    const payload = {
      min: pageConfig.min,
      max: pageConfig.max,
      search: searchData,
    };
    const storeCreditDetailsResponse =
      await ManagementServices.getStoreCreditDetails(payload);
    if (storeCreditDetailsResponse.status === 1) {
      const {
        data: {
          currentPage,
          pageSize,
          totalPages,
          totalUsers,
          storeCreditList,
        },
      } = storeCreditDetailsResponse;
      setPaginationData({
        ...paginationData,
        currentPage: currentPage,
        RecordsForPage: pageSize,
        totalRecords: totalUsers,
        pageCount: totalPages,
      });
      setStoreCreditList(storeCreditList);
      // setAlrtMsg(storeCreditDetailsResponse.message);
      // setSuccessAlrtOpen(true);
      setLoaderData(false);
    } else {
      setAlrtMsg(storeCreditDetailsResponse.message);
      setErrorAlrtOpen(true);
      setLoaderData(false);
    }
  };
  const getTicketViewDetails = async (id) => {
    const payload = {
      ticketId: id,
    };
    const storeCreditViewDetailsResponse =
      await ManagementServices.getViewStoreTicketDetails(payload);
    if (storeCreditViewDetailsResponse.status === 1) {
      setViewStoreCreditDetails(storeCreditViewDetailsResponse.data);
      setAlrtMsg(storeCreditViewDetailsResponse.message);
    } else {
      setAlrtMsg(storeCreditViewDetailsResponse.message);
      setErrorAlrtOpen(true);
      setLoaderData(false);
    }
  };

  const TableHeader = [
    "S No",
    "Order Id",
    "Consumer Name",
    "Credited Date",
    "Ticket Id",
    "Order Amount",
    "Type",
    "Credited Amount",
    "Actions",
  ];

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const TableHeaderRow = TableHeader.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const TableBody = storeCreditList.map((data, index) => (
    <TableRow key={data.id}>
      <TableCellComponent
        value={
          (paginationData.currentPage - 1) * paginationData.RecordsForPage +
          1 +
          index
        }
        indexColoumn={true}
      />
      <TableCellComponent
        value={data.order_id === null ? "Genaral" : data.order_ticket.order_id}
      />
      <TableCellComponent value={data.consumer_ticket.consumer_name} />
      <TableCellComponent value={formatDate(data.approved_at)} />
      <TableCellComponent value={data.ticket_id} />
      <TableCellComponent value={data.order_ticket.total} />
      <TableCellComponent
        value={data.reward_type === 1 ? "StoreCredit" : "Refund"}
      />
      <TableCellComponent value={data.reward_amount} />
      <TableCell sx={{ display: "flex", mt: 1 }}>
        <IconButton
          sx={{ color: "black" }}
          onClick={() => openChatViewModal(data.id)}
        >
          <RemoveRedEyeIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  const messageViewModal = () => {
    return (
      <Modal
        open={chatViewModal}
        onClose={closeChatViewModal}
        aria-labelledby="chat-modal-title"
        aria-describedby="chat-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 750,
            height: 250,
            bgcolor: "background.paper",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            outline: 0,
            borderRadius: 5,
          }}
        >
          <Box
            sx={{
              height: "65vh",
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
            }}
          >
            <Grid sx={{ textAlign: "center", fontWeight: "bold" }}></Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs>
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    Order ID : #{viewStoreCreditDetails?.order_ticket?.order_id}
                  </div>
                </Grid>
                <Grid item>
                  <IconButton
                    mb={2}
                    onClick={closeChatViewModal}
                    sx={{ color: "black", backgroundColor: "grey" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container mt={2}>
              <Grid item md={3} sx={SpanStyle}>
                Consumer Name
              </Grid>
              <Grid item md={3}>
                : {viewStoreCreditDetails?.consumer_ticket?.consumer_name}
              </Grid>

              <Grid item md={3} sx={SpanStyle}>
                Chef Name
              </Grid>
              <Grid item md={3}>
                :{" "}
                {
                  viewStoreCreditDetails?.order_ticket?.order_chef_id
                    ?.first_name
                }
              </Grid>
              <Grid item md={3} sx={SpanStyle} mt={1}>
                Consumer Email
              </Grid>
              <Grid item md={3} mt={1}>
                : {viewStoreCreditDetails?.consumer_ticket?.email}
              </Grid>
              <Grid item md={3} mt={1} sx={SpanStyle} pl={1}>
                Order Delivery Date
              </Grid>
              <Grid item md={3} mt={1}>
                : {viewStoreCreditDetails.order_ticket?.delivery_date || "N/A"}
              </Grid>
              <Grid item md={3} sx={SpanStyle} mt={2}>
                Consumer Ph.No
              </Grid>
              <Grid item md={3} mt={2}>
                : {viewStoreCreditDetails?.consumer_ticket?.mobile}
              </Grid>
              <Grid item md={3} mt={2} sx={SpanStyle}>
                Order Amount
              </Grid>
              <Grid item md={3} mt={2}>
                : {viewStoreCreditDetails?.order_ticket?.total}
              </Grid>
              <Grid item md={3} mt={1} sx={SpanStyle}>
                Date
              </Grid>
              <Grid item md={3} mt={1}>
                : {formatDate(viewStoreCreditDetails?.order_ticket?.created_at)}
              </Grid>
              <Grid item md={3} mt={1} sx={SpanStyle}>
                Credit Date
              </Grid>
              <Grid item md={3} mt={1}>
                : {formatDate(viewStoreCreditDetails?.approved_at)}
              </Grid>
              <Grid item md={3} mt={1} sx={SpanStyle}>
                Ticket ID
              </Grid>
              <Grid item md={3} mt={1}>
                : {viewStoreCreditDetails?.ticket_id}
              </Grid>
              <Grid item md={3} mt={1} sx={SpanStyle}>
                Credit Amount
              </Grid>
              <Grid item md={3} mt={1}>
                : {viewStoreCreditDetails?.reward_amount}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    );
  };

  React.useEffect(() => {
    getStoreCreditDetails();
  }, [searchData, pageConfig.min]);

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems="center"
        spacing={2}
        p={3}
        sx={tabHeader}
      >
        <Grid item md={4}>
          <TitleComponent title={"Store Credits"} />
        </Grid>
        <Grid item md={2}>
          <SearchFieldComponent searchText={handleSearchData} />
        </Grid>
      </Grid>
      <Grid sx={tableParentHolder}>
        {loaderData ? (
          <Loader />
        ) : (
          <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
        )}
        <HFPagination details={paginationData} paginate={handlePaginate} />
        <SuccessAlert
          alertMsg={alrtMsg}
          open={successAlrtOpen}
          close={closeSuccess}
        />
        <ErrorAlert
          alertMsg={alrtMsg}
          open={errorAlrtOpen}
          close={closeError}
        />
      </Grid>
      {messageViewModal()}
    </>
  );
};

export default StoreCredits;
