import AxiosClient from "../axios/client";
import { GetAdminDetails } from "../utils/helpers"; 

/**
 * Service object containing common functions.
 * @namespace CommonServices
 */
export const CommonServices = {
  
  /**
   * Retrieves location based on provided parameters.
   * @async
   * @memberof CommonServices
   * @param {Object} options - The options for retrieving location.
   * @param {string} options.type - The type of location.
   * @param {number} [options.country=0] - The country ID.
   * @param {number} [options.state=0] - The state ID.
   * @param {number} [options.city=0] - The city ID.
   * @returns {Promise} The promise that resolves to the retrieved location.
   */
  getLocation: async ({ type, country = 0, state = 0, city = 0 }) => {
    const adminDetails = await GetAdminDetails();
      try{
        const location = await AxiosClient.post(
          "fetchLocations",
          {
            userId: adminDetails.id,
            countryId: country === "" ? 0 : country,
            stateId: state === "" ? 0 : state,
            cityId: city === "" ? 0 : city,
          },
          { useAccessToken: true }
        );
        return location
      }catch (error) {
        console.error("Error occured while retrieving location:", error);
      }
    // } // END: If adminroleId is not 1 or 2
  },

  /**
   * Adds an admin.
   * @async
   * @memberof CommonServices
   * @param {Object} payload - The payload for adding an admin.
   * @returns {Promise} The promise that resolves to the response of adding an admin.
   */
  addAdmin: async (payload) => {
    try {
      const response = await AxiosClient.post("addAdmin", payload, {
        useAccessToken: true,
      });
      return response;
    } catch (error) {
      console.error("Error occured while adding an admin:", error);
    }
  },

  /**
   * Retrieves the user list.
   * @async
   * @memberof CommonServices
   * @param {number} id - The user ID.
   * @returns {Promise} The promise that resolves to the retrieved user list.
   */
  getUserList: async (id) => {
    try {
      const response = await AxiosClient.post(
        "viewUserdetails",
        { userId: id },
        { useAccessToken: true }
      );
      return response;
    } catch (error) {
      console.error("Error occured while retrieving user list:", error);
    }
  },

  /**
   * A description of the entire function.
   *
   * @param {type} payload - description of parameter
   * @return {type} description of return value
   */
  getCurrency: async (payload) => {
    try {
      const response = await AxiosClient.post(
        "getCurrencyDetails",
        payload,
        { useAccessToken: true }
      );
      return response;
    } catch (error) {
      console.error("Error occured while retrieving user list:", error);
    }
  }
};