import { TableCell, TableRow } from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AccordionComponent from "../management_pages/PriceAccordion";

export const TableRowComponent = ({
  item,
  accordionVisibility,
  toggleAccordion,
  indexes,
  tablePricingData,
}) => {
  const { id, stateId, cityID, areaZipcode, chefId } = item;

  const key = indexes.join("-");

  return (
    <React.Fragment key={key}>
      <TableRow
        key={`country-${key}`}
        sx={{ cursor: "pointer" }}
        onClick={() => toggleAccordion(key)}
      >
        <TableCell sx={{ textAlign: "center" }}>{item?.country}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>{item?.stateName}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>{item?.cityName}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>{item?.areaCode}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>{item?.ChefName}</TableCell>
        <TableCell>
          {accordionVisibility[key] ? (
            <KeyboardArrowUpIcon className="icon" />
          ) : (
            <KeyboardArrowDownIcon className="icon" />
          )}
        </TableCell>
      </TableRow>
      {accordionVisibility[key] && (
        <TableRow key={`country-${key}`}>
          <TableCell colSpan={6}>
            <AccordionComponent
              tableShowData={tablePricingData}
              chefId={chefId}
              areaCode={areaZipcode}
              cityId={cityID}
              countryName={
                item?.cityName ||
                item?.stateName ||
                item?.country ||
                item?.areaCode ||
                item?.ChefName
              }
              stateId={stateId}
              countryID={id}
            />
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
