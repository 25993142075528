import React from "react";
import {
  Grid,
  MenuItem,
  TableCell,
  TableRow,
  FormControl,
  Select,
  Tab,
  IconButton,
  Modal,
  Box,
  Badge,
} from "@mui/material";
import { TabList, TabContext } from "@mui/lab";
import { tabHeader, tabStyle, tabItem } from "../../styles/common_styles";
import { TitleComponent } from "../../components/title_component";
import { CommonServices } from "../../services/common_services";
import {
  tableHeadCell,
  tableParentHolder,
  SpanStyle,
} from "../../styles/common_styles";
import { HFPagination } from "../../components/pagination";
import { TableComponent } from "../../components/table_component";
import { TableCellComponent } from "../../components/table_cell_component";
import EmailIcon from "@mui/icons-material/Email";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import { Loader } from "../../components/loader_component";
import CloseIcon from "@mui/icons-material/Close";
import { FirebaseServices } from "../../utils/firebasefunctions";
import { GetAdminDetails } from "../../utils/helpers";
import { SearchFieldComponent } from "../../components/form_components/search_field";

const Tickets = () => {
  const [tickets, setTickets] = React.useState([]);
  const [loadingData, setLoadingData] = React.useState(true);
  const [value, setValue] = React.useState("1");
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(0);
  const [cities, setCities] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState(0);
  const [chatViewModal, setChatViewModal] = React.useState(false);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [loaderData, setLoaderData] = React.useState(true);
  const [viewTicketDetails, setViewTicketDetails] = React.useState({});
  const [tickectCurrency, setTickectCurrency] = React.useState("");
  const [badgeCounts, setBadgeCounts] = React.useState({});
  const [roleId, setRoledId] = React.useState("");
  const [status, setStatus] = React.useState(6);
  const [searchData, setSearchData] = React.useState("");
  // const [loadData, setLoadData] = React.useState(true);

  const openChatViewModal = async (id, currency) => {
    setChatViewModal(true);
    await getTicketViewDetails(id);
    setTickectCurrency(currency);
  };

  const closeChatViewModal = () => {
    setChatViewModal(false);
    setLoadingData(true);
    setTickectCurrency("");
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const navigate = useNavigate();

  const handleClick = async (
    id,
    id2,
    status,
    addedId,
    userName,
    order_id,
    AssignStatus
  ) => {
    const payload = {
      ticketId: id2,
    };
    // const ticketUpdateStatusResponse =
    //   await ManagementServices.updateTicketStatusDetails(payload);
    const additionalData = {
      ticketUUId: id2,
      ticketStatus: status,
      value: value === "1" ? "2" : "1",
      receiverId: addedId,
      userName: userName,
      orderId: order_id,
      AssignStatus: AssignStatus,
    };
    if (AssignStatus || roleId === 5 || roleId === 6) {
      const response = await FirebaseServices.updateBadgeOnTicketOpen(id);
    }
    id &&
      navigate("/TicketId", { state: { ticket_id: id, ...additionalData } });
    try {
      await ManagementServices.updateTicketStatusDetails(payload);

      // await FirebaseServices
    } catch (error) {
      console.error("Error updating ticket status:", error);
      // Optionally navigate back or show an error notification
    }
  };

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const badgeCountDetails = async (ticketId) => {
    try {
      const details = await FirebaseServices.getBadgeCountOnTicket(ticketId);
      return details;
    } catch (e) {
      console.error("Error fetching badge count details: ", e);
      return false; // or you can choose to handle it differently
    }
  };

  const handleChangeTab = (event, newValue) => {
    setLoaderData(true);
    setValue(newValue);
    setTimeout(() => {
      setLoaderData(false);
    }, 700);
  };

  const getTicketDetails = async () => {
    const payload = {
      type: parseInt(value),
      min: pageConfig.min,
      max: pageConfig.max,
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      status: status === 6 ? null : status,
      search: searchData,
    };
    const ticketDetailsResponse = await ManagementServices.getTicketDetails(
      payload
    );
    if (ticketDetailsResponse?.status === 1) {
      const {
        data: { currentPage, pageSize, totalPages, totalTickets, tickets },
      } = ticketDetailsResponse;
      setPaginationData({
        ...paginationData,
        currentPage: currentPage,
        RecordsForPage: pageSize,
        totalRecords: totalTickets,
        pageCount: totalPages,
      });
      setTickets(tickets);
      // setAlrtMsg(ticketDetailsResponse.message);
      // setSuccessAlrtOpen(true);
      setLoaderData(false);
    } else {
      // setAlrtMsg(ticketDetailsResponse.message);
      // setErrorAlrtOpen(true);
      setLoaderData(false);
    }
  };
  const getTicketViewDetails = async (id) => {
    const payload = {
      type: parseInt(value),
      ticketId: id,
    };
    const ticketViewDetailsResponse =
      await ManagementServices.getViewTicketDetails(payload);
    if (ticketViewDetailsResponse.status === 1) {
      setViewTicketDetails(ticketViewDetailsResponse.data);
      setAlrtMsg(ticketViewDetailsResponse.message);
      setLoadingData(false);
    } else {
      setAlrtMsg(ticketViewDetailsResponse.message);
      setErrorAlrtOpen(true);
      setLoadingData(false);
    }
  };

  const TableHeader = [
    "S No",
    "Order Id",
    value === "1" ? "Consumer Name" : "Chef Name",
    "Date",
    "Ticket Id",
    "Ticket Type",
    "Message",
    "Status",
    "IsApproved",
    "Actions",
  ];

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, event.target.value);
    setSelectedState(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    GetCities(2, event.target.value);
  };
  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);
      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);
      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching cities:${error}`);
    }
  };

  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const TableHeaderRow = TableHeader.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const getStatusText = (statusNumber) => {
    switch (statusNumber) {
      case 0:
        return "Pending";
      case 1:
        return "Waiting for admin approval";
      case 2:
        return "Closed";
      case 3:
        return "Reopen";
      default:
        return "Unknown status";
    }
  };

  const TableBody = tickets.map((data, index) => (
    <TableRow key={data.id}>
      <TableCellComponent
        value={
          (paginationData.currentPage - 1) * paginationData.RecordsForPage +
          1 +
          index
        }
        indexColoumn={true}
      />
      <TableCellComponent
        value={
          data.order_ticket === null
            ? "Genaral"
            : data.order_ticket?.order_id || data.order_id || "-"
        }
      />
      <TableCellComponent
        value={value === "1" ? data.consumer_name : data.chef_name}
      />
      <TableCellComponent value={formatDate(data.created_at)} />
      <TableCellComponent value={data.ticket_id} />
      <TableCellComponent
        value={data.ticket_type === 1 ? "General" : "Order"}
      />
      <TableCellComponent value={data.message} />
      <TableCellComponent
        value={
          data.status === 2
            ? "Closed"
            : data.is_approved === 1
            ? "Approved"
            : getStatusText(data.status)
        }
      />
      <TableCellComponent
        value={data.is_approved === 1 ? "Approved" : "Not Approved"}
      />
      <TableCell sx={{ display: "flex", mt: 1 }}>
        <Badge
          className="BadgeStyles"
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          badgeContent={
            data.ticket_unread || badgeCounts[data.ticket_id] ? "" : null
          }
          color="error"
          height="2px"
          width="2px"
        >
          <IconButton
            sx={{ color: "orange" }}
            onClick={() => {
              const additionalId =
                value === "1" ? data?.consumer_id : data?.chef_id;
              const userName =
                value === "1" ? data?.consumer_name : data?.chef_name;
              handleClick(
                data.ticket_id,
                data.id,
                data.status,
                additionalId,
                userName,
                data?.order_ticket?.order_id,
                data.is_assigned
              );
            }}
          >
            <EmailIcon />
          </IconButton>
        </Badge>
        <IconButton
          sx={{ color: "black" }}
          onClick={() =>
            openChatViewModal(data.id, data.order_ticket?.currency)
          }
        >
          <RemoveRedEyeIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  const messageViewModal = () => {
    return (
      <Modal
        open={chatViewModal}
        onClose={closeChatViewModal}
        aria-labelledby="chat-modal-title"
        aria-describedby="chat-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 750,
            height: "fit-content",
            bgcolor: "background.paper",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            outline: 0,
            borderRadius: 5,
          }}
        >
          <Box
            sx={{
              height: "65vh",
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
            }}
          >
            {loadingData ? (
              <Loader />
            ) : (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs>
                      <div style={{ textAlign: "center", fontWeight: "bold" }}>
                        Ticket Id : #{viewTicketDetails?.ticket_id}
                      </div>
                    </Grid>
                    <Grid item>
                      <IconButton
                        mb={2}
                        onClick={closeChatViewModal}
                        sx={{ color: "black", backgroundColor: "grey" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  {value === "1" || value === 1 ? (
                    <>
                      <Grid item md={3} sx={SpanStyle}>
                        Consumer Name
                      </Grid>
                      <Grid item md={3}>
                        : {viewTicketDetails?.consumer_ticket?.first_name}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={3} sx={SpanStyle}>
                        Chef Name
                      </Grid>
                      <Grid item md={3}>
                        : {viewTicketDetails?.chef_ticket?.first_name}
                      </Grid>
                    </>
                  )}
                  {viewTicketDetails.ticket_type === 2 ? (
                    <>
                      <Grid item md={3} sx={SpanStyle}>
                        Order Id
                      </Grid>
                      <Grid item md={3} mt={1}>
                        : {viewTicketDetails?.order_ticket?.order_id}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={3} sx={SpanStyle}></Grid>
                      <Grid item md={3} mt={1}></Grid>
                    </>
                  )}
                  {value === "1" || value === 1 ? (
                    <>
                      <Grid item md={3} sx={SpanStyle} mt={1}>
                        Consumer Email
                      </Grid>
                      <Grid item md={3} mt={1}>
                        : {viewTicketDetails?.consumer_ticket?.email}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={3} sx={SpanStyle} mt={1}>
                        Chef Email
                      </Grid>
                      <Grid item md={3} mt={1}>
                        : {viewTicketDetails?.chef_ticket?.email}
                      </Grid>
                    </>
                  )}
                  <Grid item md={3} mt={1} sx={SpanStyle} pl={1}>
                    Ticket Type
                  </Grid>
                  {
                    <Grid item md={3} mt={1}>
                      :{" "}
                      {viewTicketDetails.ticket_type === 1
                        ? "Genaral"
                        : "Order"}
                    </Grid>
                  }
                  {value === "1" || value === 1 ? (
                    <>
                      <Grid item md={3} sx={SpanStyle} mt={2}>
                        Consumer Ph.No
                      </Grid>
                      <Grid item md={3} mt={2}>
                        : {viewTicketDetails?.consumer_ticket?.mobile}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={3} sx={SpanStyle} mt={2}>
                        Chef Ph.No
                      </Grid>
                      <Grid item md={3} mt={2}>
                        : {viewTicketDetails?.chef_ticket?.mobile}
                      </Grid>
                    </>
                  )}
                  {viewTicketDetails?.ticket_type === 2 ? (
                    <>
                      <Grid item md={3} mt={2} sx={SpanStyle}>
                        Order Amount
                      </Grid>
                      <Grid item md={3} mt={2}>
                        : {tickectCurrency}
                        {viewTicketDetails?.order_ticket.total}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={3} mt={2} sx={SpanStyle}></Grid>
                      <Grid item md={3} mt={2}></Grid>
                    </>
                  )}
                  <Grid item md={3} mt={1} sx={SpanStyle}>
                    Date
                  </Grid>
                  <Grid item md={3} mt={1}>
                    : {formatDate(viewTicketDetails?.created_at)}
                  </Grid>
                  {viewTicketDetails?.ticket_type === 2 ? (
                    <>
                      <Grid item md={3} mt={1} sx={SpanStyle}>
                        Store Credit Amount
                      </Grid>
                      <Grid item md={3} mt={1}>
                        : {tickectCurrency}
                        {viewTicketDetails?.reward_amount}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={3} mt={1} sx={SpanStyle}></Grid>
                      <Grid item md={3} mt={1}></Grid>
                    </>
                  )}
                  {value === "1" || value === 1 ? (
                    <>
                      <Grid item md={3} mt={2} sx={SpanStyle}>
                        Consumer Message
                      </Grid>
                      <Grid item md={9} mt={2}>
                        : {viewTicketDetails.message}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={3} mt={2} sx={SpanStyle}>
                        Chef Message
                      </Grid>
                      <Grid item md={9} mt={2}>
                        : {viewTicketDetails.message}
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };

  React.useEffect(() => {
    const fetchBadgeCounts = async () => {
      const counts = await Promise.all(
        tickets.map(async (data) => {
          const hasBadge = await badgeCountDetails(data.ticket_id);
          return { ticket_id: data.ticket_id, hasBadge };
        })
      );
      const countsMap = counts.reduce((acc, { ticket_id, hasBadge }) => {
        acc[ticket_id] = hasBadge;
        return acc;
      }, {});
      setBadgeCounts(countsMap);
    };
    fetchBadgeCounts();
  }, [tickets]);

  React.useEffect(() => {
    getTicketDetails();
    GetCountries();
  }, [value, pageConfig.min, status, searchData]);

  const getAdminDetails = async () => {
    const AdminDetails = await GetAdminDetails();
    setRoledId(AdminDetails.role_id);
  };

  React.useEffect(() => {
    getAdminDetails();
  }, []);

  React.useEffect(() => {
    getTicketDetails();
  }, [selectedCountry, selectedState, selectedCity]);

  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems="center"
        spacing={2}
        p={3}
        sx={tabHeader}
      >
        <Grid item md={1.5}>
          <TitleComponent title={"Tickets"} />
        </Grid>
        <Grid item md={3}>
          <TabContext value={value}>
            <TabList
              sx={tabStyle}
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
            >
              <Tab label="Consumer" value="1" sx={tabItem} />
              <Tab label="Chef" value="2" sx={tabItem} />
            </TabList>
          </TabContext>
        </Grid>
        <Grid item md={1.5}>
          <SearchFieldComponent searchText={handleSearchData} />
        </Grid>
        <Grid item md={6}>
          <Grid container justifyContent={"end"} spacing={2}>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Select
                  value={selectedCountry}
                  size={"small"}
                  inputProps={{ "aria-label": "Country" }}
                  onChange={handleChangeCountry}
                >
                  <MenuItem value={0}>Select Country</MenuItem>
                  {totalCountries}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Select
                  value={selectedState}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "State" }}
                  onChange={handleChangeState}
                >
                  <MenuItem value={0} disabled>
                    Select state
                  </MenuItem>
                  {totalStates}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Select
                  value={selectedCity}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "State" }}
                  onChange={handleChangeCity}
                >
                  <MenuItem value={0} disabled>
                    Select City
                  </MenuItem>
                  {totalCities}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Select
                  value={status}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "State" }}
                  onChange={handleChangeStatus}
                >
                  <MenuItem value={6} disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value={0}>Pending</MenuItem>
                  <MenuItem value={1}>Waiting for approval</MenuItem>
                  <MenuItem value={2}>Closed</MenuItem>
                  <MenuItem value={3}>Reopen</MenuItem>
                  <MenuItem value={5}>Approved</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={tableParentHolder}>
        {loaderData ? (
          <Loader />
        ) : (
          <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
        )}
        <HFPagination details={paginationData} paginate={handlePaginate} />
        <SuccessAlert
          alertMsg={alrtMsg}
          open={successAlrtOpen}
          close={closeSuccess}
        />
        <ErrorAlert
          alertMsg={alrtMsg}
          open={errorAlrtOpen}
          close={closeError}
        />
      </Grid>
      {messageViewModal()}
    </>
  );
};

export default Tickets;
