import { Grid, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import pdfCard from "../assets/images/pdf.png";
import { MediaBaseUrl } from "../config/config";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";

export const PdfCard = ({ pdfDetails }) => {
  const DownloadPdf = (pdfbase) => {
    const pdfPath = pdfbase;

    try {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfbase;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      // Handle errors gracefully, e.g., display an error message to the user
      console.error("Error downloading PDF:", error);
    }
  };

  const ViewPdf = (pdfbase) => {
    const pdfPath = pdfbase;

    try {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.target = "_blank"; // Open in a new tab
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      // Handle errors gracefully, e.g., display an error message to the user
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <Grid className="PdfCards">
        {pdfDetails.map((pdf, index) => (
          <Grid key={index} className="AddressCard">
            <Typography variant="p" className="RuleNos">
              Rule {index + 1}
            </Typography>
            <Grid className="CardBody">
              <img src={pdfCard} alt="Onboardingrules" />
              <Grid className="CardInnerIcons">
                <RemoveRedEyeIcon
                  className="viewIcon"
                  onClick={() => ViewPdf(pdf.pdfUrl)}
                />
                <DownloadSharpIcon
                  className="viewIcon"
                  onClick={() => DownloadPdf(pdf.pdfUrl)}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
