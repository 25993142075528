import React, { useState } from "react";
import {
  Grid,
  TableCell,
  TableRow,
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
} from "@mui/material";
import { TableComponent } from "./table_component";
import { ButtonComponent } from "./form_components/button";
import {
  tableHeadCell,
  tableParentHolder,
  tableParentHolderr,
  Errors,
} from "../styles/common_styles";
import { Emaillable, CustomField } from "../styles/loginstyles";
import { useForm } from "react-hook-form";
import EditIcon from "../assets/icons/edit.svg";
import DeleteIcon from "../assets/icons/delete.svg";
import { FaqModalStyle, modalHeading } from "../styles/common_styles";
import { CommonServices } from "../services/common_services";
import { ManagementServices } from "../services/management_services";
import { SuccessAlert } from "./alert";
import { ErrorAlert } from "./alert";
import { IconTableCellComponent } from "./table_cell_component";
import { BlockOrDeleteModalComponent } from "./modal_component";
import { HFPagination } from "./pagination";
import { Loader } from "./loader_component";

export const CurrencyTaxHandling = ({
  fromModule,
  open,
  handleClose: handleCloseProp,
  searchDataa,
  renderData,
}) => {
  const [taxCurrencyData, setTaxCurrencyData] = React.useState([]);
  const [currency, setCurrency] = React.useState("");
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [countries, setCountries] = React.useState([]);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [loaderData, setLoaderData] = React.useState(true);
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });
  const [currencyDetails, setCurrencyDetails] = React.useState({
    country: "",
    currency: "",
    conversionRate: "",
  });

  const [taxDetails, setTaxDetails] = React.useState({
    id: null,
    country: null,
    state: null,
    city: null,
    tax: null,
    Percentage: null,
  });

  const [actionType, setActiontype] = React.useState({
    title: "",
    type: null,
  });

  const handleClose = () => {
    setModalOpen(false);
    setIsEditMode(false);
    handleCloseProp();
    reset();
    reset1();
    setSelectedCountry(null);
    setCurrency("");
    setCurrencyDetails({
      ...currencyDetails,
      country: null,
      currency: null,
      conversionRate: null,
    });
    setTaxDetails({
      ...taxDetails,
      id: null,
      country: null,
      state: null,
      city: null,
      tax: null,
      Percentage: null,
    });
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  let tableHeaders;

  if (fromModule === 1 || fromModule === "1") {
    tableHeaders = ["Sno", "Country", "Currency", "Conversion Rate", "Actions"];
  } else {
    tableHeaders = [
      "Sno",
      "Country",
      "State",
      "City",
      "Tax Name",
      "Percentage",
      "Actions",
    ];
  }

  const editModalHandleOpen = (data) => {
    setModalOpen(true);
    setEditModal(true);
    setIsEditMode(true);
    setSelectedCountry(data.id);
    setSelectedState(data.state_id);
    setSelectedCity(data.city_id);
    if (fromModule === 1 || fromModule === "1") {
      setCurrencyDetails({
        ...currencyDetails,
        country: data.id,
        currency: data.currency,
        conversionRate: data.conversion_rate_to_dollar,
      });
    } else {
      setTaxDetails({
        ...taxDetails,
        id: data.id,
        country: data.country_id,
        state: data.state_id,
        city: data.city_id,
        tax: data.tax_name,
        Percentage: data.tax_percent,
      });
    }
  };

  const DeleteModalClose = () => setDeleteModal(false);

  const blockOrDeleteHandleOpen = (data, title, type) => {
    setCountryId(data.country_id);
    setDeleteModal(true);
    setTaxDetails({
      ...taxDetails,
      tax: data.tax_name,
      Percentage: data.tax_percent,
    });
    setActiontype({ ...actionType, title, type });
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    GetStates(2, event.target.value);
    setSelectedCountry(value);
    setSelectedState(null);
    setCountryId(value);
    GetCurrency(value);
  };
  const handleChangeCountry1 = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    setSelectedState(null);
    GetStates(2, event.target.value);
    setCountryId(value);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setStateId(value);
    GetCities(2, event.target.value);
  };
  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
    setCityId(value);
  };

  const GetCurrency = async (id) => {
    const payload = {
      countryId: id,
    };
    const currencyResponse = await CommonServices.getCurrency(payload);
    setCurrency(currencyResponse.data.currency);
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));
  const iconConfigurations = (data) => [
    {
      condition: true,
      icon: EditIcon,
      alt: "Edit",
      onClick: () => editModalHandleOpen(data),
    },
    {
      condition: true,
      icon: DeleteIcon,
      alt: "Delete",
      onClick: () => blockOrDeleteHandleOpen(data, "Delete", 3),
    },
  ];

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);
      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching cities:${error}`);
    }
  };

  const TableBody = taxCurrencyData?.map((data, index) => {
    return (
      <TableRow key={data.id}>
        <TableCell sx={{ textAlign: "center" }}>{index + 1}</TableCell>
        {fromModule === 1 || fromModule === "1" ? (
          <TableCell sx={{ textAlign: "center" }}>{data.name}</TableCell>
        ) : (
          <TableCell sx={{ textAlign: "center" }}>
            {data.country_name}
          </TableCell>
        )}
        {fromModule === 2 || fromModule === "2" ? (
          <TableCell sx={{ textAlign: "center" }}>
            {data.state_name || "-"}
          </TableCell>
        ) : null}
        {fromModule === 2 || fromModule === "2" ? (
          <TableCell sx={{ textAlign: "center" }}>
            {data.city_name || "-"}
          </TableCell>
        ) : null}
        {fromModule === 1 || fromModule === "1" ? (
          <TableCell sx={{ textAlign: "center" }}>{data.currency}</TableCell>
        ) : (
          <TableCell sx={{ textAlign: "center" }}>{data.tax_name}</TableCell>
        )}
        {fromModule === 1 || fromModule === "1" ? (
          <TableCell sx={{ textAlign: "center" }}>
            {data.conversion_rate_to_dollar}
          </TableCell>
        ) : (
          <TableCell sx={{ textAlign: "center" }}>{data.tax_percent}</TableCell>
        )}
        <IconTableCellComponent
          index={index}
          data={data}
          iconConfigurations={iconConfigurations}
        />
      </TableRow>
    );
  });

  const getCurrencyAndTax = async () => {
    if (fromModule === 1 || fromModule === "1") {
      const payload = {
        min: pageConfig.min,
        max: pageConfig.max,
        search: searchDataa,
      };
      const getTaxCurrency = await ManagementServices.getcountryCurrency(
        payload
      );
      if (getTaxCurrency.status === 1) {
        setTaxCurrencyData(getTaxCurrency.data.currencyConversion);
        setPaginationData({
          ...paginationData,
          currentPage: getTaxCurrency.data.currentPage,
          RecordsForPage: getTaxCurrency.data.pageSize,
          totalRecords: getTaxCurrency.data.totalCountries,
          pageCount: getTaxCurrency.data.totalPages,
        });
        setLoaderData(false);
        // setAlrtMsg(getTaxCurrency.message);
        // setSuccessAlrtOpen(true);
      } else {
        setAlrtMsg(getTaxCurrency.message);
        setErrorAlrtOpen(true);
      }
    } else {
      const payload = {
        min: pageConfig.min,
        max: pageConfig.max,
        search: searchDataa,
      };
      const getTax = await ManagementServices.getcountryTax(payload);
      if (getTax?.status === 1) {
        setTaxCurrencyData(getTax?.data.taxData);
        setPaginationData({
          ...paginationData,
          currentPage: getTax?.data.currentPage,
          RecordsForPage: getTax?.data.pageSize,
          totalRecords: getTax?.data.totalCountries,
          pageCount: getTax?.data.totalPages,
        });
        setLoaderData(false);
        // setAlrtMsg(getTax?.message);
        // setSuccessAlrtOpen(true);
      } else {
        setAlrtMsg(getTax?.message);
        setErrorAlrtOpen(true);
      }
    }
  };

  const CurrencySubmit = async (data) => {
    let payload;
    if (isEditMode === true) {
      payload = {
        countryId: currencyDetails.id || data.countryId,
        conversionRate:
          currencyDetails.conversion_rate_to_dollar || data.conversionRate,
        type: 2,
      };
    } else {
      payload = {
        countryId: countryId,
        conversionRate: data.conversionRate,
        type: 1,
      };
    }

    const insertCurrency =
      await ManagementServices.handleupdateCountryConversion(payload);
    if (insertCurrency?.status === 1) {
      setSuccessAlrtOpen(true);
      setAlrtMsg(insertCurrency.message);
      setCountryId(null);
      setSelectedCountry(null);
      setCurrency(null);
      setValue("conversionRate", null);
      setCurrencyDetails({
        ...currencyDetails,
        currency: null,
      });
      handleClose();
      getCurrencyAndTax();
    } else {
      setAlrtMsg(insertCurrency.message);
      setErrorAlrtOpen(true);
    }
  };

  const CurrencyModal = () => {
    return (
      <Modal
        open={open || modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={FaqModalStyle}>
          <Typography sx={modalHeading}>
            {isEditMode ? "Edit  Currency" : "Add  Currency"}
          </Typography>
          <Grid component={"form"} onSubmit={handleSubmit(CurrencySubmit)}>
            <Grid container justifyContent={"center"} spacing={3}>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Country</Grid>
                  <Select
                    sx={CustomField}
                    value={selectedCountry || ""}
                    size={"small"}
                    {...register("countryId", {
                      required: "Please select Country",
                    })}
                    onChange={handleChangeCountry}
                    displayEmpty
                    inputProps={{
                      "aria-label": "countryId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select country
                    </MenuItem>
                    {totalCountries}
                  </Select>
                  {errors.countryId && errors.countryId.type === "required" && (
                    <Typography sx={Errors}>Please select country.</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Currency</Grid>
                  <TextField
                    sx={CustomField}
                    fullWidth
                    size={"small"}
                    value={currencyDetails.currency || currency || ""}
                    type="text"
                    inputProps={{
                      placeholder: "Please select currency",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Conversion rate</Grid>
                  <TextField
                    sx={CustomField}
                    fullWidth
                    size={"small"}
                    defaultValue={currencyDetails?.conversionRate || ""}
                    inputProps={{
                      placeholder: "Enter Conversion Rate",
                    }}
                    {...register("conversionRate", {
                      required: "Please Enter Conversion rate",
                      maxLength: {
                        value: 255,
                        message: "must be values",
                      },
                      validate: (value) =>
                        parseFloat(value) > 0 ||
                        "Conversion rate must be values and greater than 0",
                    })}
                  />
                  {errors.conversionRate && (
                    <Typography sx={Errors}>
                      {errors?.conversionRate?.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid container justifyContent={"center"} p={3}>
                {isEditMode ? (
                  <ButtonComponent type={"submit"} btnText={"Update"} />
                ) : (
                  <ButtonComponent type={"submit"} btnText={"Add"} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };

  const TaxSubmit = async (data) => {
    let payload;
    if (isEditMode === true) {
      payload = {
        taxId: taxDetails.id,
        countryId: taxDetails.country || parseInt(data.countryId),
        stateId: stateId || parseInt(data.stateId),
        cityId: cityId || parseInt(data.cityId),
        taxName: taxDetails.tax_name || data.tax,
        type: 2,
        taxPercent: taxDetails.tax_percent || parseFloat(data.Percentage),
      };
    } else {
      payload = {
        countryId: countryId,
        stateId: stateId,
        cityId: cityId,
        taxName: data.tax,
        type: 1,
        taxPercent: data.Percentage,
      };
    }

    const insertTax = await ManagementServices.handleupdateCountryTax(payload);
    if (insertTax?.status === 1) {
      setSuccessAlrtOpen(true);
      setAlrtMsg(insertTax?.message);
      setCountryId(null);
      setSelectedCountry(null);
      setValue1("Percentage", null);
      setValue1("tax", null);
      handleClose();
      getCurrencyAndTax();
    } else {
      setAlrtMsg(insertTax?.message);
      setErrorAlrtOpen(true);
    }
  };

  const TaxModal = () => {
    return (
      <Modal
        open={open || modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={FaqModalStyle}>
          <Typography sx={modalHeading}>
            {isEditMode ? "Edit  Tax" : "Add  Tax"}
          </Typography>
          <Grid component={"form"} onSubmit={handleSubmit1(TaxSubmit)}>
            <Grid container justifyContent={"center"} spacing={3}>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Country</Grid>
                  <Select
                    sx={CustomField}
                    value={taxDetails.country || selectedCountry || ""}
                    size={"small"}
                    {...register1("countryId", {
                      required: "Please select Country",
                    })}
                    onChange={handleChangeCountry1}
                    displayEmpty
                    inputProps={{
                      "aria-label": "countryId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select country
                    </MenuItem>
                    {totalCountries}
                  </Select>
                  {errors1.countryId &&
                    errors1.countryId.type === "required" && (
                      <Typography sx={Errors}>
                        Please select country.
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>State</Grid>
                  <Select
                    sx={CustomField}
                    value={taxDetails.state || selectedState || ""}
                    size={"small"}
                    {...register1("stateId", {
                      // required: "Please select state",
                    })}
                    onChange={handleChangeState}
                    displayEmpty
                    inputProps={{
                      "aria-label": "stateId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select state
                    </MenuItem>
                    {totalStates}
                  </Select>
                  {/* {errors.countryId && errors.countryId.type === "required" && (
                    <Typography sx={Errors}>Please select country.</Typography>
                  )} */}
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>City</Grid>
                  <Select
                    sx={CustomField}
                    value={taxDetails.city || selectedCity || ""}
                    size={"small"}
                    {...register1("cityId", {
                      // required: "Please select City",
                    })}
                    onChange={handleChangeCity}
                    displayEmpty
                    inputProps={{
                      "aria-label": "cityId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select City
                    </MenuItem>
                    {totalCities}
                  </Select>
                  {/* {errors.countryId && errors.countryId.type === "required" && (
                    <Typography sx={Errors}>Please select country.</Typography>
                  )} */}
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Tax Name</Grid>
                  <TextField
                    sx={CustomField}
                    fullWidth
                    size={"small"}
                    defaultValue={taxDetails.tax || ""}
                    type="text"
                    inputProps={{
                      placeholder: "Please Enter Tax",
                    }}
                    {...register1("tax", {
                      required: "Please Enter Tax",
                    })}
                  />
                  {errors1.tax && (
                    <Typography sx={Errors}>{errors1?.tax?.message}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Percentage</Grid>
                  <TextField
                    sx={CustomField}
                    fullWidth
                    size={"small"}
                    defaultValue={taxDetails.Percentage || ""}
                    inputProps={{
                      placeholder: "Enter Percentage",
                    }}
                    {...register1("Percentage", {
                      required: "Please Enter Percentage",
                      maxLength: {
                        value: 255,
                        message: "must be values",
                      },
                      validate: (value) =>
                        parseFloat(value) > 0 ||
                        "Percentage must be greater than 0",
                    })}
                  />
                  {errors1.Percentage && (
                    <Typography sx={Errors}>
                      {errors1?.Percentage?.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid container justifyContent={"center"} p={3}>
                {isEditMode ? (
                  <ButtonComponent type={"submit"} btnText={"Update"} />
                ) : (
                  <ButtonComponent type={"submit"} btnText={"Add"} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };
  const handleRes = async (data) => {
    if (data === 1) {
      DeleteModalClose();
    } else {
      const payload = {
        countryId: countryId,
        type: 3,
        conversionRate: "",
      };
      if (fromModule === "1" || fromModule === 1) {
        const deleteCurrency =
          await ManagementServices.handleupdateCountryConversion(payload);
        if (deleteCurrency.status === 1) {
          setSuccessAlrtOpen(true);
          setAlrtMsg(deleteCurrency.message);
          getCurrencyAndTax();
          DeleteModalClose();
        } else {
          setAlrtMsg(deleteCurrency.message);
          setErrorAlrtOpen(true);
        }
      } else {
        const payload = {
          countryId: countryId,
          type: 3,
          taxName: taxDetails.tax,
          taxPercent: taxDetails.Percentage,
        };
        const deleteTax = await ManagementServices.handleupdateCountryTax(
          payload
        );
        if (deleteTax.status === 1) {
          setSuccessAlrtOpen(true);
          setAlrtMsg(deleteTax.message);
          getCurrencyAndTax();
          DeleteModalClose();
        } else {
          setAlrtMsg(deleteTax.message);
          setErrorAlrtOpen(true);
        }
      }
    }
  };

  React.useEffect(() => {
    GetCountries();
  }, [fromModule, currency, searchDataa, taxDetails, renderData]);

  React.useEffect(() => {
    if (renderData) {
      getCurrencyAndTax();
    }
  }, [renderData]);

  React.useEffect(() => {
    // GetCountries();
    if (isEditMode === true) {
      GetStates(2, taxDetails.country);
      GetCities(2, taxDetails.state, taxDetails.country);
    }
    getCurrencyAndTax();
  }, [fromModule, currency, searchDataa, taxDetails, renderData]);

  return (
    <>
      <Grid>
        {loaderData && loaderData ? (
          <Loader />
        ) : (
          <Grid sx={tableParentHolderr}>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
          </Grid>
        )}
        <HFPagination details={paginationData} paginate={handlePaginate} />
        <BlockOrDeleteModalComponent
          deleteopen={deleteModal}
          resType={handleRes}
          onClose={DeleteModalClose}
          title={`Do you want to Delete?`}
        />
        <Grid>
          {fromModule === "1" || fromModule === 1
            ? CurrencyModal()
            : TaxModal()}
        </Grid>
        <SuccessAlert
          alertMsg={alrtMsg}
          open={successAlrtOpen}
          close={closeSuccess}
        />
        <ErrorAlert
          alertMsg={alrtMsg}
          open={errorAlrtOpen}
          close={closeError}
        />
      </Grid>
    </>
  );
};
