import {
  Grid,
  Box,
  List,
  ListItem,
  Typography,
  IconButton,
  Modal,
  FormControl,
  Select,
  MenuItem,
  TextareaAutosize,
  Checkbox
} from "@mui/material";
import { ManagementServices } from "../services/management_services";
import React from "react";
import { ErrorAlert, SuccessAlert } from "./alert";
import {
  safetyText,
  safetyMainHeading,
  safetyBorder,
  safetyBlock,
  modalHeading,
  FaqModalStyle,
  Errors,
} from "../styles/common_styles";
import EditIcon from "../assets/icons/edit.svg";
import DeleteIcon from "../assets/icons/delete.svg";
import { BlockOrDeleteModalComponent } from "./modal_component";
import { BackDropLoader } from "./loader_component";
import { ButtonComponent } from "./form_components/button";
import { CommonServices } from "../services/common_services";
import { Emaillable, CustomField } from "../styles/loginstyles";
import { useForm } from "react-hook-form";

export const FaqHandling = ({
  modType,
  countryId,
  stateId,
  cityId,
  handMod,
  handleModClose,
  resHandler,
  editModHandler
}) => {
  const [listOfFaq, setListOfFaq] = React.useState([]);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [assignModal, setAssignModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [actionId, setActionId] = React.useState("");
  const [backDropLoaderOpen, setBackDropLoaderOpen] = React.useState(false);
  const [handleModal, setHandleModal] = React.useState(handMod);
  const [countries, setCountries] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedStates, setSelectedStates] = React.useState(null);
  const [selectedcities, setSelectedcities] = React.useState(null);
  const [cities, setCities] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  
  
  const GetFaqList = async () => {

    const payload = {
      type: modType,
      countryId,
      stateId,
      cityId,
    };

    const faqlist = await ManagementServices.listFaqs(payload);
    if (faqlist?.status === 1) {
      setListOfFaq(faqlist.data);
    } else {
      setAlrtMsg(faqlist.message);
      setErrorAlrtOpen(true);
    }
  };

  const blockOrDeleteHandleOpen = (id) => {
    setActionId(id);
    setDeleteModal(true);
  };

  const handleToggleBackDropLoader = () => {
    setBackDropLoaderOpen(!backDropLoaderOpen);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const DeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleRes = async (data) => {
    try {
      if (data === 1) {
        DeleteModalClose();
      } else {
        setBackDropLoaderOpen(true);
        const response = await ManagementServices.deleteFaq(actionId);

        if (response.status === 1) {
          setBackDropLoaderOpen(false);
          setAlrtMsg(response.message);
          setSuccessAlrtOpen(true);
          DeleteModalClose();
          GetFaqList();
        } else {
          setBackDropLoaderOpen(false);
          setAlrtMsg(response.message);
          setErrorAlrtOpen(true);
        }
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const editHandling = (data) => {
      setActionId(data.id);
      setSelectedCountry(data.country)
      setSelectedState(data.state)
      setSelectedCity(data.city)
      setValue('question',data.question)
      setValue('answer',data.answer)
      editModHandler(true)
      setIsEditMode(true)
  };


  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(item => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      const allItemIds = listOfFaq.map(item => item.id);
      setSelectedItems(allItemIds);
    } else {
      setSelectedItems([]);
    }
  };

  const FaqDataList =(
    <>
    {countryId !== 0 && <Grid container sx={safetyBlock}>
      <Grid item md={2} sx={safetyText}>
        <Checkbox 
        checked={selectAll} 
        onChange={handleSelectAllChange}
        sx={{color:'black'}}
        />
        <span style={{color:'black'}}>Select All</span>
      </Grid>
      <Grid>
       <ButtonComponent btnText={"Assign to"} item md={10} onClick={()=>{setAssignModal(true);countryId&&GetStatess(countryId)}}/>
      </Grid>
    </Grid>}
    {listOfFaq &&
    listOfFaq.map((item, index) => (
      <ListItem key={index}>
        <Grid container alignItems="center" sx={safetyBlock}>
          <Grid item md={12} component="h2" sx={safetyMainHeading}>
            {index + 1}. {item.question}
          </Grid>
          <Grid item md={10} sx={safetyBorder}>
            <Typography sx={safetyText}>{item.answer}</Typography>
          </Grid>
          <Grid item md={2} container justifyContent={"center"}>
            <IconButton onClick={() => editHandling(item)}>
              <img src={EditIcon} alt="edit" />
            </IconButton>
            <IconButton onClick={() => blockOrDeleteHandleOpen(item.id)}>
              <img src={DeleteIcon} alt="delete" />
            </IconButton>
            {countryId !== 0 && <Grid>
            <Checkbox 
            checked={selectedItems.includes(item.id)} 
            onChange={() => handleCheckboxChange(item.id)}
            sx={{color:'black'}}
            />
            </Grid>}
          </Grid>
        </Grid>
      </ListItem>
    ))}
    </>
  )

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async () => {
    try {
      const payload = {
        type: 2,
        country: selectedCountry,
      };
      const States = await CommonServices.getLocation(payload);
      if (States.status === 1) {
        setStates(States.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };


  const GetStatess = async (country) => {
    try {
      const payload = {
        type: 2,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);
      if (States.status === 1) {
        setStates(States.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (country) => {
    try {
      const payload = {
        type: 3,
        country: selectedCountry,
        state: selectedState,
      };
      const Cities = await CommonServices.getLocation(payload);

      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const assignModalClose = ()=>{
    setAssignModal(false)
   }

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setSelectedCity(null);
    setSelectedStates(value)
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
    setSelectedcities(event.target.value)
  };

  const FaqAdding = async (data) => {
    const payload = {
      countryId:selectedCountry,
      stateId:selectedState,
      cityId:selectedCity,
      question:data.question ,
      answer:data.answer ,
    }
    payload.type = modType;
    let insertion
    if(isEditMode !== true){
      insertion = await ManagementServices.insertFaq(payload);
    }
    else if (isEditMode === true){
      payload.faqId = actionId
      insertion = await ManagementServices.editFaq(payload);
    }
    insertion && resHandler(insertion)
  };

  const handleBulkFaqAdding = async () => {
    const payload = {
      countryId:countryId || selectedCountry,
      stateId:selectedStates,
      cityId:selectedcities,
      faqIds:selectedItems
    }
    const bulkFaqResponse = await ManagementServices.bulkFaqInsert(payload)
      if (bulkFaqResponse?.status === 1) {
        setAlrtMsg(bulkFaqResponse.message)
        setSuccessAlrtOpen(true)
        assignModalClose()
      } else {
        setAlrtMsg(bulkFaqResponse.message)
        setErrorAlrtOpen(true)
      }
  }    
  

  
  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const selectModal = ()=>{
    return (
      <>
      <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
      <Modal open={assignModal}
      onClose={assignModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
      <Box sx={FaqModalStyle}>
      <Typography sx={modalHeading}> 
          select state
      </Typography>
      <Grid container justifyContent={"center"} spacing={3}>
          <Grid item md={10}>
          <FormControl fullWidth>
          <Select
            value={countryId}
            size={"small"}
            displayEmpty
            inputProps={{ "aria-label": "countryId" }}
            onChange={handleChangeCountry}
          >
            <MenuItem value="" disabled>
              Select country
            </MenuItem>
            {totalCountries}
          </Select>
        </FormControl>
        </Grid>
        <Grid item md={10}>
        <FormControl fullWidth>
        <Select
          value={selectedStates || stateId || ""}
          size={"small"}
          displayEmpty
          inputProps={{ "aria-label": "State" }}
          onChange={handleChangeState}
        >
          <MenuItem value="" disabled>
            Select State
          </MenuItem>
          {totalStates}
        </Select>
        </FormControl>
        </Grid>
        <Grid item md={10}>
        <FormControl fullWidth>
        <Select
          value={selectedcities || cityId || ""}
          size={"small"}
          displayEmpty
          inputProps={{ "aria-label": "City" }}
          onChange={handleChangeCity}
        >
          <MenuItem value="" disabled>
            Select City
          </MenuItem>
          {totalCities}
        </Select>
        </FormControl>
        </Grid>
        <Grid item md={10} container justifyContent={"center"} mb={4}>
          <ButtonComponent btnText={"Assign"} onClick={handleBulkFaqAdding}/>
        </Grid>
        </Grid>
      </Box>
      </Modal>
      </>
    )
  }

  React.useEffect(() => {
    if(selectedCountry !== null){
      GetStates();
    }
  }, [selectedCountry]);


  React.useEffect(() => {
    GetCountries()
  }, [countryId])

  React.useEffect(() => {
    if(selectedState !== null){
      GetCities();
    }
  }, [selectedState]);

  React.useEffect(() => {
    GetFaqList();
  }, [modType, countryId, stateId, cityId]);

  React.useEffect(() => {
    setHandleModal(handMod);
    GetFaqList();
    if(handMod){
      GetCountries()
    }else{
      setIsEditMode(false)
      setSelectedCountry(null);
      setSelectedState(null);
      setSelectedCity(null);
      setValue('question',"");
      setValue('answer',"")
    }
  }, [handMod]);


  return (
    <>
      <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
      <Box>
        <List>{listOfFaq.length > 0 ? FaqDataList : <Grid component={'h2'} textAlign={'center'}>No Data Found</Grid>}</List>
      </Box>
      <Modal
        open={handleModal}
        onClose={handleModClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={FaqModalStyle}>
          <Typography sx={modalHeading}>
            {modType === 1
              ? isEditMode
                ? " Edit Consumer FAQ"
                : "Add Consumer FAQ"
              : isEditMode
              ? " Edit Chef FAQ"
              : "Add Chef FAQ"}
          </Typography>
          <Grid component={"form"} onSubmit={handleSubmit(FaqAdding)}>
            <Grid container justifyContent={"center"} spacing={3}>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Country</Grid>
                  <Select
                    sx={CustomField}
                    value={selectedCountry}
                    // name='country'
                    size={"small"}
                    {...register("countryId")}
                    onChange={handleChangeCountry}
                    displayEmpty
                    inputProps={{
                      "aria-label": "countryId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                    // helperText={errors?.country?.message}
                  >
                    <MenuItem value={null} disabled>
                      Select country
                    </MenuItem>
                    {totalCountries}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={10}>
              <FormControl fullWidth>
                  <Grid sx={Emaillable}>State</Grid>
                  <Select
                    sx={CustomField}
                    value={selectedState}
                    size={"small"}
                    {...register("stateId")}
                    onChange={handleChangeState}
                    displayEmpty
                    inputProps={{
                      "aria-label": "stateId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                  >
                    <MenuItem value={null} disabled>
                      Select state
                    </MenuItem>
                    {totalStates}
                  </Select>
                  {/* No required attribute for State */}
                </FormControl>
              </Grid>
              <Grid item md={10}>
              <FormControl fullWidth>
                <Grid sx={Emaillable}>City</Grid>
                <Select
                  sx={CustomField}
                  value={selectedCity}
                  size={"small"}
                  {...register("cityId")}
                  onChange={handleChangeCity}
                  displayEmpty
                  inputProps={{
                    "aria-label": "cityId",
                    sx: { "& input": { color: "#CCCCCC" } },
                  }}
                >
                  <MenuItem value={null} disabled>
                    Select city
                  </MenuItem>
                  {totalCities}
                </Select>
                {/* No required attribute for City */}
              </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Question</Grid>
                  <TextareaAutosize
                    className={"textArea"}
                    aria-label="empty textarea"
                    // name={'description'}
                    // value={onBoardingDetails.description}
                    placeholder="Type your question"
                    {...register("question", {
                      required: true,
                    })}
                    // onChange={handleOnBoardingChanging}
                  />
                  {errors.question && errors.question.type === "required" && (
                    <Typography sx={Errors}>
                      Oops! Please enter question.
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Answer</Grid>
                  <TextareaAutosize
                    className={"textArea"}
                    // name={'description'}
                    // value={onBoardingDetails.description}
                    placeholder="Type your answer"
                    {...register("answer", {
                      required: true,
                    })}
                    // onChange={handleOnBoardingChanging}
                  />
                  {errors.answer && errors.answer.type === "required" && (
                    <Typography sx={Errors}>
                      Oops! Please enter answer.
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid container justifyContent={"center"}  sx={{padding: '20px'}}>
                {isEditMode ? (
                  <ButtonComponent type={"submit"} btnText={"Update"} />
                ) : (
                  <ButtonComponent type={"submit"} btnText={"ADD"} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {selectModal()}
      <BlockOrDeleteModalComponent
        deleteopen={deleteModal}
        resType={handleRes}
        onClose={DeleteModalClose}
        title={"Do you want to Delete ?"}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
    </>
  );
};
