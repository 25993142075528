import React from "react";
import {
  Grid, 
  Table, 
  TableContainer, 
  TableHead, 
  TableBody, 
  TableRow,  
  Paper, 
  TableCell 
} from '@mui/material';
import { BlockOrDeleteModalComponent } from '../components/modal_component';
import { tableContainerStyles,tableContainerParent,paperContainerStyles } from "../styles/common_styles";

export const TableComponent = ({headerData, bodyData}) => {

    const [blockOrDeleteModal,setBlockOrDeleteModal]  = React.useState(false)
    const blockOrDeleteModalCloseModal = () => setBlockOrDeleteModal(false)

    return (
      <>
        <Grid sx={tableContainerParent}>
          <Paper sx={paperContainerStyles}>
            <TableContainer sx={tableContainerStyles}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>{headerData}</TableRow>
                </TableHead>
                {bodyData?.length > 0 ? (
                <TableBody>{bodyData}</TableBody>
                ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={headerData.length}>
                      <Grid component="h2" align="center">
                        No Data Found
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid>
          <BlockOrDeleteModalComponent
            openModal={blockOrDeleteModal}
            handleCloseModal={blockOrDeleteModalCloseModal}
          />
        </Grid>
      </>
    );
}