import React, { useState } from "react";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import { ManagementServices } from "../../services/management_services";

const FileUpload = ({ modalClose, sendData }) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const styles = {
    customButton: {
      display: "inline-block",
      padding: "10px 20px",
      cursor: "pointer",
      backgroundColor: "#007bff",
      color: "#fff",
      borderRadius: "5px",
      margin: "10px 0",
    },
    error: {
      color: "red",
      marginTop: "10px",
    },
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop().toLowerCase();
      if (fileType !== "xlsx" && fileType !== "xls") {
        setErrorMessage("Please upload a valid Excel file (.xlsx or .xls)");
        // Clear the file input
        e.target.value = "";
        setFile(null);
        return;
      }
      setErrorMessage("");
      setFile(selectedFile);
      // You can proceed with further processing of the file here
    }
  };

  const handleFileUpload = () => {
    if (!file) {
      setErrorMessage("Please select a file to upload");
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setData(parsedData);
      sendDataToBackend(parsedData);
      sendData(parsedData);
      //   modalClose();
    };
    reader.readAsBinaryString(file);
  };

  const sendDataToBackend = async (data) => {
    const payload = {
      currencyConversionData: data,
    };
    const response = await ManagementServices.uploadCurrencyConversionRate(
      payload
    );
    if (response.status === 1) {
      modalClose();
    } else {
      setErrorMessage("file should contain name and conversion rate columns");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="fileInput"
      />
      <label
        htmlFor="fileInput"
        style={{
          marginBottom: 10,
          height: "40px",
          backgroundColor: "black",
          color: "white",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        Choose File
      </label>
      {errorMessage && <p style={styles.error}>{errorMessage}</p>}
      {file && <span>{file.name}</span>}
      <Button
        sx={{
          height: "40px",
          backgroundColor: "black",
          color: "white",
          "&:hover": {
            backgroundColor: "black", // Ensuring it stays black on hover
          },
        }}
        onClick={handleFileUpload}
      >
        Upload and Process File
      </Button>
    </div>
  );
};

export default FileUpload;
