import {
  Button,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { TitleComponent } from "../../components/title_component";
import {
  moduleHeadContainer,
  addButton,
  rewardFieldSet,
  rewardsContainer,
  defaultAddButton,
} from "../../styles/common_styles";
import React, { useEffect } from "react";
import { CommonServices } from "../../services/common_services";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { RewardListComponent } from "../../components/reward_list_component";
import { Loader } from "../../components/loader_component";

const Rewards = () => {
  const [handleAdd, setHandleAdd] = React.useState(true);
  const [list, setList] = React.useState([]);
  const [country, setCountry] = React.useState(0);
  const [state, setState] = React.useState(0);
  const [city, setCity] = React.useState(0);
  const [area, setArea] = React.useState(0);
  const [countryList, setCountryList] = React.useState([]);
  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [areaList, setAreaList] = React.useState([]);
  const [consumerPercentage, setConsumerPercentage] = React.useState(0);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [handleAddState, setHandleAddState] = React.useState(false);

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const getRewards = async () => {
    // API call to get rewards
    const rewards = await ManagementServices.RewardsListing();
    if (rewards?.status === 1) {
      setList(rewards?.data);
    }
  };

  const getCountry = async () => {
    // API call to get country list
    const country = await CommonServices.getLocation({ type: 1 });
    if (country.status === 1) {
      setCountryList(country.data);
    }
  };

  const getState = async () => {
    // API call to get state list
    const state = await CommonServices.getLocation({ country });
    if (state.status === 1) {
      setStateList(state.data);
    }
  };

  const getCity = async () => {
    // API call to get city list
    const city = await CommonServices.getLocation({ country, state });
    if (city.status === 1) {
      setCityList(city.data);
    }
  };

  const getArea = async () => {
    // API call to get area list
    const area = await CommonServices.getLocation({ country, state, city });
    if (area.status === 1) {
      setAreaList(area.data);
    }
  };

  const revertFunction = (index) => {
    getRewards();
  };
  const handleAddButton = (data) => {
    setHandleAddState(data);
    setHandleAdd(data);
  };

  const cancelAddButton = () => {
    setHandleAddState(false);
    setHandleAdd(true);
  };

  const rewardsList = list?.map((item, index) => (
    <RewardListComponent
      key={item.id}
      item={item}
      revertFunction={revertFunction}
      toggleButton={handleAddButton}
      cancelToggle={cancelAddButton}
    />
  ));

  useEffect(() => {
    getRewards();
    getCountry();
  }, []);

  // Inside useEffect for country
  useEffect(() => {
    if (country !== 0) {
      getState();
      setState(0);
      setCityList([]);
      setCity(0);
      setAreaList([]);
      setArea(0);
    } else {
      setCityList([]);
      setCity(0);
      setAreaList([]);
      setArea(0);
    }
  }, [country]);

  // Inside useEffect for state
  useEffect(() => {
    if (state !== 0) {
      getCity();
      setCity(0);
      setArea(0);
      setAreaList([]);
    }
  }, [state]);

  // Inside useEffect for city
  useEffect(() => {
    if (city !== 0) {
      getArea();
      setArea(0);
    }
  }, [city]);

  // Rendering dropdown menus
  const countries = countryList?.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  ));
  const states = stateList?.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  ));
  const cities = cityList?.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {item.name}
    </MenuItem>
  ));
  const areas = areaList?.map((item) => (
    <MenuItem key={item.area_code} value={item.area_code}>
      {item.area_code}
    </MenuItem>
  ));

  const handleSave = async () => {
    if (country === 0) {
      setAlrtMsg("You have to select country to save Reward");
      setErrorAlrtOpen(true);
      return;
    }
    const payload = {
      consumerPercentage: consumerPercentage,
      countryId: country,
      stateId: state,
      cityId: city,
      areaCode: area ? area : "",
    };
    const save = await ManagementServices.InsertRewards(payload);
    if (save?.status === 1) {
      getRewards();
      setHandleAdd(true);
      setAlrtMsg(save?.message);
      setSuccessAlrtOpen(true);
      setHandleAddState(false);
      setConsumerPercentage(0);
      setCountry(0);
      setState(0);
      setCity(0);
      setArea("");
    } else {
      setAlrtMsg(save?.message);
      setErrorAlrtOpen(true);
    }
  };
  return (
    <>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={moduleHeadContainer}
      >
        <Grid item>
          <TitleComponent title={"Rewards Credit"} />
        </Grid>
        <Grid item>
          <Button
            sx={handleAddState ? defaultAddButton : addButton}
            disabled={handleAddState}
            onClick={() => {
              setHandleAdd(false);
              setHandleAddState(true);
            }}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid sx={rewardsContainer}>
        {!handleAdd && (
          <Grid container>
            <Grid item md={2.2}>
              <Grid container justifyContent={"space-around"}>
                <Grid item md={5}>
                  <FormControl fullWidth>
                    <Grid mb={1}>Admin%</Grid>
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      value={"100%"}
                      sx={{
                        ...rewardFieldSet,
                      }}
                      inputProps={{
                        placeholder: "Name",
                        style: { textAlign: "center" },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={5}>
                  <FormControl fullWidth>
                    <Grid mb={1}>Consumer%</Grid>
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      value={consumerPercentage}
                      sx={{
                        ...rewardFieldSet,
                      }}
                      onChange={(e) => setConsumerPercentage(e.target.value)}
                      inputProps={{
                        placeholder: "percent",
                        min: 1,
                        max: 100,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={7.3}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FormControl fullWidth>
                    <Grid mb={1}>Country</Grid>
                    <Select
                      className={"FilterStyle"}
                      value={country}
                      size={"small"}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <MenuItem value={0}>select country</MenuItem>
                      {countries}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <FormControl fullWidth>
                    <Grid mb={1}>State</Grid>
                    <Select
                      className={"FilterStyle"}
                      value={state}
                      size={"small"}
                      onChange={(e) => setState(e.target.value)}
                    >
                      <MenuItem value={0}>select state</MenuItem>
                      {stateList && states}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <FormControl fullWidth>
                    <Grid mb={1}>City</Grid>
                    <Select
                      className={"FilterStyle"}
                      value={city}
                      size={"small"}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      <MenuItem value={0}>select city</MenuItem>
                      {cityList && cities}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <FormControl fullWidth>
                    <Grid mb={1}>Area</Grid>
                    <Select
                      className={"FilterStyle"}
                      value={area}
                      size={"small"}
                      onChange={(e) => setArea(e.target.value)}
                    >
                      <MenuItem value={0}>select area</MenuItem>
                      {areaList && areas}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2.5}>
              <Grid container mt={4}>
                <Grid item pl={1.5}>
                  <Button sx={addButton} onClick={handleSave}>
                    Save
                  </Button>
                </Grid>
                <Grid item pl={1.5}>
                  <Button
                    sx={addButton}
                    onClick={() => {
                      setHandleAdd(true);
                      setHandleAddState(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {list?.length === 0 && handleAdd ? (
          <Grid
            variant="body1"
            component={"h2"}
            sx={{ textAlign: "center", marginTop: 2 }}
          >
            No Data Found
          </Grid>
        ) : (
          // <Loader />
          rewardsList
        )}
      </Grid>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};

export default Rewards;
