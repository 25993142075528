import React from "react";
import * as XLSX from "xlsx";
import { ButtonComponent } from "../../components/form_components/button";
import { ManagementServices } from "../../services/management_services";

const DownloadToExcel = () => {
  const [data, setData] = React.useState([]);

  const handleCurrenceyData = async () => {
    const payload = {
      min: 1,
      max: 10,
      search: "",
      type: "excelDownload",
    };
    const getTaxCurrency = await ManagementServices.getcountryCurrency(payload);
    console.log(getTaxCurrency, "getTaxCurrency");
    if (getTaxCurrency.status === 1) {
      setData(getTaxCurrency.data);
    }
  };



  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");

    XLSX.writeFile(wb, "data.xlsx");
  };

  React.useEffect(() => {
    handleCurrenceyData();
  }, []);

  //   return <button onClick={handleDownload}>Download Excel</button>;
  return (
    <>
      <ButtonComponent btnText={"Download"} onClick={handleDownload} />
    </>
  );
};

export default DownloadToExcel;
