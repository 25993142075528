import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  TextareaAutosize,
  ListItem,
  Box,
  List,
  Modal,
  Checkbox,
  Button,
} from "@mui/material";
import { Emaillable, CustomField } from "../styles/loginstyles";
import { ButtonComponent } from "./form_components/button";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaqModalStyle } from "../styles/common_styles";
import {
  safetyHeading,
  safetyText,
  safetyMainHeading,
  safetyBorder,
  safetyBlock,
  Errors,
  modalStyle,
  modalHeading,
} from "../styles/common_styles";
import EditIcon from "../assets/icons/edit.svg";
import DeleteIcon from "../assets/icons/delete.svg";
import DragIcon from "../assets/icons/drag.svg";
import { CommonServices } from "../services/common_services";
import { ManagementServices } from "../services/management_services";
import { useForm } from "react-hook-form";
import { SuccessAlert, ErrorAlert } from "./alert";
import { BlockOrDeleteModalComponent } from "./modal_component";
import { ReOdering } from "../utils/helpers";
import { BackDropLoader } from "./loader_component";
import { Loader } from "./loader_component";
export const OnBoardingHandling = ({
  fromModule,
  country,
  state,
  type,
  open,
  handleClose: handleCloseProp,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [backDropLoaderOpen, setBackDropLoaderOpen] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const [onBoardingList, setOnBoardingList] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [countries, setCountries] = React.useState([]);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [actionId, setActionId] = React.useState("");
  const [editId, setEditId] = React.useState("");
  const [updateButton, setUpdateButton] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [states, setStates] = React.useState([]);
  const [selectedStates, setSelectedStates] = React.useState(null);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [loaderData, setLoaderData] = React.useState(true);
  const [onBoardingDetails, setOnBoardingDetails] = React.useState({
    country: null,
    state: null,
    information: "",
    title: "",
    heading: "",
    type: "",
    description: "",
  });

  const handleModalOpen = (item) => {
    setModalOpen(true);
    editIconData(item);
    setIsEditMode(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    editIconData("");
    setIsEditMode(false);
    handleCloseProp();
    setUpdateButton(false);
    setOnBoardingDetails({
      ...onBoardingDetails,
      country: null,
      state: null,
      information: "",
      title: "",
      heading: "",
      description: "",
      type: "",
    });
    reset({
      information: "",
      title: "",
      heading: "",
      description: "",
      country: null,
      state: null,
    });
  };

  const DeleteModalClose = () => {
    setDeleteModal(false);
  };

  const blockOrDeleteHandleOpen = (id) => {
    setActionId(id);
    setDeleteModal(true);
  };

  const handleToggleBackDropLoader = () => {
    setBackDropLoaderOpen(!backDropLoaderOpen);
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setOnBoardingDetails({
      ...onBoardingDetails,
      country: selectedCountry,
      state: null,
    });
    GetStates(2, selectedCountry); // Assuming type 2 is for states
  };
  const handleChangeState = (event) => {
    const selectedState = event.target.value;
    setOnBoardingDetails({
      ...onBoardingDetails,
      state: selectedState,
    });
    setSelectedStates(event.target.value);
  };

  const handleChangeStatenull = (event) => {
    const selectedState = event.target.value;
    setOnBoardingDetails({
      ...onBoardingDetails,
      state: null,
    });
    setSelectedStates(null);
  };
  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setOnBoardingDetails({
      ...onBoardingDetails,
      type: selectedType,
    });
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const handleOnDragEnd = (result) => {
    if (country === 0) return;
    if (!result.destination) return;
    const items = Array.from(onBoardingList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setOnBoardingList(items);
    // handleupdatestep()
  };

  const reOderingBoardingList = async () => {
    try {
      const Data = await ReOdering(onBoardingList);
      const updatedList = await ManagementServices.updateOnboardingSteps(Data);
      if (updatedList.status !== 1) {
        setAlrtMsg(updatedList.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  // const handleupdatestep = () => {
  //   reOderingBoardingList();
  // };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country, state) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const ConsumerOnBoardingadding = async (data) => {
    setBackDropLoaderOpen(true);

    const commonPayload = {
      info: data.information,
      title: data.title,
      heading: data.heading,
      description: data.description,
      countryId: data.country,
      stateId: data.state === "" ? null : data.state,
      type: data.type,
    };

    const resetStateAndSetAlert = (message, success) => {
      setBackDropLoaderOpen(false);
      // handleClose();
      setAlrtMsg(message);
      success ? setSuccessAlrtOpen(true) : setErrorAlrtOpen(true);
      setOnBoardingDetails({
        ...onBoardingDetails,
        country: null,
        state: null,
        information: "",
        title: "",
        heading: "",
        description: "",
        type: "",
      });
    };

    if (updateButton === true) {
      const editPayload = {
        ...commonPayload,
        onboardingId: editId,
        countryId: onBoardingDetails.country,
        stateId:
          onBoardingDetails.state === "" ? null : onBoardingDetails.state,
        typeId: onBoardingDetails.type,
      };
      const editOnBoard = await ManagementServices.editOnboarding(editPayload);
      if (editOnBoard?.status === 1) {
        handleClose(true);
        resetStateAndSetAlert(editOnBoard.message, true);
        setValue("information", "");
        setValue("title", "");
        setValue("heading", "");
        setValue("description", "");
        setEditId("");
        setOnBoardingDetails({
          ...onBoardingDetails,
          country: null,
          state: null,
          type: "",
        });
        GettingListOfOnBoarding();
        setUpdateButton(false);
      }
      return;
    }

    try {
      let response;
      if (fromModule === "1") {
        // setLoaderData(true);
        handleClose();
        response = await ManagementServices.consumerOnBoarding(commonPayload);
        if (response.status === 1) {
          // setLoaderData(false);
        }
      } else {
        handleClose();
        response = await ManagementServices.chefOnBoarding(commonPayload);
        // setLoaderData(true);
        if (response.status === 1) {
          // setLoaderData(false);
        }
      }
      if (response.status === 1) {
        setValue("information", "");
        setValue("title", "");
        setValue("heading", "");
        setValue("description", "");
        setEditId("");
        setOnBoardingDetails({
          ...onBoardingDetails,
          country: null,
          state: null,
          type: "",
        });
      }
      GettingListOfOnBoarding();
      resetStateAndSetAlert(response.message, response.status === 1);
    } catch (error) {
      resetStateAndSetAlert("An error occurred", false);
    }
  };

  const GettingListOfOnBoarding = async () => {
    const payload = {
      type: fromModule,
      countryId: country,
      stateId: state,
      typeId: type,
    };
    const response = await ManagementServices.getOnBoardingList(payload);
    if (response?.status === 1) {
      setOnBoardingList(response?.data.onboardingDetails);
      setLoaderData(false);
    } else {
      setAlrtMsg(response?.message);
      setErrorAlrtOpen(true);
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      const allItemIds = onBoardingList.map((item) => item.id);
      setSelectedItems(allItemIds);
    } else {
      setSelectedItems([]);
    }
  };

  const GettingOnBoardingList = (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          {country !== 0 && (
            <Grid container sx={safetyBlock}>
              <Grid item md={2} sx={safetyText}>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  sx={{ color: "black" }}
                  disabled={country === 0}
                />
                <span style={{ color: "black" }}>Select All</span>
              </Grid>
              <Grid>
                <ButtonComponent
                  btnText={"Assign to"}
                  item
                  md={10}
                  onClick={() => {
                    setAssignModal(true);
                    GetStates(2, country);
                  }}
                />
              </Grid>
            </Grid>
          )}
          {onBoardingList.length > 0 ? (
            onBoardingList.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                      cursor: snapshot.isDragging ? "grabbing" : "grab",
                    }}
                  >
                    <Grid container alignItems="center" sx={safetyBlock}>
                      <Grid item md={12} sx={safetyText}>
                        {item.info}
                      </Grid>
                      <Grid item md={12} component="h2" sx={safetyMainHeading}>
                        {index + 1}.{item.heading}
                      </Grid>
                      <Grid item md={10} sx={safetyBorder}>
                        <Typography sx={safetyHeading} component="p">
                          {item.title}
                        </Typography>
                        <Typography sx={safetyText}>
                          {item.description}
                        </Typography>
                      </Grid>
                      <Grid item md={2} container justifyContent={"center"}>
                        <IconButton onClick={() => handleModalOpen(item)}>
                          <img src={EditIcon} alt="edit" />
                        </IconButton>
                        <IconButton
                          onClick={() => blockOrDeleteHandleOpen(item.id)}
                        >
                          <img src={DeleteIcon} alt="delete" />
                        </IconButton>
                        {country !== 0 && (
                          <Grid
                            alignItems={"center"}
                            p={1}
                            {...provided.dragHandleProps}
                          >
                            <img src={DragIcon} alt="drag" />
                          </Grid>
                        )}
                        {country !== 0 && (
                          <Grid>
                            <Checkbox
                              checked={selectedItems.includes(item.id)}
                              onChange={() => handleCheckboxChange(item.id)}
                              sx={{ color: "black" }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                )}
              </Draggable>
            ))
          ) : (
            <Typography
              sx={{ fontWeight: 700, textAlign: "center", fontSize: 24 }}
            >
              No data found
            </Typography>
          )}
        </>
      )}
    </>
  );

  const handleRes = async (data) => {
    try {
      if (data === 1) {
        DeleteModalClose();
      } else {
        setBackDropLoaderOpen(true);
        const response = await ManagementServices.onBoardingItemDelete(
          actionId,
          fromModule
        );
        if (response.status === 1) {
          setBackDropLoaderOpen(false);
          setAlrtMsg(response.message);
          setSuccessAlrtOpen(true);
          DeleteModalClose();
          setTimeout(() => {
            GettingListOfOnBoarding();
          }, 1500);
        } else {
          setBackDropLoaderOpen(false);
          setAlrtMsg(response.message);
          setErrorAlrtOpen(true);
        }
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const editIconData = (details) => {
    setEditId(details.id);
    setOnBoardingDetails({
      ...onBoardingDetails,
      country: details?.country_id,
      state: details?.state_id,
      type: details.type,
    });
    GetStates(2, details?.country_id);
    setValue("information", details.info);
    setValue("title", details.title);
    setValue("heading", details.heading);
    setValue("description", details.description);
    setUpdateButton(true);
  };

  const handleAssignState = async () => {
    if (fromModule === 1 || fromModule === "1") {
      const payload = {
        countryId: country,
        stateId: selectedStates,
        onboardingIds: selectedItems,
      };
      const bulkOnboardingRes =
        await ManagementServices.bulkOnboardingInsertConsumer(payload);
      if (bulkOnboardingRes?.status === 1) {
        setAlrtMsg(bulkOnboardingRes.message);
        setSuccessAlrtOpen(true);
        assignModalClose();
      } else {
        setAlrtMsg(bulkOnboardingRes.message);
        setErrorAlrtOpen(true);
      }
    } else {
      const payload = {
        countryId: country,
        stateId: selectedStates,
        onboardingIds: selectedItems,
      };
      const bulkOnboardingRes =
        await ManagementServices.bulkOnboardingInsertChef(payload);
      if (bulkOnboardingRes?.status === 1) {
        setAlrtMsg(bulkOnboardingRes.message);
        setSuccessAlrtOpen(true);
        assignModalClose();
      } else {
        setAlrtMsg(bulkOnboardingRes.message);
        setErrorAlrtOpen(true);
      }
    }
  };

  const assignModalClose = () => {
    setAssignModal(false);
  };

  const selectModal = () => {
    return (
      <>
        <BackDropLoader
          backDropLoaderopen={backDropLoaderOpen}
          BackDropLoaderHandleClose={handleToggleBackDropLoader}
        />
        <Modal
          open={assignModal}
          onClose={assignModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={FaqModalStyle}>
            <Typography sx={modalHeading}>select state</Typography>
            <Grid container justifyContent={"center"} spacing={3}>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Select
                    value={country}
                    size={"small"}
                    disabled
                    displayEmpty
                    inputProps={{ "aria-label": "Country" }}
                    onChange={handleCountryChange}
                  >
                    <MenuItem value="" disabled>
                      Select country
                    </MenuItem>
                    {totalCountries}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Select
                    value={selectedStates || ""}
                    size={"small"}
                    displayEmpty
                    inputProps={{ "aria-label": "State" }}
                    onChange={handleChangeState}
                  >
                    <MenuItem value="" onClick={handleChangeStatenull}>
                      Select state
                    </MenuItem>
                    {totalStates}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={10} container justifyContent={"center"} mb={4}>
                <ButtonComponent
                  btnText={"Assign"}
                  type={"button"}
                  onClick={handleAssignState}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </>
    );
  };

  useEffect(() => {
    if (country !== 0) reOderingBoardingList();
  }, [onBoardingList]);

  useEffect(() => {
    GettingListOfOnBoarding();
  }, [country, state]);

  React.useEffect(() => {
    GetCountries();
    GettingListOfOnBoarding();
  }, [fromModule]);

  return (
    <>
      <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
      <Modal
        open={open || modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography sx={modalHeading}>
            {fromModule === "1"
              ? isEditMode
                ? "Edit consumer onboarding"
                : "Add consumer onboarding"
              : isEditMode
              ? "Edit chef onboarding"
              : "Add chef onboarding"}
          </Typography>
          <Grid
            component={"form"}
            onSubmit={handleSubmit(ConsumerOnBoardingadding)}
          >
            {/* {loaderData ? (
              <Loader />
            ) : (
              <> */}
            <Grid container justifyContent={"center"} spacing={3} pl={4} pr={4}>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Country</Grid>
                  <Select
                    sx={CustomField}
                    value={onBoardingDetails.country}
                    // name='country'
                    size={"small"}
                    {...register("country", {
                      required:
                        !onBoardingDetails.country ||
                        onBoardingDetails.country === null ||
                        onBoardingDetails.country === "",
                      message: "Please enter country",
                    })}
                    onChange={handleCountryChange}
                    displayEmpty
                    inputProps={{
                      "aria-label": "country",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                    // helperText={errors?.country?.message}
                  >
                    <MenuItem value={null} disabled>
                      Select country
                    </MenuItem>
                    {totalCountries}
                  </Select>
                  {onBoardingDetails.country === null &&
                    errors.country &&
                    errors.country.type === "required" && (
                      <Typography sx={Errors}>
                        Please select country.
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>State</Grid>
                  {Object.keys(onBoardingDetails).length > 0 && (
                    <Select
                      sx={CustomField}
                      value={onBoardingDetails?.state}
                      size={"small"}
                      {...register("state")}
                      onChange={handleChangeState}
                      displayEmpty
                      inputProps={{
                        "aria-label": "state",
                        sx: { "& input": { color: "#CCCCCC" } },
                      }}
                    >
                      <MenuItem
                        value={null}
                        onClick={() => setSelectedStates(null)}
                      >
                        Select state
                      </MenuItem>
                      {totalStates}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Information</Grid>
                  <TextField
                    sx={CustomField}
                    fullWidth
                    size={"small"}
                    type="text"
                    inputProps={{
                      placeholder: "Text here",
                    }}
                    {...register("information", {
                      required: "Please enter information",
                      maxLength: {
                        value: 255,
                        message: "Characters should be less than 256",
                      },
                    })}
                  />
                  {errors.information && (
                    <Typography sx={Errors}>
                      {errors?.information?.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Title</Grid>
                  <TextField
                    sx={CustomField}
                    fullWidth
                    size={"small"}
                    type="text"
                    inputProps={{
                      placeholder: "Text here",
                    }}
                    {...register("title", {
                      required: "Please enter title",
                      maxLength: {
                        value: 255,
                        message: "Characters should be less than 256",
                      },
                    })}
                  />
                  {errors.title && (
                    <Typography sx={Errors}>
                      {errors?.title?.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Heading</Grid>
                  <TextField
                    sx={CustomField}
                    size={"small"}
                    type={"text"}
                    inputProps={{
                      placeholder: "Text here",
                    }}
                    {...register("heading", {
                      required: "Please enter heading",
                      maxLength: {
                        value: 255,
                        message: "Characters should be less than 256",
                      },
                    })}
                  />
                  {errors.heading && (
                    <Typography sx={Errors}>
                      {errors?.heading?.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Type</Grid>
                  <Select
                    sx={CustomField}
                    value={onBoardingDetails?.type}
                    size={"small"}
                    {...register("type", {
                      required:
                        !onBoardingDetails?.type ||
                        onBoardingDetails?.type === null ||
                        onBoardingDetails?.type === "",
                      message: "Please enter type",
                    })}
                    onChange={handleTypeChange}
                    displayEmpty
                    inputProps={{
                      "aria-label": "type",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                  >
                    <MenuItem value={""} disabled>
                      Select type
                    </MenuItem>
                    <MenuItem value={1}>Mandotory</MenuItem>
                    <MenuItem value={2}>Optional</MenuItem>
                  </Select>
                  {onBoardingDetails?.type === "" &&
                    errors.type &&
                    errors.type.type === "required" && (
                      <Typography sx={Errors}>Please select type.</Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Description</Grid>
                  <TextareaAutosize
                    className={"textArea"}
                    aria-label="empty textarea"
                    placeholder="Text here"
                    {...register("description", {
                      required: "Please enter description",
                    })}
                  />
                  {errors.description && (
                    <Typography sx={Errors}>
                      {errors?.description?.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid container justifyContent={"center"} p={3}>
                {updateButton ? (
                  <ButtonComponent type={"submit"} btnText={"UPDATE"} />
                ) : (
                  <ButtonComponent type={"submit"} btnText={"ADD"} />
                )}
              </Grid>
            </Grid>
            {/* </>
            )} */}
          </Grid>
        </Box>
      </Modal>
      <Grid>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <List
                className="characters"
                {...provided.droppableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                {GettingOnBoardingList}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      <BlockOrDeleteModalComponent
        deleteopen={deleteModal}
        resType={handleRes}
        onClose={DeleteModalClose}
        title={"Do you want to Delete ?"}
      />
      {selectModal()}
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};
