import React, { useEffect } from "react";
import { StorageClient } from "../utils/helpers";
import { useNavigate } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const Navigate = useNavigate();
  const currentPath = window.location.pathname;
  const privilagePath = currentPath.split("/")[1];
  const privilegedRoutes = StorageClient.getItem("rolePrivileges");
  const isPrivilegedRoute = privilegedRoutes && privilegedRoutes.some(
    (route) => route.path === `/${privilagePath}`
  );

  useEffect(() => {
    if (!isPrivilegedRoute) {
      if (privilegedRoutes) {
        Navigate(`${privilegedRoutes[0].path}`);
      } else {
        Navigate(`/login`);
      }
    }
  }, [isPrivilegedRoute, privilegedRoutes, Navigate]);

  return isPrivilegedRoute ? <Component {...rest} /> : null;
};