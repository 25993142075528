import React from "react";
import {
  Grid,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Modal,
  Box,
} from "@mui/material";
import {
  moduleHeadContainer,
  tableParentHolder,
} from "../../styles/common_styles";
import { TitleComponent } from "../../components/title_component";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import { CommonServices } from "../../services/common_services";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OrderModalComponent from "../../components/order_modal_component";
import { TableComponent } from "../../components/table_component";
import { EmaillableTwo } from "../../styles/loginstyles";
import { tableHeadCell } from "../../styles/common_styles";
import { HFPagination } from "../../components/pagination";
import { TableCellComponent } from "../../components/table_cell_component";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import { Loader } from "../../components/loader_component";
import { SpanStyle, roleDetailStyle } from "../../styles/common_styles";
import "../../styles/common_styles.css";
const OrdersReportMessage = () => {
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [selectedState, setSelectedState] = React.useState(0);
  const [selectedCity, setSelectedCity] = React.useState(0);
  const [orderStatus, setOrderStatus] = React.useState(null);
  const [searchData, setSearchData] = React.useState("");
  const [orderReportMessageData, setOrderReportMessageData] = React.useState(
    []
  );
  const [orderId, setOrderId] = React.useState("");
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [loaderData, setLoaderData] = React.useState(true);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [viewMessageDetails, setViewMessageDetails] = React.useState({});
  const [chatViewModal, setChatViewModal] = React.useState(false);
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const getMessageViewDetails = async (id) => {
    const payload = {
      msgId: id,
    };
    const messageViewDetailsResponse =
      await ManagementServices.OrderMessageViewDetails(payload);
    if (messageViewDetailsResponse.status === 1) {
      setViewMessageDetails(messageViewDetailsResponse.data);
      setAlrtMsg(messageViewDetailsResponse.message);
    } else {
      setAlrtMsg(messageViewDetailsResponse.message);
      setErrorAlrtOpen(true);
      setLoaderData(false);
    }
  };

  const openChatViewModal = async (id) => {
    setChatViewModal(true);
    await getMessageViewDetails(id);
  };

  const closeChatViewModal = () => {
    setChatViewModal(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };
  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const handleOpenModal = (id) => {
    setIsModalOpen(true);
    setOrderId(id);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };
  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, value);
    setSelectedState(0);
    setSelectedCity(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setSelectedCity(0);
    GetCities(3, event.target.value, selectedCountry);
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  const getTotalIncompleteOrders = async () => {
    try {
      const payload = {
        min: pageConfig.min,
        max: pageConfig.max,
        search: searchData,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
        fromDate: startDate,
        toDate: endDate,
      };
      const incompleteOrders =
        await ManagementServices.OrderMessageDetailsReports(payload);
      if (incompleteOrders.status === 1) {
        setOrderReportMessageData(incompleteOrders.data.reportedMessages);
        setPaginationData({
          ...paginationData,
          currentPage: incompleteOrders.data.currentPage,
          RecordsForPage: incompleteOrders.data.pageSize,
          totalRecords: incompleteOrders.data.totalReports,
          pageCount: incompleteOrders.data.totalPages,
        });
        setLoaderData(false);
        // setAlrtMsg(incompleteOrders.message);
        // setSuccessAlrtOpen(true);
      } else {
        setErrorAlrtOpen(incompleteOrders.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  let tableHeaders = [
    "S no",
    "Consumer Name",
    "Consumer Phone",
    "Chef Name",
    "Reported Date",
    "Consumer Reported Message",
    "Actions",
  ];

  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const TableBody = orderReportMessageData?.map((data, index) => {
    return (
      <TableRow key={data.id}>
        <TableCellComponent
          value={
            (paginationData.currentPage - 1) * paginationData.RecordsForPage +
            1 +
            index
          }
          indexColoumn={true}
        />
        <TableCellComponent value={data.consumer_first_name} />
        <TableCellComponent value={data.consumer_mobile} />
        <TableCellComponent value={data.chef_first_name} />
        <TableCellComponent value={formatDate(data.report_date)} />
        <TableCellComponent value={data.report_message} />
        <TableCell>
          <IconButton onClick={() => openChatViewModal(data.id)}>
            <VisibilityIcon sx={{ color: "black" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  const messageViewModal = () => {
    return (
      <Modal
        open={chatViewModal}
        onClose={closeChatViewModal}
        aria-labelledby="chat-modal-title"
        aria-describedby="chat-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "fit-content",
            height: 350,
            bgcolor: "background.paper",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
            outline: 0,
            borderRadius: 5,
          }}
        >
          <Box
            sx={{
              height: "65vh",
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
            }}
          >
            <Grid sx={{ textAlign: "center", fontWeight: "bold" }}>
              Reported Detail Message
            </Grid>
            <Grid container>
              <Grid item md={6} container mt={2}>
                <Grid item md={5} sx={SpanStyle}>
                  Consumer Nameeee
                </Grid>
                <Grid item md={1}>
                  :
                </Grid>
                <Grid item md={6}>
                  {viewMessageDetails.consumer_msg_id?.first_name}
                </Grid>
              </Grid>
              <Grid item md={6} container mt={2}>
                <Grid item md={5} sx={SpanStyle}>
                  Chef Name
                </Grid>
                <Grid item md={1}>
                  :
                </Grid>
                <Grid item md={6}>
                  <Grid>{viewMessageDetails.chef_msg_id?.first_name}</Grid>
                </Grid>
              </Grid>
              <Grid item md={6} container mt={2}>
                <Grid item md={5} sx={SpanStyle}>
                  ConsumerEmail
                </Grid>
                <Grid item md={1}>
                  :
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ wordBreak: "break-all", whiteSpace: "pre-line" }}
                >
                  {viewMessageDetails.consumer_msg_id?.email}
                </Grid>
              </Grid>
              <Grid item md={6} container mt={2}>
                <Grid item md={5} sx={SpanStyle}>
                  ChefEmail
                </Grid>
                <Grid item md={1}>
                  :
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ wordBreak: "break-all", whiteSpace: "pre-line" }}
                >
                  {viewMessageDetails.chef_msg_id?.email}
                </Grid>
              </Grid>
              <Grid item md={6} container mt={2}>
                <Grid item md={5} sx={SpanStyle}>
                  Consumer Ph No
                </Grid>
                <Grid item md={1}>
                  :
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ wordBreak: "break-all", whiteSpace: "pre-line" }}
                >
                  {viewMessageDetails.consumer_msg_id?.mobile}
                </Grid>
              </Grid>
              <Grid item md={6} container mt={2}>
                <Grid item md={5} sx={SpanStyle}>
                  Chef Ph No
                </Grid>
                <Grid item md={1}>
                  :
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ wordBreak: "break-all", whiteSpace: "pre-line" }}
                >
                  {viewMessageDetails.chef_msg_id?.mobile}
                </Grid>
              </Grid>
              <Grid item md={6} container mt={2}>
                <Grid item md={5} sx={SpanStyle}>
                  Consumer Reported Date
                </Grid>
                <Grid item md={1}>
                  :
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ wordBreak: "break-all", whiteSpace: "pre-line" }}
                >
                  {viewMessageDetails.report_date}
                </Grid>
              </Grid>
              <Grid item md={6} container mt={2}>
                <Grid item md={5}>
                  {/* Chef Ph No */}
                </Grid>
                <Grid item md={1}>
                  {/* : */}
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ wordBreak: "break-all", whiteSpace: "pre-line" }}
                >
                  {/* {viewMessageDetails.chef_msg_id?.mobile} */}
                </Grid>
              </Grid>
              <Grid item md={12} container mt={2}>
                <Grid item md={2.5} sx={SpanStyle}>
                  Consumer Reported Message
                </Grid>
                <Grid item md={0.5}>
                  :
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ wordBreak: "break-all", whiteSpace: "pre-line" }}
                >
                  {viewMessageDetails.report_message}
                </Grid>
              </Grid>
              <Grid item md={12} container mt={2}>
                <Grid item md={2.5} sx={SpanStyle}>
                  Chef Message
                </Grid>
                <Grid item md={0.5}>
                  :
                </Grid>
                <Grid
                  item
                  md={7}
                  // sx={{ wordBreak: "break-all", whiteSpace: "pre-line" }}
                >
                  {viewMessageDetails.message}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    );
  };

  React.useEffect(() => {
    GetCountries();
    getTotalIncompleteOrders();
  }, [
    selectedCountry,
    selectedState,
    selectedCity,
    pageConfig.min,
    searchData,
    orderStatus,
  ]);

  React.useEffect(() => {
    getTotalIncompleteOrders();
  }, [orderStatus, startDate, endDate]);

  return (
    <>
      <Grid container alignItems={"center"} sx={moduleHeadContainer}>
        <Grid item md={3}>
          <TitleComponent title={"Reported Chef Message"} />
        </Grid>
        <Grid item md={9}>
          <Grid container alignItems={"center"} justifyContent={"end"} gap={1}>
            <Grid item md={1.9}>
              <TextField
                type="date"
                size="small"
                InputProps={{
                  placeholder: "startDate",
                }}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item md={1.9}>
              <TextField
                type="date"
                size="small"
                InputProps={{
                  placeholder: "startDate",
                }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  getTotalIncompleteOrders();
                }}
              ></TextField>
            </Grid>
            <Grid item md={2}>
              <SearchFieldComponent searchText={handleSearchData} />
            </Grid>
            <Grid md={1.3}>
              <FormControl fullWidth>
                <Select
                  value={selectedCountry !== null ? selectedCountry : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "Country" }}
                  onChange={handleChangeCountry}
                >
                  <MenuItem value={0} disabled>
                    Select country
                  </MenuItem>
                  {totalCountries}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1.3}>
              <FormControl fullWidth>
                <Select
                  value={selectedState !== null ? selectedState : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "State" }}
                  onChange={handleChangeState}
                >
                  <MenuItem value={0} disabled>
                    Select State
                  </MenuItem>
                  {totalStates}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1.5}>
              <FormControl fullWidth>
                <Select
                  value={selectedCity !== null ? selectedCity : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "City" }}
                  onChange={handleChangeCity}
                >
                  <MenuItem value={0} disabled>
                    Select City
                  </MenuItem>
                  {totalCities}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loaderData ? (
        <Loader />
      ) : (
        <Grid sx={tableParentHolder}>
          <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />

          <HFPagination details={paginationData} paginate={handlePaginate} />
        </Grid>
      )}
      {messageViewModal()}
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};

export default OrdersReportMessage;
