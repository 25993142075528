import Snackbar from "@mui/joy/Snackbar";
import React from "react";

//Position of the alert to show on screen
const vertical = "top";
const horizontal = "right";

// will handle the success alert for whole application
/**
 * Renders a success alert component.
 *
 * @param {Object} props - The props object.
 * @param {string} props.alertMsg - The message to display in the alert.
 * @param {boolean} props.open - Whether the alert is open or not.
 * @param {function} props.close - The function to call when the alert is closed.
 * @return {JSX.Element} The rendered success alert component.
 */
export const SuccessAlert = ({ alertMsg, open, close }) => {
  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        variant={"soft"}
        color={"success"}
        onClose={(reason) => {
          if (reason === "clickaway") {
            return;
          }
          close();
        }}
      >
        {alertMsg}
      </Snackbar>
    </>
  );
};

//will handle the error alert for whole application
/**
 * Renders an error alert component.
 *
 * @param {Object} props - The props object.
 * @param {string} props.alertMsg - The message to display in the alert.
 * @param {boolean} props.open - Whether the alert is open or not.
 * @param {function} props.close - The function to call when the alert is closed.
 * @return {JSX.Element} The rendered error alert component.
 */
export const ErrorAlert = ({ alertMsg, open, close }) => {
  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        variant={"soft"}
        color={"danger"}
        onClose={(reason) => {
          if (reason === "clickaway") {
            return;
          }
          close();
        }}
      >
        {alertMsg}
      </Snackbar>
    </>
  );
};
