import { Card, CardMedia } from "@mui/material";
import logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

export const LogoCard = () => {
  
  const Navigate = useNavigate();
  const handleClick = () => {
    Navigate('/');
  } 

  return (
    <Card sx={{ width: 150, height: 100, padding: 2, boxShadow: "none" }}>
      <CardMedia
        onClick={handleClick}
        sx={{ height: "100%", width: "100%", cursor: "pointer" }}
        image={logo}
      />
    </Card>
  );

};
