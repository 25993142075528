import React from "react";
import {
  Typography,
  Grid,
  Button,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { ManagementServices } from "../services/management_services";
import { useNavigate } from "react-router-dom";
import { StorageClient } from "../utils/helpers";

export const NotificationPopover = ({
  anchorEl,
  handleClose,
  notifications,
}) => {
  const navigate = useNavigate();
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const [NotificationData, setNotificationsData] = React.useState("");
  const openProfile = Boolean(anchorElProfile);

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
    getNotification();
  };

  const getNotification = async () => {
    try {
      const Notifications = await ManagementServices.getNotificationCountFunc();
      if (Notifications.status === 1) {
        setNotificationsData(Notifications.data);
        notifications(Notifications.data.totalCount);
      }
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
  };

  const handleViewClick = (item) => {
    const updateNotificationCount =
      ManagementServices.updateNoificationStatus(item);
    switch (item) {
      case "2":
        StorageClient.setItem("previlageId", item);
        navigate("/globalOperations");
        break;
      case "3":
        StorageClient.setItem("previlageId", item);
        navigate("/superAdmin");
        break;
      case "4":
        StorageClient.setItem("previlageId", item);
        navigate("/manager");
        break;
      case "5":
        StorageClient.setItem("previlageId", item);
        navigate("/supervisor");
        break;
      case "6":
        StorageClient.setItem("previlageId", item);
        navigate("/csr");
        break;
      case "7":
        navigate("/tickets");
      default:
        break;
    }
  };

  React.useEffect(() => {
    getNotification();
  }, []);

  return (
    <>
      <NotificationsNoneIcon
        onClick={(event) => {
          handleClickProfile(event);
        }}
        sx={{ cursor: "pointer" }}
      />
      <Menu
        anchorEl={anchorElProfile}
        id="account-menu"
        className="menuModal"
        open={openProfile}
        onClose={handleCloseProfile}
        onClick={handleCloseProfile}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Grid container justifyContent="center">
          <Typography className="notificationHeading">Notifications</Typography>
        </Grid>
        {NotificationData.count &&
          Object.keys(NotificationData.count).map((item) => {
            return (
              <MenuItem className="itemBorder" sx={{ p: 2 }}>
                <Grid container row>
                  <Grid item container md={12} alignItems="center">
                    <Grid item md={1.5} className="iconBorder">
                      <ListItemIcon>
                        <Badge
                          className="badgeIcon"
                          badgeContent={NotificationData?.count[item].count}
                          color="primary"
                        >
                          <MailIcon />
                        </Badge>
                      </ListItemIcon>
                    </Grid>
                    <Grid item md={7} sx={{ textAlign: "center" }}>
                      {item === "1" && "User Admin"}
                      {item === "2" && "CEO"}
                      {item === "3" && "Super Admin"}
                      {item === "4" && "Manager"}
                      {item === "5" && "Supervisor"}
                      {item === "6" && "CSR"}
                      {item === "7" && "Tickets"}
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        className="notificationView"
                        onClick={() => handleViewClick(item)}
                      >
                        View
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};
